<template>

<Toast />

<Dialog v-model:visible="showBilanDialogFlagLocal" :style="{width: '1200px'}"  @hide="closeDialog" :modal="true" >
            <!-- <UploadBilan></UploadBilan> -->
            <template #header>
                <div style="color: #2195f3;display:flex;"> 
                <div style="display: flex;align-items:center;vertical-align:center" v-if="inforiskLocal || (selectedBilanItemLocal['inforisk'] && selectedBilanItemLocal['inforisk'] == 1)">
                    <img  src="../../../src/assets/inforisk.png"  style="width:100px;" />
                    <div v-if="selectedBilanItemLocal.id" style="font-size:16px;font-weight:bold;padding-left: 5px;padding-top: 5px;">
                        <i class="pi pi-pencil"></i>
                    </div>
                    <div v-else style="font-size:16px;font-weight:bold;padding-left: 5px;padding-top: 5px;">
                        <i class="pi pi-plus-circle"></i>
                    </div>
                    <div style="font-size:16px;font-weight:bold;padding-top: 5px;">
                        &nbsp;{{titleHeader}}
                    </div>
                </div>
                <div style="font-size:16px;font-weight:bold;display: flex;align-items: center;" v-else>
                    <div v-if="selectedBilanItemLocal.id" style="font-size:16px;font-weight:bold;padding-left: 5px;">
                        <i class="pi pi-pencil"></i>
                    </div>
                    <div v-else style="font-size:16px;font-weight:bold;padding-left: 5px;">
                        <i class="pi pi-plus-circle"></i>
                    </div>
                    <div>
                    &nbsp;{{titleHeader}}
                    </div>
                </div>
                </div>
            </template>
            <V-Form > 
                <div class="" style="padding-bottom:5px">
                    <div v-if="!selectedBilanItemLocal.id && !createBilanFlag">
                    <label for="dateBilan">Date du bilan: </label> &nbsp;
                    <Dropdown v-model="selectedBilanItemLocal.dateBilan" :options="years" optionValue="annee" optionLabel="annee" optionDisabled="disabled" placeholder="Date du bilan"/>
                    &nbsp;
                    <P-Button :disabled="!selectedBilanItemLocal.dateBilan" v-if="!inforiskLocal" label="Créer Bilan" class="p-button-raised"  :loading="isSaving"  @click="createBilanFlag=true"/>
                    <!-- <PCalendar :modelValue="new Date(selectedBilanItemLocal.dateBilan)"  v-model="selectedBilanItemLocal.dateBilan" view="year" dateFormat="yy" :disabledDates="anneesbilan"/> -->
                    <br/>
                    &nbsp;
                    <!-- {{selectedBilanItemLocal.dateBilan}} -->
                    <div v-if="inforiskLocal && !selectedBilanItemLocal.id">
                        <div style="display:grid;width:300px;border: 1px solid blue;padding:20px">
                        <b>Login:</b>
                        <InputText v-model="credentials.username"/>
                        <b>Mot de passe:</b>
                        <Password :feedback="false" :promptLabel="null" v-model="credentials.password" toggleMask/>
                        </div>
                        <br/>
                        <Button @click="ImporterBilanInforiskBoutonAction" :style="InforiskLoading?'width:200px; height:35px':'width:150px; height:35px'" :loading="InforiskLoading" loadingIcon="pi pi-spinner pi-spin" :disabled="!selectedBilanItemLocal.dateBilan">Importer&nbsp;Bilan<i v-if="InforiskLoading" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></Button>
                    </div>
                    
                    <div v-if="companyInforisk && companyInforisk._sDenomination">
                        Vous allez importer un bilan de l'entreprise suivante: {{companyInforisk._sDenomination}}
                        <br/>
                        <b>ICE</b>({{companyInforisk._sICE}})

                        <span>Voulez vous continuer?</span>
                        <br/>
                        <Button @click="ImporterBilanInforiskAvecEntreprisevalidee">OUI</Button>
                        &nbsp;
                        <Button @click="companyInforisk=null;InforiskLoading=false;">NON</Button>
                    </div>
                </div>
                <!-- <Button v-if="(inforiskLocal || selectedBilanItemLocal.inforisk) && selectedBilanItemLocal.id" @click="switchToManuel" :style="'height:35px'" :loading="InforiskLoading" >Rendre manuel</Button> -->
                
                            <!-- res:{{jsonResult}} -->
                     
                   
                </div>
                
                <div class="row p-fluid" style="margin-bottom: 10px;" v-if="selectedBilanItemLocal.id>0 || createBilanFlag">
                    <!-- ========================== first Column =========================== -->
                    <div class="col-12">
                        <div class="card p-2">
                            <!-- <div class="k-state-active tabStribHeader">
                                <span class="pi pi-book" style="font-size:20px;margin-top:-4px;"></span>
                                Actif
                            </div> -->

                            <kendo-tabstrip ref="refKendoTabStrip" >
                                <ul>
                                    <li class="k-state-active tabStribHeader" style="vertical-align:middle;border-bottom-color: #0d6efd;border-width: 2px;width: 33%;">
                                        <!-- <span class="k-icon k-i-greyscale"></span> -->
                                            Actif
                                    </li>
                                    <li class="tabStribHeader" style="vertical-align:middle;border-bottom-color: #f31700;border-width: 2px;width: 33%;">
                                            <!-- <span class="k-icon k-i-dollar"></span> -->
                                            Passif
                                    </li>
                                    <li class="tabStribHeader" style="vertical-align:middle;border-bottom-color: #37b400;border-width: 2px;width: 33%;">
                                            <!-- <span class="k-icon k-i-saturation"></span> -->
                                            CPC
                                    </li>
                                </ul>
                                <div class="bilanActif">
                                    <!-- *********************** BILAN (ACTIF) ******************************* -->
                                    <table style="width:100%">
                                        <tr v-if="!inforiskLocal">
                                            <th></th>
                                            <th></th>
                                            <th>Brut</th>
                                            <th>Amortissement/Provisions</th>
                                            <th>Net</th>
                                            <th class="YearN1TH">N-1 
                                                <span v-if="selectedBilanItemLocalAnneeN1.inforisk" v-tooltip.top="'(inforisk)'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                                <span v-else-if="selectedBilanItemLocalAnneeN1.id && !selectedBilanItemLocalAnneeN1.estAnneePrecedente" v-tooltip.top="'Accédez au bilan '+selectedBilanItemLocalAnneeN1.dateBilan+' pour le changer'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                            </th>
                                        </tr>
                                        <tr v-else>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th>Net</th>
                                            <th class="YearN1TH">N-1 
                                                <span v-if="selectedBilanItemLocalAnneeN1.inforisk" v-tooltip.top="'(inforisk)'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                                <span v-else-if="selectedBilanItemLocalAnneeN1.id && !selectedBilanItemLocalAnneeN1.estAnneePrecedente" v-tooltip.top="'Accédez au bilan '+selectedBilanItemLocalAnneeN1.dateBilan+' pour le changer'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                            </th>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td style="text-align:center" rowspan="26" class="bilanMargeVerticale"> A <br>C<br>T<br>I<br>F<br> <br> I<br>M<br>M<br>O<br>B<br>I<br>L<br>I<br>S<br>E<br>S</td>
                                            <td class="titleCol titleGroupCol"> IMMOBILISATION EN NON VALEUR (a)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifAImmobiliseImmobilisationEnNonValeurBrut'   v-model='actifAImmobiliseImmobilisationEnNonValeurBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifAImmobiliseImmobilisationEnNonValeurAmrtProv'   v-model='actifAImmobiliseImmobilisationEnNonValeurAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifAImmobiliseImmobilisationEnNonValeur' v-model='actifAImmobiliseImmobilisationEnNonValeur'  mode='decimal'  :minFractionDigits='2'  />
                                                    <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifAImmobiliseImmobilisationEnNonValeur" 
                                                    :selectedBilanItem="selectedBilanItemLocal"
                                                    :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifAImmobiliseImmobilisationEnNonValeurAnneeN1'   v-model='actifAImmobiliseImmobilisationEnNonValeurAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                    <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifAImmobiliseImmobilisationEnNonValeur" 
                                                    :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                    :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Frais préliminaires</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifA1ImmobiliseFraisPreliminairesBrut'  v-model='selectedBilanItemLocal.actifA1ImmobiliseFraisPreliminairesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifA1ImmobiliseFraisPreliminairesAmrtProv'  v-model='selectedBilanItemLocal.actifA1ImmobiliseFraisPreliminairesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifA1ImmobiliseFraisPreliminaires'  v-model='actifA1ImmobiliseFraisPreliminaires'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifA1ImmobiliseFraisPreliminairesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifA1ImmobiliseFraisPreliminaires'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Charges à répartir sur plusieurs exercices</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifA2ImmobiliseChargesArepartirSurplusieursExercicesBrut'  v-model='selectedBilanItemLocal.actifA2ImmobiliseChargesArepartirSurplusieursExercicesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifA2ImmobiliseChargesArepartirSurplusieursExercicesAmrtProv'  v-model='selectedBilanItemLocal.actifA2ImmobiliseChargesArepartirSurplusieursExercicesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifA2ImmobiliseChargesArepartirSurplusieursExercices'  v-model='actifA2ImmobiliseChargesArepartirSurplusieursExercices'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifA2ImmobiliseChargesArepartirSurplusieursExercicesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifA2ImmobiliseChargesArepartirSurplusieursExercices'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Primes de remboursement des obligations</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifA3ImmobilisePrimesDeRemboursementDesObligationsBrut'  v-model='selectedBilanItemLocal.actifA3ImmobilisePrimesDeRemboursementDesObligationsBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifA3ImmobilisePrimesDeRemboursementDesObligationsAmrtProv'  v-model='selectedBilanItemLocal.actifA3ImmobilisePrimesDeRemboursementDesObligationsAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifA3ImmobilisePrimesDeRemboursementDesObligations'  v-model='actifA3ImmobilisePrimesDeRemboursementDesObligations'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifA3ImmobilisePrimesDeRemboursementDesObligationsAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifA3ImmobilisePrimesDeRemboursementDesObligations'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> IMMOBILISATIONS INCORPORELLES (b)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifBImmobiliseImmobilisationsIncorporellesBrut'   v-model='actifBImmobiliseImmobilisationsIncorporellesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifBImmobiliseImmobilisationsIncorporellesAmrtProv'   v-model='actifBImmobiliseImmobilisationsIncorporellesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifBImmobiliseImmobilisationsIncorporelles'   v-model='actifBImmobiliseImmobilisationsIncorporelles'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifBImmobiliseImmobilisationsIncorporelles" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifBImmobiliseImmobilisationsIncorporellesAnneeN1'   v-model='actifBImmobiliseImmobilisationsIncorporellesAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifBImmobiliseImmobilisationsIncorporelles" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Immobilisations en recherche et développement</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementBrut'  v-model='selectedBilanItemLocal.actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementAmrtProv'  v-model='selectedBilanItemLocal.actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppement'  v-model='actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Brevets, marques, droits et valeurs similaires</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesBrut'  v-model='selectedBilanItemLocal.actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesAmrtProv'  v-model='selectedBilanItemLocal.actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilaires'  v-model='actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilaires'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilaires'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Fonds commercial</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifB3ImmobiliseFondsCommercialBrut'  v-model='selectedBilanItemLocal.actifB3ImmobiliseFondsCommercialBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifB3ImmobiliseFondsCommercialAmrtProv'  v-model='selectedBilanItemLocal.actifB3ImmobiliseFondsCommercialAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifB3ImmobiliseFondsCommercial'  v-model='actifB3ImmobiliseFondsCommercial'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifB3ImmobiliseFondsCommercialAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifB3ImmobiliseFondsCommercial'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres immobilisations incorporelles</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifB4ImmobiliseAutresImmobilisationsIncorporellesBrut'  v-model='selectedBilanItemLocal.actifB4ImmobiliseAutresImmobilisationsIncorporellesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifB4ImmobiliseAutresImmobilisationsIncorporellesAmrtProv'  v-model='selectedBilanItemLocal.actifB4ImmobiliseAutresImmobilisationsIncorporellesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifB4ImmobiliseAutresImmobilisationsIncorporelles'  v-model='actifB4ImmobiliseAutresImmobilisationsIncorporelles'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifB4ImmobiliseAutresImmobilisationsIncorporellesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifB4ImmobiliseAutresImmobilisationsIncorporelles'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> IMMOBILISATIONS CORPORELLES (c)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifCImmobiliseImmobilisationsCorporellesBrut'   v-model='actifCImmobiliseImmobilisationsCorporellesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifCImmobiliseImmobilisationsCorporellesAmrtProv'   v-model='actifCImmobiliseImmobilisationsCorporellesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifCImmobiliseImmobilisationsCorporelles'   v-model='actifCImmobiliseImmobilisationsCorporelles'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifCImmobiliseImmobilisationsCorporelles" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifCImmobiliseImmobilisationsCorporellesAnneeN1'   v-model='actifCImmobiliseImmobilisationsCorporellesAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifCImmobiliseImmobilisationsCorporelles" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Terrains</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC1ImmobiliseTerrainsBrut'  v-model='selectedBilanItemLocal.actifC1ImmobiliseTerrainsBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC1ImmobiliseTerrainsAmrtProv'  v-model='selectedBilanItemLocal.actifC1ImmobiliseTerrainsAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifC1ImmobiliseTerrains'  v-model='actifC1ImmobiliseTerrains'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifC1ImmobiliseTerrainsAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifC1ImmobiliseTerrains'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Constructions</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC2ImmobiliseConstructionsBrut'  v-model='selectedBilanItemLocal.actifC2ImmobiliseConstructionsBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC2ImmobiliseConstructionsAmrtProv'  v-model='selectedBilanItemLocal.actifC2ImmobiliseConstructionsAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifC2ImmobiliseConstructions'  v-model='actifC2ImmobiliseConstructions'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifC2ImmobiliseConstructionsAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifC2ImmobiliseConstructions'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Installations techniques, matériel et outillage</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC3ImmobiliseInstallationsTechniqueMaterielOutillageBrut'  v-model='selectedBilanItemLocal.actifC3ImmobiliseInstallationsTechniqueMaterielOutillageBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC3ImmobiliseInstallationsTechniqueMaterielOutillageAmrtProv'  v-model='selectedBilanItemLocal.actifC3ImmobiliseInstallationsTechniqueMaterielOutillageAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifC3ImmobiliseInstallationsTechniqueMaterielOutillage'  v-model='actifC3ImmobiliseInstallationsTechniqueMaterielOutillage'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifC3ImmobiliseInstallationsTechniqueMaterielOutillageAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifC3ImmobiliseInstallationsTechniqueMaterielOutillage'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Matériel de transport</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC4ImmobiliseMaterielDeTransportBrut'  v-model='selectedBilanItemLocal.actifC4ImmobiliseMaterielDeTransportBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC4ImmobiliseMaterielDeTransportAmrtProv'  v-model='selectedBilanItemLocal.actifC4ImmobiliseMaterielDeTransportAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifC4ImmobiliseMaterielDeTransport'  v-model='actifC4ImmobiliseMaterielDeTransport'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifC4ImmobiliseMaterielDeTransportAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifC4ImmobiliseMaterielDeTransport'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Mobiliers, matériel de bureau et aménagements divers</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversBrut'  v-model='selectedBilanItemLocal.actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversAmrtProv'  v-model='selectedBilanItemLocal.actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDivers'  v-model='actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDivers'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDivers'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres immobilisations corporelles</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC6ImmobiliseAutresImmobilisationsCorporellesBrut'  v-model='selectedBilanItemLocal.actifC6ImmobiliseAutresImmobilisationsCorporellesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC6ImmobiliseAutresImmobilisationsCorporellesAmrtProv'  v-model='selectedBilanItemLocal.actifC6ImmobiliseAutresImmobilisationsCorporellesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifC6ImmobiliseAutresImmobilisationsCorporelles'  v-model='actifC6ImmobiliseAutresImmobilisationsCorporelles'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifC6ImmobiliseAutresImmobilisationsCorporellesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifC6ImmobiliseAutresImmobilisationsCorporelles'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Immobilisations corporelles en cours</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC7ImmobiliseImmobilisationsCorporellesEnCoursBrut'  v-model='selectedBilanItemLocal.actifC7ImmobiliseImmobilisationsCorporellesEnCoursBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifC7ImmobiliseImmobilisationsCorporellesEnCoursAmrtProv'  v-model='selectedBilanItemLocal.actifC7ImmobiliseImmobilisationsCorporellesEnCoursAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifC7ImmobiliseImmobilisationsCorporellesEnCours'  v-model='actifC7ImmobiliseImmobilisationsCorporellesEnCours'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifC7ImmobiliseImmobilisationsCorporellesEnCoursAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifC7ImmobiliseImmobilisationsCorporellesEnCours'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> IMMOBILISATIONS FINANCIERES (d)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifDImmobiliseImmobilisationsFinancieresBrut'   v-model='actifDImmobiliseImmobilisationsFinancieresBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifDImmobiliseImmobilisationsFinancieresAmrtProv'   v-model='actifDImmobiliseImmobilisationsFinancieresAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifDImmobiliseImmobilisationsFinancieres'   v-model='actifDImmobiliseImmobilisationsFinancieres'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifDImmobiliseImmobilisationsFinancieres" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifDImmobiliseImmobilisationsFinancieresAnneeN1'   v-model='actifDImmobiliseImmobilisationsFinancieresAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifDImmobiliseImmobilisationsFinancieres" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Prêts immobilises</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifD1ImmobilisePretsImmobilisesBrut'  v-model='selectedBilanItemLocal.actifD1ImmobilisePretsImmobilisesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifD1ImmobilisePretsImmobilisesAmrtProv'  v-model='selectedBilanItemLocal.actifD1ImmobilisePretsImmobilisesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifD1ImmobilisePretsImmobilises'  v-model='actifD1ImmobilisePretsImmobilises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifD1ImmobilisePretsImmobilisesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifD1ImmobilisePretsImmobilises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres créances financières</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifD2ImmobiliseAutresCreancesFinancieresBrut'  v-model='selectedBilanItemLocal.actifD2ImmobiliseAutresCreancesFinancieresBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifD2ImmobiliseAutresCreancesFinancieresAmrtProv'  v-model='selectedBilanItemLocal.actifD2ImmobiliseAutresCreancesFinancieresAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifD2ImmobiliseAutresCreancesFinancieres'  v-model='actifD2ImmobiliseAutresCreancesFinancieres'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifD2ImmobiliseAutresCreancesFinancieresAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifD2ImmobiliseAutresCreancesFinancieres'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Titres de participation</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifD3ImmobiliseTitresDeParticipationBrut'  v-model='selectedBilanItemLocal.actifD3ImmobiliseTitresDeParticipationBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifD3ImmobiliseTitresDeParticipationAmrtProv'  v-model='selectedBilanItemLocal.actifD3ImmobiliseTitresDeParticipationAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifD3ImmobiliseTitresDeParticipation'  v-model='actifD3ImmobiliseTitresDeParticipation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifD3ImmobiliseTitresDeParticipationAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifD3ImmobiliseTitresDeParticipation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres titres immobilisés</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifD4ImmobiliseAutresTitresImmobilisesBrut'  v-model='selectedBilanItemLocal.actifD4ImmobiliseAutresTitresImmobilisesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifD4ImmobiliseAutresTitresImmobilisesAmrtProv'  v-model='selectedBilanItemLocal.actifD4ImmobiliseAutresTitresImmobilisesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifD4ImmobiliseAutresTitresImmobilises'  v-model='actifD4ImmobiliseAutresTitresImmobilises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifD4ImmobiliseAutresTitresImmobilisesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifD4ImmobiliseAutresTitresImmobilises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> ECARTS DE CONVERSION - ACTIF (e)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifEImmobiliseEcartsDeConversionBrut'   v-model='actifEImmobiliseEcartsDeConversionBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifEImmobiliseEcartsDeConversionAmrtProv'   v-model='actifEImmobiliseEcartsDeConversionAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifEImmobiliseEcartsDeConversion'   v-model='actifEImmobiliseEcartsDeConversion'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifEImmobiliseEcartsDeConversion" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifEImmobiliseEcartsDeConversionAnneeN1'   v-model='actifEImmobiliseEcartsDeConversionAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifEImmobiliseEcartsDeConversion" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Diminution des créances immobilisées</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifE1ImmobiliseDiminutionDesCreancesImmobiliseesBrut'  v-model='selectedBilanItemLocal.actifE1ImmobiliseDiminutionDesCreancesImmobiliseesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifE1ImmobiliseDiminutionDesCreancesImmobiliseesAmrtProv'  v-model='selectedBilanItemLocal.actifE1ImmobiliseDiminutionDesCreancesImmobiliseesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifE1ImmobiliseDiminutionDesCreancesImmobilisees'  v-model='actifE1ImmobiliseDiminutionDesCreancesImmobilisees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifE1ImmobiliseDiminutionDesCreancesImmobiliseesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifE1ImmobiliseDiminutionDesCreancesImmobilisees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Augmentation des dettes de financement</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifE2ImmobiliseAugmentationDesDettesDeFinancementBrut'  v-model='selectedBilanItemLocal.actifE2ImmobiliseAugmentationDesDettesDeFinancementBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifE2ImmobiliseAugmentationDesDettesDeFinancementAmrtProv'  v-model='selectedBilanItemLocal.actifE2ImmobiliseAugmentationDesDettesDeFinancementAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifE2ImmobiliseAugmentationDesDettesDeFinancement'  v-model='actifE2ImmobiliseAugmentationDesDettesDeFinancement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifE2ImmobiliseAugmentationDesDettesDeFinancementAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifE2ImmobiliseAugmentationDesDettesDeFinancement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanTotal">
                                            <td class="titleCol titleGroupCol"> TOTAL  I   ( a+b+c+d+e)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifTotalIImmobiliseTotalBrut'   v-model='actifTotalIImmobiliseTotalBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifTotalIImmobiliseTotalAmrtProv'   v-model='actifTotalIImmobiliseTotalAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifTotalIImmobiliseTotal'   v-model='actifTotalIImmobiliseTotal'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifTotalIImmobiliseTotal" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifTotalIImmobiliseTotalAnneeN1'   v-model='actifTotalIImmobiliseTotalAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifTotalIImmobiliseTotal" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td rowspan="17" style="text-align:center" class="bilanMargeVerticale"> A<br>C<br>T<br>I<br>F <br><br> C<br>I<br>R<br>C<br>U<br>L<br>A<br>N<br>T</td>
                                            <td class="titleCol titleGroupCol"> STOCKS (f)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifFCirculantStocksBrut'   v-model='actifFCirculantStocksBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifFCirculantStocksAmrtProv'   v-model='actifFCirculantStocksAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifFCirculantStocks'   v-model='actifFCirculantStocks'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifFCirculantStocks" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifFCirculantStocksAnneeN1'   v-model='actifFCirculantStocksAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifFCirculantStocks" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Marchandises</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF1CirculantMarchandisesBrut'  v-model='selectedBilanItemLocal.actifF1CirculantMarchandisesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF1CirculantMarchandisesAmrtProv'  v-model='selectedBilanItemLocal.actifF1CirculantMarchandisesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifF1CirculantMarchandises'  v-model='actifF1CirculantMarchandises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifF1CirculantMarchandisesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifF1CirculantMarchandises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Matières et fournitures consommables</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF2CirculantMatieresEtFournituresConsommablesBrut'  v-model='selectedBilanItemLocal.actifF2CirculantMatieresEtFournituresConsommablesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF2CirculantMatieresEtFournituresConsommablesAmrtProv'  v-model='selectedBilanItemLocal.actifF2CirculantMatieresEtFournituresConsommablesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifF2CirculantMatieresEtFournituresConsommables'  v-model='actifF2CirculantMatieresEtFournituresConsommables'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifF2CirculantMatieresEtFournituresConsommablesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifF2CirculantMatieresEtFournituresConsommables'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Produits en cours</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF3CirculantProduitsEnCoursBrut'  v-model='selectedBilanItemLocal.actifF3CirculantProduitsEnCoursBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF3CirculantProduitsEnCoursAmrtProv'  v-model='selectedBilanItemLocal.actifF3CirculantProduitsEnCoursAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifF3CirculantProduitsEnCours'  v-model='actifF3CirculantProduitsEnCours'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifF3CirculantProduitsEnCoursAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifF3CirculantProduitsEnCours'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Produits intermédiaires et produits résiduels</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF4CirculantProduitsIntermediairesEtProduitsResiduelsBrut'  v-model='selectedBilanItemLocal.actifF4CirculantProduitsIntermediairesEtProduitsResiduelsBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF4CirculantProduitsIntermediairesEtProduitsResiduelsAmrtProv'  v-model='selectedBilanItemLocal.actifF4CirculantProduitsIntermediairesEtProduitsResiduelsAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifF4CirculantProduitsIntermediairesEtProduitsResiduels'  v-model='actifF4CirculantProduitsIntermediairesEtProduitsResiduels'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifF4CirculantProduitsIntermediairesEtProduitsResiduelsAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifF4CirculantProduitsIntermediairesEtProduitsResiduels'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Produits finis</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF5CirculantProduitsFinisBrut'  v-model='selectedBilanItemLocal.actifF5CirculantProduitsFinisBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifF5CirculantProduitsFinisAmrtProv'  v-model='selectedBilanItemLocal.actifF5CirculantProduitsFinisAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifF5CirculantProduitsFinis'  v-model='actifF5CirculantProduitsFinis'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifF5CirculantProduitsFinisAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifF5CirculantProduitsFinis'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> CREANCES DE L'ACTIF CIRCULANT (g)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifGCirculantCreancesCirculantBrut'   v-model='actifGCirculantCreancesCirculantBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifGCirculantCreancesCirculantAmrtProv'   v-model='actifGCirculantCreancesCirculantAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifGCirculantCreancesCirculant'   v-model='actifGCirculantCreancesCirculant'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifGCirculantCreancesCirculant" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifGCirculantCreancesCirculantAnneeN1'   v-model='actifGCirculantCreancesCirculantAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifGCirculantCreancesCirculant" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Fournis. débiteurs, avances et acomptes</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG1CirculantFournisseursdebiteursAvancesAcomptesBrut'  v-model='selectedBilanItemLocal.actifG1CirculantFournisseursdebiteursAvancesAcomptesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG1CirculantFournisseursdebiteursAvancesAcomptesAmrtProv'  v-model='selectedBilanItemLocal.actifG1CirculantFournisseursdebiteursAvancesAcomptesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifG1CirculantFournisseursdebiteursAvancesAcomptes'  v-model='actifG1CirculantFournisseursdebiteursAvancesAcomptes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifG1CirculantFournisseursdebiteursAvancesAcomptesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifG1CirculantFournisseursdebiteursAvancesAcomptes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Clients et comptes rattachés</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG2CirculantClientsEtComptesRattachesBrut'  v-model='selectedBilanItemLocal.actifG2CirculantClientsEtComptesRattachesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG2CirculantClientsEtComptesRattachesAmrtProv'  v-model='selectedBilanItemLocal.actifG2CirculantClientsEtComptesRattachesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifG2CirculantClientsEtComptesRattaches'  v-model='actifG2CirculantClientsEtComptesRattaches'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifG2CirculantClientsEtComptesRattachesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifG2CirculantClientsEtComptesRattaches'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Personnel</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG3CirculantPersonnelBrut'  v-model='selectedBilanItemLocal.actifG3CirculantPersonnelBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG3CirculantPersonnelAmrtProv'  v-model='selectedBilanItemLocal.actifG3CirculantPersonnelAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifG3CirculantPersonnel'  v-model='actifG3CirculantPersonnel'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifG3CirculantPersonnelAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifG3CirculantPersonnel'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Etat</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG4CirculantEtatBrut'  v-model='selectedBilanItemLocal.actifG4CirculantEtatBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG4CirculantEtatAmrtProv'  v-model='selectedBilanItemLocal.actifG4CirculantEtatAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifG4CirculantEtat'  v-model='actifG4CirculantEtat'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifG4CirculantEtatAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifG4CirculantEtat'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Comptes d'associés</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG5CirculantCompteAssociesBrut'  v-model='selectedBilanItemLocal.actifG5CirculantCompteAssociesBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG5CirculantCompteAssociesAmrtProv'  v-model='selectedBilanItemLocal.actifG5CirculantCompteAssociesAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifG5CirculantCompteAssocies'  v-model='actifG5CirculantCompteAssocies'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifG5CirculantCompteAssociesAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifG5CirculantCompteAssocies'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres débiteurs</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG6CirculantAutresDebiteursBrut'  v-model='selectedBilanItemLocal.actifG6CirculantAutresDebiteursBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG6CirculantAutresDebiteursAmrtProv'  v-model='selectedBilanItemLocal.actifG6CirculantAutresDebiteursAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifG6CirculantAutresDebiteurs'  v-model='actifG6CirculantAutresDebiteurs'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifG6CirculantAutresDebiteursAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifG6CirculantAutresDebiteurs'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Compte de régularisation actif</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG7CirculantCompteRegularisationBrut'  v-model='selectedBilanItemLocal.actifG7CirculantCompteRegularisationBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifG7CirculantCompteRegularisationAmrtProv'  v-model='selectedBilanItemLocal.actifG7CirculantCompteRegularisationAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifG7CirculantCompteRegularisation'  v-model='actifG7CirculantCompteRegularisation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifG7CirculantCompteRegularisationAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifG7CirculantCompteRegularisation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> TITRES ET VALEUR DE PLACEMENT (h)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifHCirculantTitresEtValeursDePlacementBrut'  v-model='selectedBilanItemLocal.actifHCirculantTitresEtValeursDePlacementBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifHCirculantTitresEtValeursDePlacementAmrtProv'  v-model='selectedBilanItemLocal.actifHCirculantTitresEtValeursDePlacementAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifHCirculantTitresEtValeursDePlacement'  v-model='actifHCirculantTitresEtValeursDePlacement'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifHCirculantTitresEtValeursDePlacement" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifHCirculantTitresEtValeursDePlacementAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifHCirculantTitresEtValeursDePlacement'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifHCirculantTitresEtValeursDePlacement" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> ECART DE CONVERSION - ACTIF (i)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifICirculantEcartDeConversionBrut'  v-model='selectedBilanItemLocal.actifICirculantEcartDeConversionBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifICirculantEcartDeConversionAmrtProv'  v-model='selectedBilanItemLocal.actifICirculantEcartDeConversionAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifICirculantEcartDeConversion'  v-model='actifICirculantEcartDeConversion'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifICirculantEcartDeConversion" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifICirculantEcartDeConversionAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifICirculantEcartDeConversion'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="actifICirculantEcartDeConversion" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanTotal">
                                            <td class="titleCol titleGroupCol"> TOTAL  II   ( f+g+h+i)</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifTotalIICirculantTotalBrut'   v-model='actifTotalIICirculantTotalBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifTotalIICirculantTotalAmrtProv'   v-model='actifTotalIICirculantTotalAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifTotalIICirculantTotal'   v-model='actifTotalIICirculantTotal'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifTotalIICirculantTotal" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifTotalIICirculantTotalAnneeN1'   v-model='actifTotalIICirculantTotalAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifTotalIICirculantTotal" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td rowspan="4" style="text-align:center" class="bilanMargeVerticale"> T<br>R<br>E<br>S<br>O</td>
                                            <td class="titleCol">Chèques et valeurs à encaisser</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTresor1TresorerieChequesEtValeursAEncaisserBrut'  v-model='selectedBilanItemLocal.actifTresor1TresorerieChequesEtValeursAEncaisserBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTresor1TresorerieChequesEtValeursAEncaisserAmrtProv'  v-model='selectedBilanItemLocal.actifTresor1TresorerieChequesEtValeursAEncaisserAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifTresor1TresorerieChequesEtValeursAEncaisser'  v-model='actifTresor1TresorerieChequesEtValeursAEncaisser'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifTresor1TresorerieChequesEtValeursAEncaisserAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifTresor1TresorerieChequesEtValeursAEncaisser'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Banques, T.G & CP</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTresor2TresorerieBanquesTGEtCPBrut'  v-model='selectedBilanItemLocal.actifTresor2TresorerieBanquesTGEtCPBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTresor2TresorerieBanquesTGEtCPAmrtProv'  v-model='selectedBilanItemLocal.actifTresor2TresorerieBanquesTGEtCPAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifTresor2TresorerieBanquesTGEtCP'  v-model='actifTresor2TresorerieBanquesTGEtCP'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifTresor2TresorerieBanquesTGEtCPAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifTresor2TresorerieBanquesTGEtCP'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Caisses, régies d'avances et accréditifs</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsBrut'  v-model='selectedBilanItemLocal.actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsAmrtProv'  v-model='selectedBilanItemLocal.actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <InputNumber v-show="true" :disabled="true" name='actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifs'  v-model='actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifs'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsAnneeN1'  v-model='selectedBilanItemLocalAnneeN1.actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifs'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanTotal">
                                            <td class="titleCol titleGroupCol"> TOTAL  III</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifTresorTresorerieTotalBrut'   v-model='actifTresorTresorerieTotalBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifTresorTresorerieTotalAmrtProv'   v-model='actifTresorTresorerieTotalAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div>
                                                <InputNumber v-show="true" :disabled="true" name='actifTresorTresorerieTotal'   v-model='actifTresorTresorerieTotal'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifTresorTresorerieTotal" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div>
                                                <InputNumber v-show="true" :disabled="true" name='actifTresorTresorerieTotalAnneeN1'   v-model='actifTresorTresorerieTotalAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifTresorTresorerieTotal" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanTotalGeneral">
                                            <td rowspan="1" style="text-align:center"> </td>
                                            <td class="titleCol titleGroupCol"> TOTAL GENERAL  I+II+III</td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifTotalGeneralBrut'   v-model='actifTotalGeneralBrut'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='actifTotalGeneralAmrtProv'   v-model='actifTotalGeneralAmrtProv'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifTotalGeneral'   v-model='actifTotalGeneral'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifTotalGeneral" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='actifTotalGeneralAnneeN1'   v-model='actifTotalGeneralAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" codeTechnique="actifTotalGeneral" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                    </div>

                                    <div class="bilanPassif">
                                    <!-- *********************** BILAN (PASSIF) ******************************* -->
                                    <table style="width:100%">
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th>Net</th>
                                            <th class="YearN1TH">N-1 
                                                <span v-if="selectedBilanItemLocalAnneeN1.inforisk" v-tooltip.top="'(inforisk)'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                                <span v-else-if="selectedBilanItemLocalAnneeN1.id && !selectedBilanItemLocalAnneeN1.estAnneePrecedente" v-tooltip.top="'Accédez au bilan '+selectedBilanItemLocalAnneeN1.dateBilan+' pour le changer'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                            </th>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td style="text-align:center" rowspan="23" class="bilanMargeVerticale"> F<br>I<br>N<br>A<br>N<br>C<br>E<br>M<br>E<br>N<br>T<br> <br>P<br>E<br>R<br>M<br>A<br>N<br>E<br>N<br>T       </td>
                                            <td class="titleCol titleGroupCol"> CAPITAUX PROPRES</td>
                                            <td class="YearN"><div><InputNumber v-show="true"  name='passifAFinancementPermanentCapitauxPropres'  v-model='passifAFinancementPermanentCapitauxPropres'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifAFinancementPermanentCapitauxPropres" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true"  name='passifAFinancementPermanentCapitauxPropresAnneeN1'  v-model='passifAFinancementPermanentCapitauxPropresAnneeN1'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifAFinancementPermanentCapitauxPropres" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Capital social ou personnel (1)</td>
                                            <td><InputNumber v-show="true" name='passifA2FinancementPermanentCapitalPersonnel' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifA2FinancementPermanentCapitalPersonnel'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifA2FinancementPermanentCapitalPersonnelAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifA2FinancementPermanentCapitalPersonnel'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">moins: Actionnaires, capital souscrit non appelé dont versé</td>
                                            <td><InputNumber v-show="true" name='passifA3FinancementPermanentMoinsActionnairesCapitalsouscritnonappelenonverse' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifA3FinancementPermanentMoinsActionnairesCapitalsouscritnonappelenonverse'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifA3FinancementPermanentMoinsActionnairesCapitalsouscritnonappelenonverseAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifA3FinancementPermanentMoinsActionnairesCapitalsouscritnonappelenonverse'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Prime d'émission, de fusion, d'apport</td>
                                            <td><InputNumber v-show="true" name='passifA6FinancementPermanentPrimeEmissionFusionApport' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifA6FinancementPermanentPrimeEmissionFusionApport'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifA6FinancementPermanentPrimeEmissionFusionApportAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifA6FinancementPermanentPrimeEmissionFusionApport'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Ecarts de réévaluation</td>
                                            <td><InputNumber v-show="true" name='passifA7FinancementPermanentEcartsDeReevaluation' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifA7FinancementPermanentEcartsDeReevaluation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifA7FinancementPermanentEcartsDeReevaluationAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifA7FinancementPermanentEcartsDeReevaluation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Réserve légale</td>
                                            <td><InputNumber v-show="true" name='passifA8FinancementPermanentReserveLegale' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifA8FinancementPermanentReserveLegale'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifA8FinancementPermanentReserveLegaleAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifA8FinancementPermanentReserveLegale'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres réserves</td>
                                            <td><InputNumber v-show="true" name='passifA9FinancementPermanentAutresReserves' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifA9FinancementPermanentAutresReserves'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifA9FinancementPermanentAutresReservesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifA9FinancementPermanentAutresReserves'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Report à nouveau (2)</td>
                                            <td><InputNumber v-show="true" name='passifA10FinancementPermanentReportANouveau' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifA10FinancementPermanentReportANouveau'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifA10FinancementPermanentReportANouveauAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifA10FinancementPermanentReportANouveau'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Résultat nets en instance d'affectation (2)</td>
                                            <td><InputNumber v-show="true" name='passifA11FinancementPermanentResultatNetsEnInstanceDAffectation' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifA11FinancementPermanentResultatNetsEnInstanceDAffectation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifA11FinancementPermanentResultatNetsEnInstanceDAffectationAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifA11FinancementPermanentResultatNetsEnInstanceDAffectation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Résultat net de l'exercice (2)</td>
                                            <td><InputNumber v-show="true" name='passifA12FinancementPermanentResultatNetDeLexercice' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifA12FinancementPermanentResultatNetDeLexercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifA12FinancementPermanentResultatNetDeLexerciceAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifA12FinancementPermanentResultatNetDeLexercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>

                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> CAPITAUX PROPRES ASSIMILES (b)</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifBFinancementPermanentCapitauxPropresAssimiles'   v-model='passifBFinancementPermanentCapitauxPropresAssimiles'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifAFinancementPermanentCapitauxPropres" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifBFinancementPermanentCapitauxPropresAssimilesAnneeN1'   v-model='passifBFinancementPermanentCapitauxPropresAssimilesAnneeN1'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifAFinancementPermanentCapitauxPropres" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Subventions d'investissement</td>
                                            <td><InputNumber v-show="true" name='passifB1FinancementPermanentSubventionInvestissement' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifB1FinancementPermanentSubventionInvestissement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifB1FinancementPermanentSubventionInvestissementAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifB1FinancementPermanentSubventionInvestissement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Provisions réglementées</td>
                                            <td><InputNumber v-show="true" name='passifB2FinancementPermanentProvisionReglementees' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifB2FinancementPermanentProvisionReglementees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifB2FinancementPermanentProvisionReglementeesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifB2FinancementPermanentProvisionReglementees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>

                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> DETTES DE FINANCEMENT (c)</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifCFinancementPermanentDettesDeFinancement'   v-model='passifCFinancementPermanentDettesDeFinancement'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifCFinancementPermanentDettesDeFinancement" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifCFinancementPermanentDettesDeFinancementAnneeN1'   v-model='passifCFinancementPermanentDettesDeFinancementAnneeN1'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifCFinancementPermanentDettesDeFinancement" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Emprunts obligataires</td>
                                            <td><InputNumber v-show="true" name='passifC1FinancementPermanentEmpruntsObligataires' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifC1FinancementPermanentEmpruntsObligataires'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifC1FinancementPermanentEmpruntsObligatairesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifC1FinancementPermanentEmpruntsObligataires'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres dettes de financement</td>
                                            <td><InputNumber v-show="true" name='passifC2FinancementPermanentAutresDettesDeFinancement' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifC2FinancementPermanentAutresDettesDeFinancement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifC2FinancementPermanentAutresDettesDeFinancementAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifC2FinancementPermanentAutresDettesDeFinancement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>

                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> PROVISIONS DURABLES POUR RISQUES ET CHARGES (d)</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifDFinancementPermanentProvisionsDurablesPourRisquesEtCharges'  v-model='passifDFinancementPermanentProvisionsDurablesPourRisquesEtCharges'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifDFinancementPermanentProvisionsDurablesPourRisquesEtCharges" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifDFinancementPermanentProvisionsDurablesPourRisquesEtChargesAnneeN1'  v-model='passifDFinancementPermanentProvisionsDurablesPourRisquesEtChargesAnneeN1'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifDFinancementPermanentProvisionsDurablesPourRisquesEtCharges" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Provisions pour risques</td>
                                            <td><InputNumber v-show="true" name='passifD1FinancementPermanentProvisionsPourRisques' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifD1FinancementPermanentProvisionsPourRisques'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifD1FinancementPermanentProvisionsPourRisquesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifD1FinancementPermanentProvisionsPourRisques'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol"> Provisions pour charges</td>
                                            <td><InputNumber v-show="true" name='passifD2FinancementPermanentProvisionsPourCharges' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifD2FinancementPermanentProvisionsPourCharges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifD2FinancementPermanentProvisionsPourChargesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifD2FinancementPermanentProvisionsPourCharges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">ECARTS DE CONVERSION - PASSIF (e)</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifEFinancementPermanentEcartsDeConversion'  v-model='passifEFinancementPermanentEcartsDeConversion'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifEFinancementPermanentEcartsDeConversion" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifEFinancementPermanentEcartsDeConversionAnneeN1'  v-model='passifEFinancementPermanentEcartsDeConversionAnneeN1'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifEFinancementPermanentEcartsDeConversion" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Augmentation des créances immobilisées</td>
                                            <td><InputNumber v-show="true" name='passifE1FinancementPermanentAugmentationDesCreancesImmobilisees' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifE1FinancementPermanentAugmentationDesCreancesImmobilisees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifE1FinancementPermanentAugmentationDesCreancesImmobiliseesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifE1FinancementPermanentAugmentationDesCreancesImmobilisees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Diminution des dettes de financement</td>
                                            <td><InputNumber v-show="true" name='passifE2FinancementPermanentDiminutionDesDettesDeFinancement' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifE2FinancementPermanentDiminutionDesDettesDeFinancement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifE2FinancementPermanentDiminutionDesDettesDeFinancementAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifE2FinancementPermanentDiminutionDesDettesDeFinancement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> TOTAL  I  ( a+b+c+d+e )</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifTotalIFinancementPermanentTotal'  v-model='passifTotalIFinancementPermanentTotal'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifTotalIFinancementPermanentTotal" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifTotalIFinancementPermanentTotalAnneeN1'  v-model='passifTotalIFinancementPermanentTotalAnneeN1'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifTotalIFinancementPermanentTotal" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td style="text-align:center" rowspan="12" class="bilanMargeVerticale"> P<br/>A<br/>S<br/>S<br/>I<br/>F<br/> <br/>C<br/>I<br/>R<br/>C<br/>U     </td>
                                            <td class="titleCol titleGroupCol"> DETTES DU PASSIF CIRCULANT (f)</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifFCirculantDettesDuCirculant'  v-model='passifFCirculantDettesDuCirculant'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifFCirculantDettesDuCirculant" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifFCirculantDettesDuCirculantAnneeN1'  v-model='passifFCirculantDettesDuCirculantAnneeN1'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifFCirculantDettesDuCirculant" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Fournisseurs et comptes rattachés</td>
                                            <td><InputNumber v-show="true" name='passifF1CirculantFournisseursEtComptesRattaches' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifF1CirculantFournisseursEtComptesRattaches'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifF1CirculantFournisseursEtComptesRattachesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifF1CirculantFournisseursEtComptesRattaches'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Clients créditeurs, avances et acomptes</td>
                                            <td><InputNumber v-show="true" name='passifF2CirculantClientsCrediteursAvancesEtAcomptes' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifF2CirculantClientsCrediteursAvancesEtAcomptes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifF2CirculantClientsCrediteursAvancesEtAcomptesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifF2CirculantClientsCrediteursAvancesEtAcomptes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Personnel</td>
                                            <td><InputNumber v-show="true" name='passifF3CirculantPersonnel' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifF3CirculantPersonnel'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifF3CirculantPersonnelAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifF3CirculantPersonnel'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Organismes sociaux</td>
                                            <td><InputNumber v-show="true" name='passifF4CirculantOrganismesSociaux' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifF4CirculantOrganismesSociaux'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifF4CirculantOrganismesSociauxAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifF4CirculantOrganismesSociaux'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Etat</td>
                                            <td><InputNumber v-show="true" name='passifF5CirculantEtat' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifF5CirculantEtat'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifF5CirculantEtatAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifF5CirculantEtat'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Comptes d'associés</td>
                                            <td><InputNumber v-show="true" name='passifF6CirculantComptesAssocies' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifF6CirculantComptesAssocies'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifF6CirculantComptesAssociesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifF6CirculantComptesAssocies'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres créanciers</td>
                                            <td><InputNumber v-show="true" name='passifF7CirculantAutresCreanciers' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifF7CirculantAutresCreanciers'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifF7CirculantAutresCreanciersAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifF7CirculantAutresCreanciers'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Comptes de régularisation - passif</td>
                                            <td><InputNumber v-show="true" name='passifF8CirculantComptesDeRegularisation' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifF8CirculantComptesDeRegularisation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifF8CirculantComptesDeRegularisationAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifF8CirculantComptesDeRegularisation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> AUTRES PROVISIONS POUR RISQUES ET CHARGES (g)</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifGCirculantAutresProvisionsPourRisquesEtCharges' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifGCirculantAutresProvisionsPourRisquesEtCharges'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifGCirculantAutresProvisionsPourRisquesEtCharges" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifGCirculantAutresProvisionsPourRisquesEtChargesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifGCirculantAutresProvisionsPourRisquesEtCharges'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifGCirculantAutresProvisionsPourRisquesEtCharges" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> ECARTS DE CONVERSION - PASSIF (h)( Eléments circulants )</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifHCirculantEcartsDeConversion' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifHCirculantEcartsDeConversion'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifHCirculantEcartsDeConversion" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifHCirculantEcartsDeConversionAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifHCirculantEcartsDeConversion'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifHCirculantEcartsDeConversion" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> TOTAL  II  ( f+g+h )</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifTotalIICirculantTotal'  v-model='passifTotalIICirculantTotal'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifTotalIICirculantTotal" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifTotalIICirculantTotalAnneeN1'  v-model='passifTotalIICirculantTotalAnneeN1'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifTotalIICirculantTotal" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td style="text-align:center" rowspan="5" class="bilanMargeVerticale"> T<br/>R<br/>E<br/>S<br/>O        </td>
                                            <td class="titleCol titleGroupCol"> TRESORERIE PASSIF</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifTresorTresorerieTresoreriePassif'  v-model='passifTresorTresorerieTresoreriePassif'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifTresorTresorerieTresoreriePassif" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifTresorTresorerieTresoreriePassifAnneeN1'  v-model='passifTresorTresorerieTresoreriePassifAnneeN1'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifTresorTresorerieTresoreriePassif" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Crédits d'escompte</td>
                                            <td><InputNumber v-show="true" name='passifTresor1TresorerieCreditsEscompte' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifTresor1TresorerieCreditsEscompte'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifTresor1TresorerieCreditsEscompteAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifTresor1TresorerieCreditsEscompte'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Crédit de trésorerie</td>
                                            <td><InputNumber v-show="true" name='passifTresor2TresorerieCreditDeTresorerie' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifTresor2TresorerieCreditDeTresorerie'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifTresor2TresorerieCreditDeTresorerieAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifTresor2TresorerieCreditDeTresorerie'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Banques ( soldes créditeurs )</td>
                                            <td><InputNumber v-show="true" name='passifTresor3TresorerieBanques' :disabled="!bilanFieldsEnabled" v-model='selectedBilanItemLocal.passifTresor3TresorerieBanques'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" name='passifTresor3TresorerieBanquesAnneeN1' :disabled="!bilanFieldsN1Enabled" v-model='selectedBilanItemLocalAnneeN1.passifTresor3TresorerieBanques'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol"> TOTAL  III</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifTresorTresorerieTotalIII'  v-model='TresorTresorerieTotalIII'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifTresorTresorerieTotalIII" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifTresorTresorerieTotalIIIAnneeN1'  v-model='TresorTresorerieTotalIIIAnneeN1'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="passifTresorTresorerieTotalIII" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanTotalGeneral">
                                            <td style="text-align:center" rowspan="1" class="bilanMargeVerticale">      </td>
                                            <td class="titleCol titleGroupCol"> TOTAL   I+II+III</td>
                                            <td class="YearN"><div><InputNumber v-show="true" name='passifTotalGeneral'  v-model='passifTotalGeneral'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" codeTechnique="passifTotalGeneral" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" name='passifTotalGeneralAnneeN1'  v-model='passifTotalGeneralAnneeN1'  mode='decimal'  :minFractionDigits='2' :disabled="true" />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" codeTechnique="passifTotalGeneral" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                
                                    </div>
                                    
                                    <div class="bilanCPC">
                                    <!-- *********************** BILAN (CPC) ******************************* -->
                                    <table>
                                        <tr v-if="!inforiskLocal">
                                            <th></th>
                                            <th></th>
                                            <th>Exercice</th>
                                            <th>Exercice précedent</th>
                                            <th>TOTAUX EXERCICE</th>
                                            <th class="YearN1TH">N-1 
                                                <span v-if="selectedBilanItemLocalAnneeN1.inforisk" v-tooltip.top="'(inforisk)'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                                <span v-else-if="selectedBilanItemLocalAnneeN1.id && !selectedBilanItemLocalAnneeN1.estAnneePrecedente" v-tooltip.top="'Accédez au bilan '+selectedBilanItemLocalAnneeN1.dateBilan+' pour le changer'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                            </th>
                                        </tr>
                                        <tr v-else>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th>TOTAUX EXERCICE</th>
                                            <th class="YearN1TH">N-1 
                                                <span v-if="selectedBilanItemLocalAnneeN1.inforisk" v-tooltip.top="'(inforisk)'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                                <span v-else-if="selectedBilanItemLocalAnneeN1.id && !selectedBilanItemLocalAnneeN1.estAnneePrecedente" v-tooltip.top="'Accédez au bilan '+selectedBilanItemLocalAnneeN1.dateBilan+' pour le changer'"><i class="pi pi-info-circle" style="color:orange"></i></span>
                                            </th>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td style="text-align:center" rowspan="18" class="bilanMargeVerticale"> E<br>X<br>P<br>L<br>O<br>I<br>T<br>A<br>T<br>I<br>O<br>N<br></td>
                                            <td class="titleCol titleGroupCol">I PRODUITS D'EXPLOITATION</td>        
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcIExploitationProduitsExploitationExercice' v-model='cpcIExploitationProduitsExploitationExercice'  mode='decimal'  :minFractionDigits='2'  /></td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcIExploitationProduitsExploitationPrecedent' v-model='cpcIExploitationProduitsExploitationPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="true" name='cpcIExploitationProduitsExploitation' v-model='cpcIExploitationProduitsExploitation'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIExploitationProduitsExploitation" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='cpcIExploitationProduitsExploitationAnneeN1' v-model='cpcIExploitationProduitsExploitationAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIExploitationProduitsExploitation" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Ventes de marchandises</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI1ExploitationVentesDeMarchandisesExercice' v-model='selectedBilanItemLocal.cpcI1ExploitationVentesDeMarchandisesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI1ExploitationVentesDeMarchandisesPrecedent' v-model='selectedBilanItemLocal.cpcI1ExploitationVentesDeMarchandisesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcI1ExploitationVentesDeMarchandises' v-model='cpcI1ExploitationVentesDeMarchandises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcI1ExploitationVentesDeMarchandisesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcI1ExploitationVentesDeMarchandises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Ventes de biens et services produits</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI2ExploitationVentesBiensEtServicesProduitsExercice' v-model='selectedBilanItemLocal.cpcI2ExploitationVentesBiensEtServicesProduitsExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI2ExploitationVentesBiensEtServicesProduitsPrecedent' v-model='selectedBilanItemLocal.cpcI2ExploitationVentesBiensEtServicesProduitsPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcI2ExploitationVentesBiensEtServicesProduits' v-model='cpcI2ExploitationVentesBiensEtServicesProduits'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcI2ExploitationVentesBiensEtServicesProduitsAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcI2ExploitationVentesBiensEtServicesProduits'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr style="background-color: rgb(227, 227, 227);">
                                            <td class="titleCol">Chiffres d'affaires</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcI3ExploitationChiffresAffairesExercice' v-model='cpcI3ExploitationChiffresAffairesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcI3ExploitationChiffresAffairesPrecedent' v-model='cpcI3ExploitationChiffresAffairesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcI3ExploitationChiffresAffaires' v-model='cpcI3ExploitationChiffresAffaires'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="true" name='cpcI3ExploitationChiffresAffairesAnneeN1' v-model='cpcI3ExploitationChiffresAffairesAnneeN1'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Variation de stock de produits</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI4ExploitationVariationDeStockDeProduitsExercice' v-model='selectedBilanItemLocal.cpcI4ExploitationVariationDeStockDeProduitsExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI4ExploitationVariationDeStockDeProduitsPrecedent' v-model='selectedBilanItemLocal.cpcI4ExploitationVariationDeStockDeProduitsPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcI4ExploitationVariationDeStockDeProduits' v-model='cpcI4ExploitationVariationDeStockDeProduits'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcI4ExploitationVariationDeStockDeProduitsAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcI4ExploitationVariationDeStockDeProduits'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Immobilisations produites pour l'Ese p/elle même</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI5ExploitationImmobilisationsProduitesPourElleMemeExercice' v-model='selectedBilanItemLocal.cpcI5ExploitationImmobilisationsProduitesPourElleMemeExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI5ExploitationImmobilisationsProduitesPourElleMemePrecedent' v-model='selectedBilanItemLocal.cpcI5ExploitationImmobilisationsProduitesPourElleMemePrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcI5ExploitationImmobilisationsProduitesPourElleMeme' v-model='cpcI5ExploitationImmobilisationsProduitesPourElleMeme'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcI5ExploitationImmobilisationsProduitesPourElleMemeAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcI5ExploitationImmobilisationsProduitesPourElleMeme'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Subvention d'exploitation</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI6ExploitationSubventionDExploitationExercice' v-model='selectedBilanItemLocal.cpcI6ExploitationSubventionDExploitationExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI6ExploitationSubventionDExploitationPrecedent' v-model='selectedBilanItemLocal.cpcI6ExploitationSubventionDExploitationPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcI6ExploitationSubventionDExploitation' v-model='cpcI6ExploitationSubventionDExploitation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcI6ExploitationSubventionDExploitationAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcI6ExploitationSubventionDExploitation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres produits d'exploitation</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI7ExploitationAutresProduitsExploitationExercice' v-model='selectedBilanItemLocal.cpcI7ExploitationAutresProduitsExploitationExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI7ExploitationAutresProduitsExploitationPrecedent' v-model='selectedBilanItemLocal.cpcI7ExploitationAutresProduitsExploitationPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcI7ExploitationAutresProduitsExploitation' v-model='cpcI7ExploitationAutresProduitsExploitation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcI7ExploitationAutresProduitsExploitationAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcI7ExploitationAutresProduitsExploitation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Reprises d'exploitation; transfert de charges</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI8ExploitationReprisesExploitationTransfertDeChargesExercice' v-model='selectedBilanItemLocal.cpcI8ExploitationReprisesExploitationTransfertDeChargesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcI8ExploitationReprisesExploitationTransfertDeChargesPrecedent' v-model='selectedBilanItemLocal.cpcI8ExploitationReprisesExploitationTransfertDeChargesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcI8ExploitationReprisesExploitationTransfertDeCharges' v-model='cpcI8ExploitationReprisesExploitationTransfertDeCharges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcI8ExploitationReprisesExploitationTransfertDeChargesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcI8ExploitationReprisesExploitationTransfertDeCharges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">II CHARGES D'EXPLOITATION</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcIIExploitationChargesExploitationExercice'  v-model='cpcIIExploitationChargesExploitationExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcIIExploitationChargesExploitationPrecedent'  v-model='cpcIIExploitationChargesExploitationPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="true" name='cpcIIExploitationChargesExploitation'  v-model='cpcIIExploitationChargesExploitation'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIIExploitationChargesExploitation" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='cpcIIExploitationChargesExploitationAnneeN1'  v-model='cpcIIExploitationChargesExploitationAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIIExploitationChargesExploitation" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Achats revendus de marchandises</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII1ExploitationAchatsRevendusDeMarchandisesExercice' v-model='selectedBilanItemLocal.cpcII1ExploitationAchatsRevendusDeMarchandisesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII1ExploitationAchatsRevendusDeMarchandisesPrecedent' v-model='selectedBilanItemLocal.cpcII1ExploitationAchatsRevendusDeMarchandisesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcII1ExploitationAchatsRevendusDeMarchandises' v-model='cpcII1ExploitationAchatsRevendusDeMarchandises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcII1ExploitationAchatsRevendusDeMarchandisesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcII1ExploitationAchatsRevendusDeMarchandises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Achat consommes de matières et de fournitures</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresExercice' v-model='selectedBilanItemLocal.cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresPrecedent' v-model='selectedBilanItemLocal.cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcII2ExploitationAchatConsommesDeMatieresEtDeFournitures' v-model='cpcII2ExploitationAchatConsommesDeMatieresEtDeFournitures'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcII2ExploitationAchatConsommesDeMatieresEtDeFournitures'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres charges externes</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII3ExploitationAutresChargesExternesExercice' v-model='selectedBilanItemLocal.cpcII3ExploitationAutresChargesExternesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII3ExploitationAutresChargesExternesPrecedent' v-model='selectedBilanItemLocal.cpcII3ExploitationAutresChargesExternesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcII3ExploitationAutresChargesExternes' v-model='cpcII3ExploitationAutresChargesExternes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcII3ExploitationAutresChargesExternesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcII3ExploitationAutresChargesExternes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Impôts et taxes</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII4ExploitationImpotsEtTaxesExercice' v-model='selectedBilanItemLocal.cpcII4ExploitationImpotsEtTaxesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII4ExploitationImpotsEtTaxesPrecedent' v-model='selectedBilanItemLocal.cpcII4ExploitationImpotsEtTaxesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcII4ExploitationImpotsEtTaxes' v-model='cpcII4ExploitationImpotsEtTaxes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcII4ExploitationImpotsEtTaxesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcII4ExploitationImpotsEtTaxes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Charges de personnel</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII5ExploitationChargesPersonnelExercice' v-model='selectedBilanItemLocal.cpcII5ExploitationChargesPersonnelExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII5ExploitationChargesPersonnelPrecedent' v-model='selectedBilanItemLocal.cpcII5ExploitationChargesPersonnelPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcII5ExploitationChargesPersonnel' v-model='cpcII5ExploitationChargesPersonnel'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcII5ExploitationChargesPersonnelAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcII5ExploitationChargesPersonnel'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres charges d'exploitation</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII6ExploitationAutresChargesExploitationExercice' v-model='selectedBilanItemLocal.cpcII6ExploitationAutresChargesExploitationExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII6ExploitationAutresChargesExploitationPrecedent' v-model='selectedBilanItemLocal.cpcII6ExploitationAutresChargesExploitationPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcII6ExploitationAutresChargesExploitation' v-model='cpcII6ExploitationAutresChargesExploitation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcII6ExploitationAutresChargesExploitationAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcII6ExploitationAutresChargesExploitation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Dotations d'exploitation</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII7ExploitationDotationsExploitationExercice' v-model='selectedBilanItemLocal.cpcII7ExploitationDotationsExploitationExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcII7ExploitationDotationsExploitationPrecedent' v-model='selectedBilanItemLocal.cpcII7ExploitationDotationsExploitationPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcII7ExploitationDotationsExploitation' v-model='cpcII7ExploitationDotationsExploitation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcII7ExploitationDotationsExploitationAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcII7ExploitationDotationsExploitation'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">III RESULTAT D'EXPLOITATION  ( I - II )</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcIIIExploitationResultatExploitationExercice'  v-model='cpcIIIExploitationResultatExploitationExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcIIIExploitationResultatExploitationPrecedent'  v-model='cpcIIIExploitationResultatExploitationPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="true" name='cpcIIIExploitationResultatExploitation'  v-model='cpcIIIExploitationResultatExploitation'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIIIExploitationResultatExploitation" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='cpcIIIExploitationResultatExploitationAnneeN1'  v-model='cpcIIIExploitationResultatExploitationAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIIIExploitationResultatExploitation" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td style="text-align:center" rowspan="11" class="bilanMargeVerticale"> F<br>I<br>N<br>A<br>N<br>C<br>I<br>E<br>R<br></td>
                                            <td class="titleCol titleGroupCol">IV PRODUITS FINANCIERS</td>        
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcIVFinancierProduitsFinanciersExercice'  v-model='cpcIVFinancierProduitsFinanciersExercice'  mode='decimal'  :minFractionDigits='2'   /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcIVFinancierProduitsFinanciersPrecedent'  v-model='cpcIVFinancierProduitsFinanciersPrecedent'  mode='decimal'  :minFractionDigits='2'   /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="true" name='cpcIVFinancierProduitsFinanciers'  v-model='cpcIVFinancierProduitsFinanciers'  mode='decimal'  :minFractionDigits='2'   />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIVFinancierProduitsFinanciers" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='cpcIVFinancierProduitsFinanciersAnneeN1'  v-model='cpcIVFinancierProduitsFinanciersAnneeN1'  mode='decimal'  :minFractionDigits='2'   />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIVFinancierProduitsFinanciers" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            </tr>
                                        <tr>
                                            <td class="titleCol">Produits des titres de participation et autres titres immobilises</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesExercice' v-model='selectedBilanItemLocal.cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesPrecedent' v-model='selectedBilanItemLocal.cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilises' v-model='cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilises'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Gains de change</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIV2FinancierGainsDeChangeExercice' v-model='selectedBilanItemLocal.cpcIV2FinancierGainsDeChangeExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIV2FinancierGainsDeChangePrecedent' v-model='selectedBilanItemLocal.cpcIV2FinancierGainsDeChangePrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcIV2FinancierGainsDeChange' v-model='cpcIV2FinancierGainsDeChange'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIV2FinancierGainsDeChangeAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcIV2FinancierGainsDeChange'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Intérêts et autres produits financiers</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIV3FinancierInteretsEtAutresProduitsFinanciersExercice' v-model='selectedBilanItemLocal.cpcIV3FinancierInteretsEtAutresProduitsFinanciersExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIV3FinancierInteretsEtAutresProduitsFinanciersPrecedent' v-model='selectedBilanItemLocal.cpcIV3FinancierInteretsEtAutresProduitsFinanciersPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcIV3FinancierInteretsEtAutresProduitsFinanciers' v-model='cpcIV3FinancierInteretsEtAutresProduitsFinanciers'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIV3FinancierInteretsEtAutresProduitsFinanciersAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcIV3FinancierInteretsEtAutresProduitsFinanciers'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Reprises financières; transfert de charges</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIV4FinancierReprisesFinancieresTransfertDeChargesExercice' v-model='selectedBilanItemLocal.cpcIV4FinancierReprisesFinancieresTransfertDeChargesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIV4FinancierReprisesFinancieresTransfertDeChargesPrecedent' v-model='selectedBilanItemLocal.cpcIV4FinancierReprisesFinancieresTransfertDeChargesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcIV4FinancierReprisesFinancieresTransfertDeCharges' v-model='cpcIV4FinancierReprisesFinancieresTransfertDeCharges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIV4FinancierReprisesFinancieresTransfertDeChargesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcIV4FinancierReprisesFinancieresTransfertDeCharges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">V CHARGES FINANCIERES</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcVFinancierChargesFinancieresExercice'  v-model='cpcVFinancierChargesFinancieresExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcVFinancierChargesFinancieresPrecedent'  v-model='cpcVFinancierChargesFinancieresPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="true" name='cpcVFinancierChargesFinancieres'  v-model='cpcVFinancierChargesFinancieres'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcVFinancierChargesFinancieres" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='cpcVFinancierChargesFinancieresAnneeN1'  v-model='cpcVFinancierChargesFinancieresAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcVFinancierChargesFinancieres" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Charges d'intérêts</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcV1FinancierChargesInteretExercice' v-model='selectedBilanItemLocal.cpcV1FinancierChargesInteretExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcV1FinancierChargesInteretPrecedent' v-model='selectedBilanItemLocal.cpcV1FinancierChargesInteretPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcV1FinancierChargesInteret' v-model='cpcV1FinancierChargesInteret'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcV1FinancierChargesInteretAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcV1FinancierChargesInteret'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Pertes de changes</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcV2FinancierPertesDeChangesExercice' v-model='selectedBilanItemLocal.cpcV2FinancierPertesDeChangesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcV2FinancierPertesDeChangesPrecedent' v-model='selectedBilanItemLocal.cpcV2FinancierPertesDeChangesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcV2FinancierPertesDeChanges' v-model='cpcV2FinancierPertesDeChanges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcV2FinancierPertesDeChangesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcV2FinancierPertesDeChanges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres charges financières</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcV3FinancierAutresChargesFinancieresExercice' v-model='selectedBilanItemLocal.cpcV3FinancierAutresChargesFinancieresExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcV3FinancierAutresChargesFinancieresPrecedent' v-model='selectedBilanItemLocal.cpcV3FinancierAutresChargesFinancieresPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcV3FinancierAutresChargesFinancieres' v-model='cpcV3FinancierAutresChargesFinancieres'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcV3FinancierAutresChargesFinancieresAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcV3FinancierAutresChargesFinancieres'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Dotations financières</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcV4FinancierDotationFinancieresExercice' v-model='selectedBilanItemLocal.cpcV4FinancierDotationFinancieresExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcV4FinancierDotationFinancieresPrecedent' v-model='selectedBilanItemLocal.cpcV4FinancierDotationFinancieresPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcV4FinancierDotationFinancieres' v-model='cpcV4FinancierDotationFinancieres'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcV4FinancierDotationFinancieresAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcV4FinancierDotationFinancieres'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">VI RESULTAT FINANCIER ( IV - V )</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcVIFinancierResultatFinancierExercice'  v-model='cpcVIFinancierResultatFinancierExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcVIFinancierResultatFinancierPrecedent'  v-model='cpcVIFinancierResultatFinancierPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="true" name='cpcVIFinancierResultatFinancier'  v-model='cpcVIFinancierResultatFinancier'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcVIFinancierResultatFinancier" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='cpcVIFinancierResultatFinancierAnneeN1'  v-model='cpcVIFinancierResultatFinancierAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcVIFinancierResultatFinancier" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td style="text-align:center" rowspan="1" class="bilanMargeVerticale">
                                            </td>
                                            <td class="titleCol titleGroupCol">VII RESULTAT COURANT ( III + VI)</td>        
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcVIICourantResultatCourantExercice'  v-model='cpcVIICourantResultatCourantExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcVIICourantResultatCourantPrecedent'  v-model='cpcVIICourantResultatCourantPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="true" name='cpcVIICourantResultatCourant'  v-model='cpcVIICourantResultatCourant'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcVIICourantResultatCourant" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='cpcVIICourantResultatCourantAnneeN1'  v-model='cpcVIICourantResultatCourantAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcVIICourantResultatCourant" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td style="text-align:center" rowspan="12" class="bilanMargeVerticale"> N<br>O<br>N<br> <br>C<br>O<br>U<br>R<br>A<br>N<br>T<br></td>
                                            <td class="titleCol titleGroupCol">VIII PRODUITS NON COURANTS</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcVIIINonCourantProduitsNonCourantsExercice'  v-model='cpcVIIINonCourantProduitsNonCourantsExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcVIIINonCourantProduitsNonCourantsPrecedent'  v-model='cpcVIIINonCourantProduitsNonCourantsPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="true" name='cpcVIIINonCourantProduitsNonCourants'  v-model='cpcVIIINonCourantProduitsNonCourants'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcVIIINonCourantProduitsNonCourants" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='cpcVIIINonCourantProduitsNonCourantsAnneeN1'  v-model='cpcVIIINonCourantProduitsNonCourantsAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcVIIINonCourantProduitsNonCourants" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Produits des cessions d'immobilisations</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsExercice' v-model='selectedBilanItemLocal.cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsPrecedent' v-model='selectedBilanItemLocal.cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcVIII1NonCourantProduitsDesCessionsDImmobilisations' v-model='cpcVIII1NonCourantProduitsDesCessionsDImmobilisations'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcVIII1NonCourantProduitsDesCessionsDImmobilisations'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Subventions d'équilibre</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII2NonCourantSubventionsDEquilibreExercice' v-model='selectedBilanItemLocal.cpcVIII2NonCourantSubventionsDEquilibreExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII2NonCourantSubventionsDEquilibrePrecedent' v-model='selectedBilanItemLocal.cpcVIII2NonCourantSubventionsDEquilibrePrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcVIII2NonCourantSubventionsDEquilibre' v-model='cpcVIII2NonCourantSubventionsDEquilibre'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcVIII2NonCourantSubventionsDEquilibreAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcVIII2NonCourantSubventionsDEquilibre'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Reprises sur subventions d'investissement</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII3NonCourantReprisesSurSubventionsDInvestissementExercice' v-model='selectedBilanItemLocal.cpcVIII3NonCourantReprisesSurSubventionsDInvestissementExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII3NonCourantReprisesSurSubventionsDInvestissementPrecedent' v-model='selectedBilanItemLocal.cpcVIII3NonCourantReprisesSurSubventionsDInvestissementPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcVIII3NonCourantReprisesSurSubventionsDInvestissement' v-model='cpcVIII3NonCourantReprisesSurSubventionsDInvestissement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcVIII3NonCourantReprisesSurSubventionsDInvestissementAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcVIII3NonCourantReprisesSurSubventionsDInvestissement'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres produits non courants</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII4NonCourantAutresProduitsNonCourantsExercice' v-model='selectedBilanItemLocal.cpcVIII4NonCourantAutresProduitsNonCourantsExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII4NonCourantAutresProduitsNonCourantsPrecedent' v-model='selectedBilanItemLocal.cpcVIII4NonCourantAutresProduitsNonCourantsPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcVIII4NonCourantAutresProduitsNonCourants' v-model='cpcVIII4NonCourantAutresProduitsNonCourants'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcVIII4NonCourantAutresProduitsNonCourantsAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcVIII4NonCourantAutresProduitsNonCourants'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Reprises non courantes; transferts de charges</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesExercice' v-model='selectedBilanItemLocal.cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesPrecedent' v-model='selectedBilanItemLocal.cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcVIII5NonCourantReprisesNonCourantesTransfertsDeCharges' v-model='cpcVIII5NonCourantReprisesNonCourantesTransfertsDeCharges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcVIII5NonCourantReprisesNonCourantesTransfertsDeCharges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>

                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">IX CHARGES NON COURANTES</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcIXNonCourantChargesNonCourantesExercice'  v-model='cpcIXNonCourantChargesNonCourantesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcIXNonCourantChargesNonCourantesPrecedent'  v-model='cpcIXNonCourantChargesNonCourantesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="true" name='cpcIXNonCourantChargesNonCourantes'  v-model='cpcIXNonCourantChargesNonCourantes'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIXNonCourantChargesNonCourantes" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='cpcIXNonCourantChargesNonCourantesAnneeN1'  v-model='cpcIXNonCourantChargesNonCourantesAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" 
                                                codeTechnique="cpcIXNonCourantChargesNonCourantes" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Valeurs nettes d'amortissements des immobilisations cédées</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesExercice' v-model='selectedBilanItemLocal.cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesPrecedent' v-model='selectedBilanItemLocal.cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedees' v-model='cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Subventions accordées</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIX2NonCourantSubventionsAccordeesExercice' v-model='selectedBilanItemLocal.cpcIX2NonCourantSubventionsAccordeesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIX2NonCourantSubventionsAccordeesPrecedent' v-model='selectedBilanItemLocal.cpcIX2NonCourantSubventionsAccordeesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcIX2NonCourantSubventionsAccordees' v-model='cpcIX2NonCourantSubventionsAccordees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIX2NonCourantSubventionsAccordeesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcIX2NonCourantSubventionsAccordees'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Autres charges non courantes</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIX3NonCourantAutresChargesNonCourantesExercice' v-model='selectedBilanItemLocal.cpcIX3NonCourantAutresChargesNonCourantesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIX3NonCourantAutresChargesNonCourantesPrecedent' v-model='selectedBilanItemLocal.cpcIX3NonCourantAutresChargesNonCourantesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcIX3NonCourantAutresChargesNonCourantes' v-model='cpcIX3NonCourantAutresChargesNonCourantes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIX3NonCourantAutresChargesNonCourantesAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcIX3NonCourantAutresChargesNonCourantes'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr>
                                            <td class="titleCol">Dotations non courantes aux amortiss. et provision</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionExercice' v-model='selectedBilanItemLocal.cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionPrecedent' v-model='selectedBilanItemLocal.cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvision' v-model='cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvision'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvision'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">X RESULTAT NON COURANT ( VIII- IV )</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcXResultatNonCourantExercice'  v-model='cpcXResultatNonCourantExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcXResultatNonCourantPrecedent'  v-model='cpcXResultatNonCourantPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcXResultatNonCourant'  v-model='cpcXResultatNonCourant'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="true" name='cpcXResultatNonCourantAnneeN1'  v-model='cpcXResultatNonCourantAnneeN1'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td style="text-align:center" rowspan="7" class="bilanMargeVerticale"></td>
                                            <td class="titleCol titleGroupCol">XI RESULTAT AVANT IMPOTS ( VII+ X )</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcXIResultatAvantImpotsExercice'   v-model='cpcXIResultatAvantImpotsExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcXIResultatAvantImpotsPrecedent'   v-model='cpcXIResultatAvantImpotsPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcXIResultatAvantImpots'   v-model='cpcXIResultatAvantImpots'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="true" name='cpcXIResultatAvantImpotsAnneeN1'   v-model='cpcXIResultatAvantImpotsAnneeN1'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">XII IMPOTS SUR LES RESULTATS </td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcXIIImpotsSurLesResultatsExercice' v-model='selectedBilanItemLocal.cpcXIIImpotsSurLesResultatsExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="!bilanFieldsEnabled" name='cpcXIIImpotsSurLesResultatsPrecedent' v-model='selectedBilanItemLocal.cpcXIIImpotsSurLesResultatsPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcXIIImpotsSurLesResultats' v-model='cpcXIIImpotsSurLesResultats'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="!bilanFieldsN1Enabled" name='cpcXIIImpotsSurLesResultatsAnneeN1' v-model='selectedBilanItemLocalAnneeN1.cpcXIIImpotsSurLesResultats'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanTotal">
                                            <td class="titleCol titleGroupCol">XIII RESULTAT NET ( XI - XII )</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcXIIIResultatNetExercice'  v-model='cpcXIIIResultatNetExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcXIIIResultatNetPrecedent'  v-model='cpcXIIIResultatNetPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="true" name='cpcXIIIResultatNet'  v-model='cpcXIIIResultatNet'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" codeTechnique="cpcXIIIResultatNet" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='cpcXIIIResultatNetAnneeN1'  v-model='cpcXIIIResultatNetAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" codeTechnique="cpcXIIIResultatNet" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">XIV TOTAL DES PRODUITS ( I + IV + VIII )</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcXIVTotalDesProduitsExercice'  v-model='cpcXIVTotalDesProduitsExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcXIVTotalDesProduitsPrecedent'  v-model='cpcXIVTotalDesProduitsPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcXIVTotalDesProduits'  v-model='cpcXIVTotalDesProduits'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="true" name='cpcXIVTotalDesProduitsAnneeN1'  v-model='cpcXIVTotalDesProduitsAnneeN1'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanHead">
                                            <td class="titleCol titleGroupCol">XV TOTAL DES CHARGES ( II + V + IX + XII )</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcXVTotalDesChargesExercice' v-model='cpcXVTotalDesChargesExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcXVTotalDesChargesPrecedent' v-model='cpcXVTotalDesChargesPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="true" :disabled="true" name='cpcXVTotalDesCharges' v-model='cpcXVTotalDesCharges'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN1">
                                                <InputNumber v-show="true" :disabled="true" name='cpcXVTotalDesChargesAnneeN1' v-model='cpcXVTotalDesChargesAnneeN1'  mode='decimal'  :minFractionDigits='2'  /></td>
                                        </tr>
                                        <tr class="bilanTotal">
                                            <td class="titleCol titleGroupCol">XVI RESULTAT NET ( XIV - XV )</td>    
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcXVIResultatNETExercice' v-model='cpcXVIResultatNETExercice'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td><InputNumber v-show="!inforiskLocal" :disabled="true" name='cpcXVIResultatNETPrecedent' v-model='cpcXVIResultatNETPrecedent'  mode='decimal'  :minFractionDigits='2'  /></td>
                                            <td class="YearN"><div><InputNumber v-show="true" :disabled="true" name='cpcXVIResultatNET' v-model='cpcXVIResultatNET'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocal.id+'-'+refMappingBilanListeChamps.length" codeTechnique="cpcXVIResultatNET" 
                                                :selectedBilanItem="selectedBilanItemLocal"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                            <td class="YearN1">
                                                <div><InputNumber v-show="true" :disabled="true" name='cpcXVIResultatNETAnneeN1' v-model='cpcXVIResultatNETAnneeN1'  mode='decimal'  :minFractionDigits='2'  />
                                                <InformationExclamationVue :key="selectedBilanItemLocalAnneeN1.id+'-'+refMappingBilanListeChamps.length" codeTechnique="cpcXVIResultatNET" 
                                                :selectedBilanItem="selectedBilanItemLocalAnneeN1"
                                                :refMappingBilanListeChamps="refMappingBilanListeChamps"></InformationExclamationVue>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                        <!-- *********************************************************** -->
                                </div>
                            </kendo-tabstrip>

                        </div>
                    </div>
                    <!-- ========================== second Column =========================== -->
                    <!-- =================================================================== -->
                </div>

               <div class="p-d-flex p-jc-end" style="display: flex;">

                    &nbsp;<ClientBilanLoadCsv v-if="(createBilanFlag || selectedBilanItemLocal.id) && !inforiskLocal && bilanFieldsEnabled && currentUser.jsonUserRights.gestionBilan" @BilanLoaded="onBilanLoaded"></ClientBilanLoadCsv>
                    &nbsp;<P-Button v-if="(createBilanFlag || selectedBilanItemLocal.id) && !inforiskLocal && bilanFieldsEnabled && currentUser.jsonUserRights.gestionBilan" class="p-button-success p-mr-2" @click = "downloadItem('/bilanexemple.csv')"  icon="pi pi-question-circle" v-tooltip="'Télécharger un modèle CSV pour l\'import'"></P-Button>
                    &nbsp;<P-Button v-if="selectedBilanItemLocal.id && currentUser.jsonUserRights.gestionBilan" label="Supprimer" class="p-button-raised p-button-danger" @click.prevent="confirmDeleteSelected(selectedBilanItemLocal)"/>
                    &nbsp;<P-Button label="Annuler" class="p-button-raised p-button-secondary"  @click.prevent="closeDialog" />
                    &nbsp;<P-Button v-if="!inforiskLocal && (selectedBilanItemLocal.id>0 || createBilanFlag) && !selectedBilanItemLocal.inforisk && bilanFieldsEnabled && currentUser.jsonUserRights.gestionBilan" label="Enregistrer" class="p-button-raised"  @click="onSubmit" :loading="isSaving"  />
                </div>

            </V-Form>
            
        </Dialog>

        <!-- Delete Dialog -->
</template>

<script>
import Header from "../Header.vue";
import InputSwitch from 'primevue/inputswitch';
// import CRUDService from "../1-common-components/crud.service";
import {} from 'vue-underscore';
import Button from 'primevue/button';
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper';
import { TabStrip } from '@progress/kendo-layout-vue-wrapper';
import CRUDService from "../../1-common-components/crud.service";
import { DateInput } from '@progress/kendo-dateinputs-vue-wrapper';
import Axios from 'axios';

import Dialog from 'primevue/dialog';
import ConfirmDialog from 'primevue/confirmdialog';
import { Field, Form, ErrorMessage, } from 'vee-validate';
import * as yup from 'yup';
import LoadCsv from "../LoadCsv.vue";
import CsvToDb from "../ClientCSVtoDB.service";
import UploadBilan from '../UploadBilan.vue'

import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import crudService from '../../1-common-components/crud.service';
import Dropdown from 'primevue/dropdown';
import Password from 'primevue/password'
import InformationExclamationVue from './InformationExclamation.vue';



export default {
    components:{
        Header, 
        Dropdown,
        InputSwitch,
        Password,
        'kendo-dropdownlist': DropDownList,

        'kendo-tabstrip': TabStrip,
        'kendo-dateinput': DateInput,
        Dialog,
        Button,
        ConfirmDialog,
        'V-Field':Field,
        'V-Form':Form,
        'ClientBilanLoadCsv' : LoadCsv,
        UploadBilan, 
        TabView,
        TabPanel,
        InformationExclamationVue,
        ErrorMessage
    },
    //------------------------------ Data -----------------------------
    data() {
        return {
            refMappingBilanListeChamps:[],
            createBilanFlag:false,
            showBilanDialogFlagLocal:false,
            selectedBilanItemLocal : {},//new Bilan(),
            selectedBilanItemLocalAnneeN1 : {},
            inforiskLocal:false,
            years:[],
            anneesbilan:[],
            companyInforisk:null,
            isMounted:false,
            isSaving:false,
            InforiskLoading:false,
            foundBilanN1:false,
            deleting:false,
            credentials:{username:"",password:""},
            actifMapping:{
                            'actifAImmobiliseImmobilisationEnNonValeurBrut':'_dActifA',
                            'actifA1ImmobiliseFraisPreliminairesBrut':'_dActifA1',
                            'actifA2ImmobiliseChargesArepartirSurplusieursExercicesBrut':'_dActifA2',
                            'actifA3ImmobilisePrimesDeRemboursementDesObligationsBrut':'_dActifA3',
                            'actifBImmobiliseImmobilisationsIncorporellesBrut':'_dActifB',
                            'actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementBrut':'_dActifB1',
                            'actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesBrut':'_dActifB2',
                            'actifB3ImmobiliseFondsCommercialBrut':'_dActifB3',
                            'actifB4ImmobiliseAutresImmobilisationsIncorporellesBrut':'_dActifB4',
                            'actifCImmobiliseImmobilisationsCorporellesBrut':'_dActifC',
                            'actifC1ImmobiliseTerrainsBrut':'_dActifC1',
                            'actifC2ImmobiliseConstructionsBrut':'_dActifC2',
                            'actifC3ImmobiliseInstallationsTechniqueMaterielOutillageBrut':'_dActifC3',
                            'actifC4ImmobiliseMaterielDeTransportBrut':'_dActifC4',
                            'actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversBrut':'_dActifC5',
                            'actifC6ImmobiliseAutresImmobilisationsCorporellesBrut':'_dActifC6',
                            'actifC7ImmobiliseImmobilisationsCorporellesEnCoursBrut':'_dActifC7',
                            'actifDImmobiliseImmobilisationsFinancieresBrut':'_dActifD',
                            'actifD1ImmobilisePretsImmobilisesBrut':'_dActifD1',
                            'actifD2ImmobiliseAutresCreancesFinancieresBrut':'_dActifD2',
                            'actifD3ImmobiliseTitresDeParticipationBrut':'_dActifD3',
                            'actifD4ImmobiliseAutresTitresImmobilisesBrut':'_dActifD4',
                            'actifEImmobiliseEcartsDeConversionBrut':'_dActifE',
                            'actifE1ImmobiliseDiminutionDesCreancesImmobiliseesBrut':'_dActifE1',
                            'actifE2ImmobiliseAugmentationDesDettesDeFinancementBrut':'_dActifE2',
                            'actifTotalIImmobiliseTotalBrut':'_dActifTotal_I',
                            'actifFCirculantStocksBrut':'_dActifF',
                            'actifF1CirculantMarchandisesBrut':'_dActifF1',
                            'actifF2CirculantMatieresEtFournituresConsommablesBrut':'_dActifF2',
                            'actifF3CirculantProduitsEnCoursBrut':'_dActifF3',
                            'actifF4CirculantProduitsIntermediairesEtProduitsResiduelsBrut':'_dActifF4',
                            'actifF5CirculantProduitsFinisBrut':'_dActifF5',
                            'actifGCirculantCreancesCirculantBrut':'_dActifG',
                            'actifG1CirculantFournisseursdebiteursAvancesAcomptesBrut':'_dActifG1',
                            'actifG2CirculantClientsEtComptesRattachesBrut':'_dActifG2',
                            'actifG3CirculantPersonnelBrut':'_dActifG3',
                            'actifG4CirculantEtatBrut':'_dActifG4',
                            'actifG5CirculantCompteAssociesBrut':'_dActifG5',
                            'actifG6CirculantAutresDebiteursBrut':'_dActifG6',
                            'actifG7CirculantCompteRegularisationBrut':'_dActifG7',
                            'actifHCirculantTitresEtValeursDePlacementBrut':'_dActifH',                            
                            'actifICirculantEcartDeConversionBrut':'_dActifI',
                            'actifTotalIICirculantTotalBrut':'_dActifTotal_II',
                            'actifTresor1TresorerieChequesEtValeursAEncaisserBrut':'_dActifTresor1',
                            'actifTresor2TresorerieBanquesTGEtCPBrut':'_dActifTresor2',
                            'actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsBrut':'_dActifTresor3',
                            'actifTresorTresorerieTotalBrut':'_dActifTresor',
                            'actifTotalGeneralBrut':'_dActifTotalGeneral',

                            'actifAImmobiliseImmobilisationEnNonValeur':'_dActifA',
                            'actifA1ImmobiliseFraisPreliminaires':'_dActifA1',
                            'actifA2ImmobiliseChargesArepartirSurplusieursExercices':'_dActifA2',
                            'actifA3ImmobilisePrimesDeRemboursementDesObligations':'_dActifA3',
                            'actifBImmobiliseImmobilisationsIncorporelles':'_dActifB',
                            'actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppement':'_dActifB1',
                            'actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilaires':'_dActifB2',
                            'actifB3ImmobiliseFondsCommercial':'_dActifB3',
                            'actifB4ImmobiliseAutresImmobilisationsIncorporelles':'_dActifB4',
                            'actifCImmobiliseImmobilisationsCorporelles':'_dActifC',
                            'actifC1ImmobiliseTerrains':'_dActifC1',
                            'actifC2ImmobiliseConstructions':'_dActifC2',
                            'actifC3ImmobiliseInstallationsTechniqueMaterielOutillage':'_dActifC3',
                            'actifC4ImmobiliseMaterielDeTransport':'_dActifC4',
                            'actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDivers':'_dActifC5',
                            'actifC6ImmobiliseAutresImmobilisationsCorporelles':'_dActifC6',
                            'actifC7ImmobiliseImmobilisationsCorporellesEnCours':'_dActifC7',
                            'actifDImmobiliseImmobilisationsFinancieres':'_dActifD',
                            'actifD1ImmobilisePretsImmobilises':'_dActifD1',
                            'actifD2ImmobiliseAutresCreancesFinancieres':'_dActifD2',
                            'actifD3ImmobiliseTitresDeParticipation':'_dActifD3',
                            'actifD4ImmobiliseAutresTitresImmobilises':'_dActifD4',
                            'actifEImmobiliseEcartsDeConversion':'_dActifE',
                            'actifE1ImmobiliseDiminutionDesCreancesImmobilisees':'_dActifE1',
                            'actifE2ImmobiliseAugmentationDesDettesDeFinancement':'_dActifE2',
                            'actifTotalIImmobiliseTotal':'_dActifTotal_I',
                            'actifFCirculantStocks':'_dActifF',
                            'actifF1CirculantMarchandises':'_dActifF1',
                            'actifF2CirculantMatieresEtFournituresConsommables':'_dActifF2',
                            'actifF3CirculantProduitsEnCours':'_dActifF3',
                            'actifF4CirculantProduitsIntermediairesEtProduitsResiduels':'_dActifF4',
                            'actifF5CirculantProduitsFinis':'_dActifF5',
                            'actifGCirculantCreancesCirculant':'_dActifG',
                            'actifG1CirculantFournisseursdebiteursAvancesAcomptes':'_dActifG1',
                            'actifG2CirculantClientsEtComptesRattaches':'_dActifG2',
                            'actifG3CirculantPersonnel':'_dActifG3',
                            'actifG4CirculantEtat':'_dActifG4',
                            'actifG5CirculantCompteAssocies':'_dActifG5',
                            'actifG6CirculantAutresDebiteurs':'_dActifG6',
                            'actifG7CirculantCompteRegularisation':'_dActifG7',
                            'actifHCirculantTitresEtValeursDePlacement':'_dActifH',                            
                            'actifICirculantEcartDeConversion':'_dActifI',
                            'actifTotalIICirculantTotal':'_dActifTotal_II',
                            'actifTresor1TresorerieChequesEtValeursAEncaisser':'_dActifTresor1',
                            'actifTresor2TresorerieBanquesTGEtCP':'_dActifTresor2',
                            'actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifs':'_dActifTresor3',
                            'actifTresorTresorerieTotal':'_dActifTresor',
                            'actifTotalGeneral':'_dActifTotalGeneral'
                            },
                            passifMapping:{
                            'passifAFinancementPermanentCapitauxPropres':'_dPassifA',
                            'passifA2FinancementPermanentCapitalPersonnel':'_dPassifA2',
                            'passifA3FinancementPermanentMoinsActionnairesCapitalsouscritnonappelenonverse':'_dPassifA3',
                            'passifA6FinancementPermanentPrimeEmissionFusionApport':'_dPassifA6',
                            'passifA7FinancementPermanentEcartsDeReevaluation':'_dPassifA7',
                            'passifA8FinancementPermanentReserveLegale':'_dPassifA8',
                            'passifA9FinancementPermanentAutresReserves':'_dPassifA9',
                            'passifA10FinancementPermanentReportANouveau':'_dPassifA10',
                            'passifA11FinancementPermanentResultatNetsEnInstanceDAffectation':'_dPassifA11',
                            'passifA12FinancementPermanentResultatNetDeLexercice':'_dPassifA12',
                            'passifBFinancementPermanentCapitauxPropresAssimiles':'_dPassifB',
                            'passifB1FinancementPermanentSubventionInvestissement':'_dPassifB1',
                            'passifB2FinancementPermanentProvisionReglementees':'_dPassifB2',
                            'passifCFinancementPermanentDettesDeFinancement':'_dPassifC',
                            'passifC1FinancementPermanentEmpruntsObligataires':'_dPassifC1',
                            'passifC2FinancementPermanentAutresDettesDeFinancement':'_dPassifC2',
                            'passifDFinancementPermanentProvisionsDurablesPourRisquesEtCharges':'_dPassifD',
                            'passifD1FinancementPermanentProvisionsPourRisques':'_dPassifD1',
                            'passifD2FinancementPermanentProvisionsPourCharges':'_dPassifD2',
                            'passifEFinancementPermanentEcartsDeConversion':'_dPassifE',
                            'passifE1FinancementPermanentAugmentationDesCreancesImmobilisees':'_dPassifE1',
                            'passifE2FinancementPermanentDiminutionDesDettesDeFinancement':'_dPassifE2',
                            'passifTotalIFinancementPermanentTotal':'_dPassifTotal_I',
                            'passifFCirculantDettesDuCirculant':'_dPassifF',
                            'passifF1CirculantFournisseursEtComptesRattaches':'_dPassifF1',
                            'passifF2CirculantClientsCrediteursAvancesEtAcomptes':'_dPassifF2',
                            'passifF3CirculantPersonnel':'_dPassifF3',
                            'passifF4CirculantOrganismesSociaux':'_dPassifF4',
                            'passifF5CirculantEtat':'_dPassifF5',
                            'passifF6CirculantComptesAssocies':'_dPassifF6',
                            'passifF7CirculantAutresCreanciers':'_dPassifF7',
                            'passifF8CirculantComptesDeRegularisation':'_dPassifF8',
                            'passifGCirculantAutresProvisionsPourRisquesEtCharges':'_dPassifG',
                            'passifHCirculantEcartsDeConversion':'_dPassifH',
                            'passifTotalIICirculantTotal':'_dPassifTotal_II',
                            'passifTresorTresorerieTresoreriePassif':'_dPassifTresor',
                            'passifTresor1TresorerieCreditsEscompte':'_dPassifTresor1',
                            'passifTresor2TresorerieCreditDeTresorerie':'_dPassifTresor2',
                            'passifTresor3TresorerieBanques':'_dPassifTresor3',
                            'passifTresorTresorerieTotalIII':'_dPassifTresor',
                            'passifTotalGeneral':'_dPassifTotalGeneral'
                            },
                            cpcMapping:{
                            'cpcIExploitationProduitsExploitationExercice':'_dCPC_I',
                            'cpcI1ExploitationVentesDeMarchandisesExercice':'_dCPC_I_1',
                            'cpcI2ExploitationVentesBiensEtServicesProduitsExercice':'_dCPC_I_2',
                            'cpcI3ExploitationChiffresAffairesExercice':'_dCPC_I_3',
                            'cpcI4ExploitationVariationDeStockDeProduitsExercice':'_dCPC_I_4',
                            'cpcI5ExploitationImmobilisationsProduitesPourElleMemeExercice':'_dCPC_I_5',
                            'cpcI6ExploitationSubventionDExploitationExercice':'_dCPC_I_6',
                            'cpcI7ExploitationAutresProduitsExploitationExercice':'_dCPC_I_7',
                            'cpcI8ExploitationReprisesExploitationTransfertDeChargesExercice':'_dCPC_I_8',
                            'cpcIIExploitationChargesExploitationExercice':'_dCPC_II',
                            'cpcII1ExploitationAchatsRevendusDeMarchandisesExercice':'_dCPC_II_1',
                            'cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresExercice':'_dCPC_II_2',
                            'cpcII3ExploitationAutresChargesExternesExercice':'_dCPC_II_3',
                            'cpcII4ExploitationImpotsEtTaxesExercice':'_dCPC_II_4',
                            'cpcII5ExploitationChargesPersonnelExercice':'_dCPC_II_5',
                            'cpcII6ExploitationAutresChargesExploitationExercice':'_dCPC_II_6',
                            'cpcII7ExploitationDotationsExploitationExercice':'_dCPC_II_7',
                            'cpcIIIExploitationResultatExploitationExercice':'_dCPC_III',
                            'cpcIVFinancierProduitsFinanciersExercice':'_dCPC_IV',
                            'cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesExercice':'_dCPC_IV_1',
                            'cpcIV2FinancierGainsDeChangeExercice':'_dCPC_IV_2',
                            'cpcIV3FinancierInteretsEtAutresProduitsFinanciersExercice':'_dCPC_IV_3',
                            'cpcIV4FinancierReprisesFinancieresTransfertDeChargesExercice':'_dCPC_IV_4',
                            'cpcVFinancierChargesFinancieresExercice':'_dCPC_V',
                            'cpcV1FinancierChargesInteretExercice':'_dCPC_V_1',
                            'cpcV2FinancierPertesDeChangesExercice':'_dCPC_V_2',
                            'cpcV3FinancierAutresChargesFinancieresExercice':'_dCPC_V_3',
                            'cpcV4FinancierDotationFinancieresExercice':'_dCPC_V_4',
                            'cpcVIFinancierResultatFinancierExercice':'_dCPC_VI',
                            'cpcVIICourantResultatCourantExercice':'_dCPC_VII',
                            'cpcVIIINonCourantProduitsNonCourantsExercice':'_dCPC_VIII',
                            'cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsExercice':'_dCPC_VIII_1',
                            'cpcVIII2NonCourantSubventionsDEquilibreExercice':'_dCPC_VIII_2',
                            'cpcVIII3NonCourantReprisesSurSubventionsDInvestissementExercice':'_dCPC_VIII_3',
                            'cpcVIII4NonCourantAutresProduitsNonCourantsExercice':'_dCPC_VIII_4',
                            'cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesExercice':'_dCPC_VIII_5',
                            'cpcIXNonCourantChargesNonCourantesExercice':'_dCPC_IX',
                            'cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesExercice':'_dCPC_IX_1',
                            'cpcIX2NonCourantSubventionsAccordeesExercice':'_dCPC_IX_2',
                            'cpcIX3NonCourantAutresChargesNonCourantesExercice':'_dCPC_IX_3',
                            'cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionExercice':'_dCPC_IX_4',
                            'cpcXResultatNonCourantExercice':'_dCPC_X',
                            'cpcXIResultatAvantImpotsExercice':'_dCPC_XI',
                            'cpcXIIImpotsSurLesResultatsExercice':'_dCPC_XII',
                            'cpcXIIIResultatNetExercice':'_dCPC_XIII',

                            'cpcIExploitationProduitsExploitation':'_dCPC_I',
                            'cpcI1ExploitationVentesDeMarchandises':'_dCPC_I_1',
                            'cpcI2ExploitationVentesBiensEtServicesProduits':'_dCPC_I_2',
                            'cpcI3ExploitationChiffresAffaires':'_dCPC_I_3',
                            'cpcI4ExploitationVariationDeStockDeProduits':'_dCPC_I_4',
                            'cpcI5ExploitationImmobilisationsProduitesPourElleMeme':'_dCPC_I_5',
                            'cpcI6ExploitationSubventionDExploitation':'_dCPC_I_6',
                            'cpcI7ExploitationAutresProduitsExploitation':'_dCPC_I_7',
                            'cpcI8ExploitationReprisesExploitationTransfertDeCharges':'_dCPC_I_8',
                            'cpcIIExploitationChargesExploitation':'_dCPC_II',
                            'cpcII1ExploitationAchatsRevendusDeMarchandises':'_dCPC_II_1',
                            'cpcII2ExploitationAchatConsommesDeMatieresEtDeFournitures':'_dCPC_II_2',
                            'cpcII3ExploitationAutresChargesExternes':'_dCPC_II_3',
                            'cpcII4ExploitationImpotsEtTaxes':'_dCPC_II_4',
                            'cpcII5ExploitationChargesPersonnel':'_dCPC_II_5',
                            'cpcII6ExploitationAutresChargesExploitation':'_dCPC_II_6',
                            'cpcII7ExploitationDotationsExploitation':'_dCPC_II_7',
                            'cpcIIIExploitationResultatExploitation':'_dCPC_III',
                            'cpcIVFinancierProduitsFinanciers':'_dCPC_IV',
                            'cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilises':'_dCPC_IV_1',
                            'cpcIV2FinancierGainsDeChange':'_dCPC_IV_2',
                            'cpcIV3FinancierInteretsEtAutresProduitsFinanciers':'_dCPC_IV_3',
                            'cpcIV4FinancierReprisesFinancieresTransfertDeCharges':'_dCPC_IV_4',
                            'cpcVFinancierChargesFinancieres':'_dCPC_V',
                            'cpcV1FinancierChargesInteret':'_dCPC_V_1',
                            'cpcV2FinancierPertesDeChanges':'_dCPC_V_2',
                            'cpcV3FinancierAutresChargesFinancieres':'_dCPC_V_3',
                            'cpcV4FinancierDotationFinancieres':'_dCPC_V_4',
                            'cpcVIFinancierResultatFinancier':'_dCPC_VI',
                            'cpcVIICourantResultatCourant':'_dCPC_VII',
                            'cpcVIIINonCourantProduitsNonCourants':'_dCPC_VIII',
                            'cpcVIII1NonCourantProduitsDesCessionsDImmobilisations':'_dCPC_VIII_1',
                            'cpcVIII2NonCourantSubventionsDEquilibre':'_dCPC_VIII_2',
                            'cpcVIII3NonCourantReprisesSurSubventionsDInvestissement':'_dCPC_VIII_3',
                            'cpcVIII4NonCourantAutresProduitsNonCourants':'_dCPC_VIII_4',
                            'cpcVIII5NonCourantReprisesNonCourantesTransfertsDeCharges':'_dCPC_VIII_5',
                            'cpcIXNonCourantChargesNonCourantes':'_dCPC_IX',
                            'cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedees':'_dCPC_IX_1',
                            'cpcIX2NonCourantSubventionsAccordees':'_dCPC_IX_2',
                            'cpcIX3NonCourantAutresChargesNonCourantes':'_dCPC_IX_3',
                            'cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvision':'_dCPC_IX_4',
                            'cpcXResultatNonCourant':'_dCPC_X',
                            'cpcXIResultatAvantImpots':'_dCPC_XI',
                            'cpcXIIImpotsSurLesResultats':'_dCPC_XII',
                            'cpcXIIIResultatNet':'_dCPC_XIII',
                            },
        }
    }, //end data()
    //------------------------------ created ---------------------------
    created()
    {
    },
    //------------------------------ Mounted ---------------------------
    async mounted() {
        this.jsonResult = null;
        this.selectedBilanItemLocal = {}
        this.selectedBilanItemLocal = this.selectedBilanItemProp
        this.showBilanDialogFlagLocal = this.showBilanDialogFlag
        this.inforiskLocal = this.inforisk
        this.blockedDialog = true;

        // debugger;
        if(this.selectedBilanItemLocal.id==0)
        {
            this.selectedBilanItemLocal.dateBilan = ''
        }
        

        var ref = this;
        if(ref.showBilanDialogFlag && !this.isMounted)
        {         
            ref.years = [];
            crudService.list("anneesbilandisponibles/"+this.societe.id)
                .then(function(response){
                    if(response.data)
                    {
                        ref.blockedDialog = false;
                        
                        ref.years = response.data.map(function(d){
                            d['value'] = d.annee;
                            if(d['disabled'])
                            {
                                var strDisabledInforisk = d['inforisk']?'inforisk':'manuel'
                                d['annee'] += ' ('+strDisabledInforisk+')'
                            }
                            return d;
                        })

                        /*if(!ref.selectedBilanItemLocal.id)
                        {
                            ref.selectedBilanItemLocal.dateBilan = ref.years[0].annee
                        }*/
                    }
                })
                
            if(this.selectedBilanItemLocal.id > 0 && this.selectedBilanItemLocal.dateBilan)
            {
                var anneeN1 = parseInt(this.selectedBilanItemLocal.dateBilan)-1
                ref.blockedDialog = true;
                crudService.list("bilanfinancierbyannee/"+anneeN1+"/"+this.societe.id)
                .then(function(response){
                    if(response.data)
                    {
                        ref.blockedDialog = false;
                        
                        ref.selectedBilanItemLocalAnneeN1 = response.data;
                        ref.foundBilanN1 = true
                        ref.toastSuccess("Bilan N-1 chargé (" + anneeN1 +') depuis '+ (ref.selectedBilanItemLocalAnneeN1.inforisk?"inforisk":"manuel"));
                    }
                    else
                    {
                        ref.foundBilanN1 = false
                    }
                })
            }

        }
        // ;

        // let props = {...this.selectedBilanItemProp}
        // for(var o in props)
        // {
        //     if(o!='dateBilan')
        //     {
        //         this.selectedBilanItemLocal[o[0].toUpperCase() + o.substring(1)] = this.selectedBilanItemProp[o]
        //     }
            
        // }
        // ;
        var ref = this;
        var response = await CRUDService.list('BilanListeChamps')
        if(response.data)
        { 
            ref.refMappingBilanListeChamps = response.data
        }
        else
        {
            alert(response.data)
        }

        this.isMounted = true;
        
    }, //end mounted
    computed:{
        titleHeader()
        {
            //selectedBilanItemLocal.id)?('Edition du bilan '+selectedBilanItemLocal.dateBilan+' de '+societe.raisonSociale):'Création d\'un nouveau bilan: '+societe.raisonSociale + ' - ' + selectedBilanItemLocal.dateBilan
            if(this.selectedBilanItemLocal.id){
                if(this.selectedBilanItemLocal.inforisk == 1)
                {
                    return ('Consultation du bilan '+this.selectedBilanItemLocal.dateBilan+' de '+this.societe.raisonSociale)
                }
                return ('Edition du bilan '+this.selectedBilanItemLocal.dateBilan+' de '+this.societe.raisonSociale)
            }
            else{
                return 'Création d\'un nouveau bilan: '+this.societe.raisonSociale + ' - ' + this.selectedBilanItemLocal.dateBilan
            }
        },
        /// disabled rules for actif/passif/cpc
        bilanFieldsEnabled()
        {
            return !this.inforiskLocal && !this.selectedBilanItemLocal.inforisk && this.currentUser.jsonUserRights.gestionBilan;
        },
        bilanFieldsN1Enabled()
        {
            // en mode creation
            if(!this.selectedBilanItemLocalAnneeN1.id && this.bilanFieldsEnabled) {
                return true;
            }
        
            return this.selectedBilanItemLocalAnneeN1.estAnneePrecedente && !this.selectedBilanItemLocalAnneeN1.inforisk;
        },
        // BilanNHasValues
        bilanNHasValues()
        {
            return this.selectedBilanItemLocal.actifTotalGeneral != 0 || this.selectedBilanItemLocal.passifTotalGeneral != 0 || this.selectedBilanItemLocal.cpcXVIResultatNET != 0;
        },
        // BilanN1HasValues
        bilanN1HasValues()
        {
            return this.selectedBilanItemLocalAnneeN1.actifTotalGeneral != 0 || this.selectedBilanItemLocalAnneeN1.passifTotalGeneral != 0 || this.selectedBilanItemLocalAnneeN1.cpcXVIResultatNET != 0;
        },
        /// computed for actif
        actifA1ImmobiliseFraisPreliminaires(){
            return this.selectedBilanItemLocal.actifA1ImmobiliseFraisPreliminaires = (this.selectedBilanItemLocal.actifA1ImmobiliseFraisPreliminairesBrut??0) - (this.selectedBilanItemLocal.actifA1ImmobiliseFraisPreliminairesAmrtProv??0);
        },
        actifA2ImmobiliseChargesArepartirSurplusieursExercices(){
            return this.selectedBilanItemLocal.actifA2ImmobiliseChargesArepartirSurplusieursExercices = (this.selectedBilanItemLocal.actifA2ImmobiliseChargesArepartirSurplusieursExercicesBrut??0) - (this.selectedBilanItemLocal.actifA2ImmobiliseChargesArepartirSurplusieursExercicesAmrtProv??0);
        },
        actifA3ImmobilisePrimesDeRemboursementDesObligations(){
            return this.selectedBilanItemLocal.actifA3ImmobilisePrimesDeRemboursementDesObligations = (this.selectedBilanItemLocal.actifA3ImmobilisePrimesDeRemboursementDesObligationsBrut??0) - (this.selectedBilanItemLocal.actifA3ImmobilisePrimesDeRemboursementDesObligationsAmrtProv??0);
        },
        actifBImmobiliseImmobilisationsIncorporelles(){
            return this.selectedBilanItemLocal.actifBImmobiliseImmobilisationsIncorporelles = (this.selectedBilanItemLocal.actifBImmobiliseImmobilisationsIncorporellesBrut??0) - (this.selectedBilanItemLocal.actifBImmobiliseImmobilisationsIncorporellesAmrtProv??0);
        },
        actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppement(){
            return this.selectedBilanItemLocal.actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppement = (this.selectedBilanItemLocal.actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementBrut??0) - (this.selectedBilanItemLocal.actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementAmrtProv??0);
        },
        actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilaires(){
            return this.selectedBilanItemLocal.actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilaires = (this.selectedBilanItemLocal.actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesBrut??0) - (this.selectedBilanItemLocal.actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesAmrtProv??0);
        },
        actifAImmobiliseImmobilisationEnNonValeur(){
            return this.selectedBilanItemLocal.actifAImmobiliseImmobilisationEnNonValeur = (this.selectedBilanItemLocal.actifAImmobiliseImmobilisationEnNonValeurBrut??0) - (this.selectedBilanItemLocal.actifAImmobiliseImmobilisationEnNonValeurAmrtProv??0);
        },
        actifB3ImmobiliseFondsCommercial(){
            return this.selectedBilanItemLocal.actifB3ImmobiliseFondsCommercial = (this.selectedBilanItemLocal.actifB3ImmobiliseFondsCommercialBrut??0) - (this.selectedBilanItemLocal.actifB3ImmobiliseFondsCommercialAmrtProv??0);
        },
        actifB4ImmobiliseAutresImmobilisationsIncorporelles(){
            return this.selectedBilanItemLocal.actifB4ImmobiliseAutresImmobilisationsIncorporelles = (this.selectedBilanItemLocal.actifB4ImmobiliseAutresImmobilisationsIncorporellesBrut??0) - (this.selectedBilanItemLocal.actifB4ImmobiliseAutresImmobilisationsIncorporellesAmrtProv??0);
        },
        actifCImmobiliseImmobilisationsCorporelles(){
            return this.selectedBilanItemLocal.actifCImmobiliseImmobilisationsCorporelles = (this.selectedBilanItemLocal.actifCImmobiliseImmobilisationsCorporellesBrut??0) - (this.selectedBilanItemLocal.actifCImmobiliseImmobilisationsCorporellesAmrtProv??0);
        },
        actifC1ImmobiliseTerrains(){
            return this.selectedBilanItemLocal.actifC1ImmobiliseTerrains = (this.selectedBilanItemLocal.actifC1ImmobiliseTerrainsBrut??0) - (this.selectedBilanItemLocal.actifC1ImmobiliseTerrainsAmrtProv??0);
        },
        actifC2ImmobiliseConstructions(){
            return this.selectedBilanItemLocal.actifC2ImmobiliseConstructions = (this.selectedBilanItemLocal.actifC2ImmobiliseConstructionsBrut??0) - (this.selectedBilanItemLocal.actifC2ImmobiliseConstructionsAmrtProv??0);
        },
        actifC3ImmobiliseInstallationsTechniqueMaterielOutillage(){
            return this.selectedBilanItemLocal.actifC3ImmobiliseInstallationsTechniqueMaterielOutillage = (this.selectedBilanItemLocal.actifC3ImmobiliseInstallationsTechniqueMaterielOutillageBrut??0) - (this.selectedBilanItemLocal.actifC3ImmobiliseInstallationsTechniqueMaterielOutillageAmrtProv??0);
        },
        actifC4ImmobiliseMaterielDeTransport(){
            return this.selectedBilanItemLocal.actifC4ImmobiliseMaterielDeTransport = (this.selectedBilanItemLocal.actifC4ImmobiliseMaterielDeTransportBrut??0) - (this.selectedBilanItemLocal.actifC4ImmobiliseMaterielDeTransportAmrtProv??0);
        },
        actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDivers(){
            return this.selectedBilanItemLocal.actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDivers = (this.selectedBilanItemLocal.actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversBrut??0) - (this.selectedBilanItemLocal.actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversAmrtProv??0);
        },
        actifC6ImmobiliseAutresImmobilisationsCorporelles(){
            return this.selectedBilanItemLocal.actifC6ImmobiliseAutresImmobilisationsCorporelles = (this.selectedBilanItemLocal.actifC6ImmobiliseAutresImmobilisationsCorporellesBrut??0) - (this.selectedBilanItemLocal.actifC6ImmobiliseAutresImmobilisationsCorporellesAmrtProv??0);
        },
        actifC7ImmobiliseImmobilisationsCorporellesEnCours(){
            return this.selectedBilanItemLocal.actifC7ImmobiliseImmobilisationsCorporellesEnCours = (this.selectedBilanItemLocal.actifC7ImmobiliseImmobilisationsCorporellesEnCoursBrut??0) - (this.selectedBilanItemLocal.actifC7ImmobiliseImmobilisationsCorporellesEnCoursAmrtProv??0);
        },
        actifDImmobiliseImmobilisationsFinancieres(){
            return this.selectedBilanItemLocal.actifDImmobiliseImmobilisationsFinancieres = (this.selectedBilanItemLocal.actifDImmobiliseImmobilisationsFinancieresBrut??0) - (this.selectedBilanItemLocal.actifDImmobiliseImmobilisationsFinancieresAmrtProv??0);
        },
        actifD1ImmobilisePretsImmobilises(){
            return this.selectedBilanItemLocal.actifD1ImmobilisePretsImmobilises = (this.selectedBilanItemLocal.actifD1ImmobilisePretsImmobilisesBrut??0) - (this.selectedBilanItemLocal.actifD1ImmobilisePretsImmobilisesAmrtProv??0);
        },
        actifD2ImmobiliseAutresCreancesFinancieres(){
            return this.selectedBilanItemLocal.actifD2ImmobiliseAutresCreancesFinancieres = (this.selectedBilanItemLocal.actifD2ImmobiliseAutresCreancesFinancieresBrut??0) - (this.selectedBilanItemLocal.actifD2ImmobiliseAutresCreancesFinancieresAmrtProv??0);
        },
        actifD3ImmobiliseTitresDeParticipation(){
            return this.selectedBilanItemLocal.actifD3ImmobiliseTitresDeParticipation = (this.selectedBilanItemLocal.actifD3ImmobiliseTitresDeParticipationBrut??0) - (this.selectedBilanItemLocal.actifD3ImmobiliseTitresDeParticipationAmrtProv??0);
        },
        actifD4ImmobiliseAutresTitresImmobilises(){
            return this.selectedBilanItemLocal.actifD4ImmobiliseAutresTitresImmobilises = (this.selectedBilanItemLocal.actifD4ImmobiliseAutresTitresImmobilisesBrut??0) - (this.selectedBilanItemLocal.actifD4ImmobiliseAutresTitresImmobilisesAmrtProv??0);
        },
        actifEImmobiliseEcartsDeConversion(){
            return this.selectedBilanItemLocal.actifEImmobiliseEcartsDeConversion = (this.selectedBilanItemLocal.actifEImmobiliseEcartsDeConversionBrut??0) - (this.selectedBilanItemLocal.actifEImmobiliseEcartsDeConversionAmrtProv??0);
        },
        actifE1ImmobiliseDiminutionDesCreancesImmobilisees(){
            return this.selectedBilanItemLocal.actifE1ImmobiliseDiminutionDesCreancesImmobilisees = (this.selectedBilanItemLocal.actifE1ImmobiliseDiminutionDesCreancesImmobiliseesBrut??0) - (this.selectedBilanItemLocal.actifE1ImmobiliseDiminutionDesCreancesImmobiliseesAmrtProv??0);
        },
        actifE2ImmobiliseAugmentationDesDettesDeFinancement(){
            return this.selectedBilanItemLocal.actifE2ImmobiliseAugmentationDesDettesDeFinancement = (this.selectedBilanItemLocal.actifE2ImmobiliseAugmentationDesDettesDeFinancementBrut??0) - (this.selectedBilanItemLocal.actifE2ImmobiliseAugmentationDesDettesDeFinancementAmrtProv??0);
        },
        actifTotalIImmobiliseTotal(){
            return this.selectedBilanItemLocal.actifTotalIImmobiliseTotal = (this.selectedBilanItemLocal.actifTotalIImmobiliseTotalBrut??0) - (this.selectedBilanItemLocal.actifTotalIImmobiliseTotalAmrtProv??0);
        },
        actifFCirculantStocks(){
            return this.selectedBilanItemLocal.actifFCirculantStocks = (this.selectedBilanItemLocal.actifFCirculantStocksBrut??0) - (this.selectedBilanItemLocal.actifFCirculantStocksAmrtProv??0);
        },
        actifF1CirculantMarchandises(){
            return this.selectedBilanItemLocal.actifF1CirculantMarchandises = (this.selectedBilanItemLocal.actifF1CirculantMarchandisesBrut??0) - (this.selectedBilanItemLocal.actifF1CirculantMarchandisesAmrtProv??0);
        },
        actifF2CirculantMatieresEtFournituresConsommables(){
            return this.selectedBilanItemLocal.actifF2CirculantMatieresEtFournituresConsommables = (this.selectedBilanItemLocal.actifF2CirculantMatieresEtFournituresConsommablesBrut??0) - (this.selectedBilanItemLocal.actifF2CirculantMatieresEtFournituresConsommablesAmrtProv??0);
        },
        actifF3CirculantProduitsEnCours(){
            return this.selectedBilanItemLocal.actifF3CirculantProduitsEnCours = (this.selectedBilanItemLocal.actifF3CirculantProduitsEnCoursBrut??0) - (this.selectedBilanItemLocal.actifF3CirculantProduitsEnCoursAmrtProv??0);
        },
        actifF4CirculantProduitsIntermediairesEtProduitsResiduels(){
            return this.selectedBilanItemLocal.actifF4CirculantProduitsIntermediairesEtProduitsResiduels = (this.selectedBilanItemLocal.actifF4CirculantProduitsIntermediairesEtProduitsResiduelsBrut??0) - (this.selectedBilanItemLocal.actifF4CirculantProduitsIntermediairesEtProduitsResiduelsAmrtProv??0);
        },
        actifF5CirculantProduitsFinis(){
            return this.selectedBilanItemLocal.actifF5CirculantProduitsFinis = (this.selectedBilanItemLocal.actifF5CirculantProduitsFinisBrut??0) - (this.selectedBilanItemLocal.actifF5CirculantProduitsFinisAmrtProv??0);
        },
        actifGCirculantCreancesCirculant(){
            return this.selectedBilanItemLocal.actifGCirculantCreancesCirculant = (this.selectedBilanItemLocal.actifGCirculantCreancesCirculantBrut??0) - (this.selectedBilanItemLocal.actifGCirculantCreancesCirculantAmrtProv??0);
        },
        actifG1CirculantFournisseursdebiteursAvancesAcomptes(){
            return this.selectedBilanItemLocal.actifG1CirculantFournisseursdebiteursAvancesAcomptes = (this.selectedBilanItemLocal.actifG1CirculantFournisseursdebiteursAvancesAcomptesBrut??0) - (this.selectedBilanItemLocal.actifG1CirculantFournisseursdebiteursAvancesAcomptesAmrtProv??0);
        },
        actifG2CirculantClientsEtComptesRattaches(){
            return this.selectedBilanItemLocal.actifG2CirculantClientsEtComptesRattaches = (this.selectedBilanItemLocal.actifG2CirculantClientsEtComptesRattachesBrut??0) - (this.selectedBilanItemLocal.actifG2CirculantClientsEtComptesRattachesAmrtProv??0);
        },
        actifG3CirculantPersonnel(){
            return this.selectedBilanItemLocal.actifG3CirculantPersonnel = (this.selectedBilanItemLocal.actifG3CirculantPersonnelBrut??0) - (this.selectedBilanItemLocal.actifG3CirculantPersonnelAmrtProv??0);
        },
        actifG4CirculantEtat(){
            return this.selectedBilanItemLocal.actifG4CirculantEtat = (this.selectedBilanItemLocal.actifG4CirculantEtatBrut??0) - (this.selectedBilanItemLocal.actifG4CirculantEtatAmrtProv??0);
        },
        actifG5CirculantCompteAssocies(){
            return this.selectedBilanItemLocal.actifG5CirculantCompteAssocies = (this.selectedBilanItemLocal.actifG5CirculantCompteAssociesBrut??0) - (this.selectedBilanItemLocal.actifG5CirculantCompteAssociesAmrtProv??0);
        },
        actifG6CirculantAutresDebiteurs(){
            return this.selectedBilanItemLocal.actifG6CirculantAutresDebiteurs = (this.selectedBilanItemLocal.actifG6CirculantAutresDebiteursBrut??0) - (this.selectedBilanItemLocal.actifG6CirculantAutresDebiteursAmrtProv??0);
        },
        actifG7CirculantCompteRegularisation(){
            return this.selectedBilanItemLocal.actifG7CirculantCompteRegularisation = (this.selectedBilanItemLocal.actifG7CirculantCompteRegularisationBrut??0) - (this.selectedBilanItemLocal.actifG7CirculantCompteRegularisationAmrtProv??0);
        },
        actifHCirculantTitresEtValeursDePlacement(){
            return this.selectedBilanItemLocal.actifHCirculantTitresEtValeursDePlacement = (this.selectedBilanItemLocal.actifHCirculantTitresEtValeursDePlacementBrut??0) - (this.selectedBilanItemLocal.actifHCirculantTitresEtValeursDePlacementAmrtProv??0);
        },
        actifICirculantEcartDeConversion(){
            return this.selectedBilanItemLocal.actifICirculantEcartDeConversion = (this.selectedBilanItemLocal.actifICirculantEcartDeConversionBrut??0) - (this.selectedBilanItemLocal.actifICirculantEcartDeConversionAmrtProv??0);
        },
        actifTotalIICirculantTotal(){
            return this.selectedBilanItemLocal.actifTotalIICirculantTotal = (this.selectedBilanItemLocal.actifTotalIICirculantTotalBrut??0) - (this.selectedBilanItemLocal.actifTotalIICirculantTotalAmrtProv??0);
        },
        actifTresor1TresorerieChequesEtValeursAEncaisser(){
            return this.selectedBilanItemLocal.actifTresor1TresorerieChequesEtValeursAEncaisser = (this.selectedBilanItemLocal.actifTresor1TresorerieChequesEtValeursAEncaisserBrut??0) - (this.selectedBilanItemLocal.actifTresor1TresorerieChequesEtValeursAEncaisserAmrtProv??0);
        },
        actifTresor2TresorerieBanquesTGEtCP(){
            return this.selectedBilanItemLocal.actifTresor2TresorerieBanquesTGEtCP = (this.selectedBilanItemLocal.actifTresor2TresorerieBanquesTGEtCPBrut??0) - (this.selectedBilanItemLocal.actifTresor2TresorerieBanquesTGEtCPAmrtProv??0);
        },
        actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifs(){
            return this.selectedBilanItemLocal.actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifs = (this.selectedBilanItemLocal.actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsBrut??0) - (this.selectedBilanItemLocal.actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsAmrtProv??0);
        },
        actifTresorTresorerieTotal(){
            return this.selectedBilanItemLocal.actifTresorTresorerieTotal = (this.selectedBilanItemLocal.actifTresorTresorerieTotalBrut??0) - (this.selectedBilanItemLocal.actifTresorTresorerieTotalAmrtProv??0);
        },
        actifTotalGeneral(){
            return this.selectedBilanItemLocal.actifTotalGeneral = (this.selectedBilanItemLocal.actifTotalGeneralBrut??0) - (this.selectedBilanItemLocal.actifTotalGeneralAmrtProv??0);
        },


        /// Brut

        actifAImmobiliseImmobilisationEnNonValeurBrut()
        {
            return this.selectedBilanItemLocal.actifAImmobiliseImmobilisationEnNonValeurBrut = (this.selectedBilanItemLocal.actifA1ImmobiliseFraisPreliminairesBrut??0) + (this.selectedBilanItemLocal.actifA2ImmobiliseChargesArepartirSurplusieursExercicesBrut??0) + (this.selectedBilanItemLocal.actifA3ImmobilisePrimesDeRemboursementDesObligationsBrut??0)
        },
        
        actifBImmobiliseImmobilisationsIncorporellesBrut(){
            return this.selectedBilanItemLocal.actifBImmobiliseImmobilisationsIncorporellesBrut = (this.selectedBilanItemLocal.actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementBrut??0) + (this.selectedBilanItemLocal.actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesBrut??0) + (this.selectedBilanItemLocal.actifB3ImmobiliseFondsCommercialBrut??0) + (this.selectedBilanItemLocal.actifB4ImmobiliseAutresImmobilisationsIncorporellesBrut??0)
        },
        actifCImmobiliseImmobilisationsCorporellesBrut(){
            return this.selectedBilanItemLocal.actifCImmobiliseImmobilisationsCorporellesBrut = (this.selectedBilanItemLocal.actifC1ImmobiliseTerrainsBrut??0) + (this.selectedBilanItemLocal.actifC2ImmobiliseConstructionsBrut??0) + (this.selectedBilanItemLocal.actifC3ImmobiliseInstallationsTechniqueMaterielOutillageBrut??0) + (this.selectedBilanItemLocal.actifC4ImmobiliseMaterielDeTransportBrut??0) + (this.selectedBilanItemLocal.actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversBrut??0) + (this.selectedBilanItemLocal.actifC6ImmobiliseAutresImmobilisationsCorporellesBrut??0) + (this.selectedBilanItemLocal.actifC7ImmobiliseImmobilisationsCorporellesEnCoursBrut??0)
        },
        actifDImmobiliseImmobilisationsFinancieresBrut(){
            return this.selectedBilanItemLocal.actifDImmobiliseImmobilisationsFinancieresBrut = (this.selectedBilanItemLocal.actifD1ImmobilisePretsImmobilisesBrut??0) + (this.selectedBilanItemLocal.actifD2ImmobiliseAutresCreancesFinancieresBrut??0) + (this.selectedBilanItemLocal.actifD3ImmobiliseTitresDeParticipationBrut??0) + (this.selectedBilanItemLocal.actifD4ImmobiliseAutresTitresImmobilisesBrut??0)
        },
        actifEImmobiliseEcartsDeConversionBrut(){
            return this.selectedBilanItemLocal.actifEImmobiliseEcartsDeConversionBrut = (this.selectedBilanItemLocal.actifE1ImmobiliseDiminutionDesCreancesImmobiliseesBrut??0) + (this.selectedBilanItemLocal.actifE2ImmobiliseAugmentationDesDettesDeFinancementBrut??0)
        },
        actifTotalIImmobiliseTotalBrut(){
            return this.selectedBilanItemLocal.actifTotalIImmobiliseTotalBrut = (this.selectedBilanItemLocal.actifAImmobiliseImmobilisationEnNonValeurBrut??0) + (this.selectedBilanItemLocal.actifBImmobiliseImmobilisationsIncorporellesBrut??0) + (this.selectedBilanItemLocal.actifCImmobiliseImmobilisationsCorporellesBrut??0) + (this.selectedBilanItemLocal.actifDImmobiliseImmobilisationsFinancieresBrut??0) + (this.selectedBilanItemLocal.actifEImmobiliseEcartsDeConversionBrut??0)
        },
        actifFCirculantStocksBrut(){
            return this.selectedBilanItemLocal.actifFCirculantStocksBrut = (this.selectedBilanItemLocal.actifF1CirculantMarchandisesBrut??0) + (this.selectedBilanItemLocal.actifF2CirculantMatieresEtFournituresConsommablesBrut??0) + (this.selectedBilanItemLocal.actifF3CirculantProduitsEnCoursBrut??0) + (this.selectedBilanItemLocal.actifF4CirculantProduitsIntermediairesEtProduitsResiduelsBrut??0) + (this.selectedBilanItemLocal.actifF5CirculantProduitsFinisBrut??0)
        },
        actifGCirculantCreancesCirculantBrut(){
            return this.selectedBilanItemLocal.actifGCirculantCreancesCirculantBrut = (this.selectedBilanItemLocal.actifG1CirculantFournisseursdebiteursAvancesAcomptesBrut??0) + (this.selectedBilanItemLocal.actifG2CirculantClientsEtComptesRattachesBrut??0) + (this.selectedBilanItemLocal.actifG3CirculantPersonnelBrut??0) + (this.selectedBilanItemLocal.actifG4CirculantEtatBrut??0) + (this.selectedBilanItemLocal.actifG5CirculantCompteAssociesBrut??0) + (this.selectedBilanItemLocal.actifG6CirculantAutresDebiteursBrut??0) + (this.selectedBilanItemLocal.actifG7CirculantCompteRegularisationBrut??0)
        },
        actifTotalIICirculantTotalBrut(){
            return this.selectedBilanItemLocal.actifTotalIICirculantTotalBrut = (this.selectedBilanItemLocal.actifFCirculantStocksBrut??0) + (this.selectedBilanItemLocal.actifGCirculantCreancesCirculantBrut??0) + (this.selectedBilanItemLocal.actifHCirculantTitresEtValeursDePlacementBrut??0) + (this.selectedBilanItemLocal.actifICirculantEcartDeConversionBrut??0)
        },
        actifTresorTresorerieTotalBrut(){
            return this.selectedBilanItemLocal.actifTresorTresorerieTotalBrut = (this.selectedBilanItemLocal.actifTresor1TresorerieChequesEtValeursAEncaisserBrut??0) + (this.selectedBilanItemLocal.actifTresor2TresorerieBanquesTGEtCPBrut??0) + (this.selectedBilanItemLocal.actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsBrut??0)
        },
        
        actifTotalGeneralBrut(){
            return this.selectedBilanItemLocal.actifTotalGeneralBrut = (this.selectedBilanItemLocal.actifTotalIImmobiliseTotalBrut??0) + (this.selectedBilanItemLocal.actifTotalIICirculantTotalBrut??0) + (this.selectedBilanItemLocal.actifTresorTresorerieTotalBrut??0)
        },

        actifAImmobiliseImmobilisationEnNonValeurAmrtProv()
        {
            return this.selectedBilanItemLocal.actifAImmobiliseImmobilisationEnNonValeurAmrtProv = (this.selectedBilanItemLocal.actifA1ImmobiliseFraisPreliminairesAmrtProv??0) + (this.selectedBilanItemLocal.actifA2ImmobiliseChargesArepartirSurplusieursExercicesAmrtProv??0) + (this.selectedBilanItemLocal.actifA3ImmobilisePrimesDeRemboursementDesObligationsAmrtProv??0)
        },
        
        actifBImmobiliseImmobilisationsIncorporellesAmrtProv(){
            return this.selectedBilanItemLocal.actifBImmobiliseImmobilisationsIncorporellesAmrtProv = (this.selectedBilanItemLocal.actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppementAmrtProv??0) + (this.selectedBilanItemLocal.actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilairesAmrtProv??0) + (this.selectedBilanItemLocal.actifB3ImmobiliseFondsCommercialAmrtProv??0) + (this.selectedBilanItemLocal.actifB4ImmobiliseAutresImmobilisationsIncorporellesAmrtProv??0)
        },
        actifCImmobiliseImmobilisationsCorporellesAmrtProv(){
            return this.selectedBilanItemLocal.actifCImmobiliseImmobilisationsCorporellesAmrtProv = (this.selectedBilanItemLocal.actifC1ImmobiliseTerrainsAmrtProv??0) + (this.selectedBilanItemLocal.actifC2ImmobiliseConstructionsAmrtProv??0) + (this.selectedBilanItemLocal.actifC3ImmobiliseInstallationsTechniqueMaterielOutillageAmrtProv??0) + (this.selectedBilanItemLocal.actifC4ImmobiliseMaterielDeTransportAmrtProv??0) + (this.selectedBilanItemLocal.actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDiversAmrtProv??0) + (this.selectedBilanItemLocal.actifC6ImmobiliseAutresImmobilisationsCorporellesAmrtProv??0) + (this.selectedBilanItemLocal.actifC7ImmobiliseImmobilisationsCorporellesEnCoursAmrtProv??0)
        },
        actifDImmobiliseImmobilisationsFinancieresAmrtProv(){
            return this.selectedBilanItemLocal.actifDImmobiliseImmobilisationsFinancieresAmrtProv = (this.selectedBilanItemLocal.actifD1ImmobilisePretsImmobilisesAmrtProv??0) + (this.selectedBilanItemLocal.actifD2ImmobiliseAutresCreancesFinancieresAmrtProv??0) + (this.selectedBilanItemLocal.actifD3ImmobiliseTitresDeParticipationAmrtProv??0) + (this.selectedBilanItemLocal.actifD4ImmobiliseAutresTitresImmobilisesAmrtProv??0)
        },
        actifEImmobiliseEcartsDeConversionAmrtProv(){
            return this.selectedBilanItemLocal.actifEImmobiliseEcartsDeConversionAmrtProv = (this.selectedBilanItemLocal.actifE1ImmobiliseDiminutionDesCreancesImmobiliseesAmrtProv??0) + (this.selectedBilanItemLocal.actifE2ImmobiliseAugmentationDesDettesDeFinancementAmrtProv??0)
        },
        actifTotalIImmobiliseTotalAmrtProv(){
            return this.selectedBilanItemLocal.actifTotalIImmobiliseTotalAmrtProv = (this.selectedBilanItemLocal.actifAImmobiliseImmobilisationEnNonValeurAmrtProv??0) + (this.selectedBilanItemLocal.actifBImmobiliseImmobilisationsIncorporellesAmrtProv??0) + (this.selectedBilanItemLocal.actifCImmobiliseImmobilisationsCorporellesAmrtProv??0) + (this.selectedBilanItemLocal.actifDImmobiliseImmobilisationsFinancieresAmrtProv??0) + (this.selectedBilanItemLocal.actifEImmobiliseEcartsDeConversionAmrtProv??0)
        },
        actifFCirculantStocksAmrtProv(){
            return this.selectedBilanItemLocal.actifFCirculantStocksAmrtProv = (this.selectedBilanItemLocal.actifF1CirculantMarchandisesAmrtProv??0) + (this.selectedBilanItemLocal.actifF2CirculantMatieresEtFournituresConsommablesAmrtProv??0) + (this.selectedBilanItemLocal.actifF3CirculantProduitsEnCoursAmrtProv??0) + (this.selectedBilanItemLocal.actifF4CirculantProduitsIntermediairesEtProduitsResiduelsAmrtProv??0) + (this.selectedBilanItemLocal.actifF5CirculantProduitsFinisAmrtProv??0)
        },
        actifGCirculantCreancesCirculantAmrtProv(){
            return this.selectedBilanItemLocal.actifGCirculantCreancesCirculantAmrtProv = (this.selectedBilanItemLocal.actifG1CirculantFournisseursdebiteursAvancesAcomptesAmrtProv??0) + (this.selectedBilanItemLocal.actifG2CirculantClientsEtComptesRattachesAmrtProv??0) + (this.selectedBilanItemLocal.actifG3CirculantPersonnelAmrtProv??0) + (this.selectedBilanItemLocal.actifG4CirculantEtatAmrtProv??0) + (this.selectedBilanItemLocal.actifG5CirculantCompteAssociesAmrtProv??0) + (this.selectedBilanItemLocal.actifG6CirculantAutresDebiteursAmrtProv??0) + (this.selectedBilanItemLocal.actifG7CirculantCompteRegularisationAmrtProv??0)
        },
        actifTotalIICirculantTotalAmrtProv(){
            return this.selectedBilanItemLocal.actifTotalIICirculantTotalAmrtProv = (this.selectedBilanItemLocal.actifFCirculantStocksAmrtProv??0) + (this.selectedBilanItemLocal.actifGCirculantCreancesCirculantAmrtProv??0) + (this.selectedBilanItemLocal.actifHCirculantTitresEtValeursDePlacementAmrtProv??0) + (this.selectedBilanItemLocal.actifICirculantEcartDeConversionAmrtProv??0)
        },
        actifTresorTresorerieTotalAmrtProv(){
            return this.selectedBilanItemLocal.actifTresorTresorerieTotalAmrtProv = (this.selectedBilanItemLocal.actifTresor1TresorerieChequesEtValeursAEncaisserAmrtProv??0) + (this.selectedBilanItemLocal.actifTresor2TresorerieBanquesTGEtCPAmrtProv??0) + (this.selectedBilanItemLocal.actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifsAmrtProv??0)
        },
        
        actifTotalGeneralAmrtProv(){
            return this.selectedBilanItemLocal.actifTotalGeneralAmrtProv = (this.selectedBilanItemLocal.actifTotalIImmobiliseTotalAmrtProv??0) + (this.selectedBilanItemLocal.actifTotalIICirculantTotalAmrtProv??0) + (this.selectedBilanItemLocal.actifTresorTresorerieTotalAmrtProv??0)
        },
        
        actifAImmobiliseImmobilisationEnNonValeurAnneeN1()
        {
            return this.selectedBilanItemLocalAnneeN1.actifAImmobiliseImmobilisationEnNonValeur = (this.selectedBilanItemLocalAnneeN1.actifA1ImmobiliseFraisPreliminaires??0) + (this.selectedBilanItemLocalAnneeN1.actifA2ImmobiliseChargesArepartirSurplusieursExercices??0) + (this.selectedBilanItemLocalAnneeN1.actifA3ImmobilisePrimesDeRemboursementDesObligations??0)
        },
        
        actifBImmobiliseImmobilisationsIncorporellesAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.actifBImmobiliseImmobilisationsIncorporelles = (this.selectedBilanItemLocalAnneeN1.actifB1ImmobiliseImmobilisationsEnRechercheEtDeveloppement??0) + (this.selectedBilanItemLocalAnneeN1.actifB2ImmobiliseBrevetsMarquesDroitsCaleursSimilaires??0) + (this.selectedBilanItemLocalAnneeN1.actifB3ImmobiliseFondsCommercial??0) + (this.selectedBilanItemLocalAnneeN1.actifB4ImmobiliseAutresImmobilisationsIncorporelles??0)
        },
        actifCImmobiliseImmobilisationsCorporellesAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.actifCImmobiliseImmobilisationsCorporelles = (this.selectedBilanItemLocalAnneeN1.actifC1ImmobiliseTerrains??0) + (this.selectedBilanItemLocalAnneeN1.actifC2ImmobiliseConstructions??0) + (this.selectedBilanItemLocalAnneeN1.actifC3ImmobiliseInstallationsTechniqueMaterielOutillage??0) + (this.selectedBilanItemLocalAnneeN1.actifC4ImmobiliseMaterielDeTransport??0) + (this.selectedBilanItemLocalAnneeN1.actifC5ImmobiliseMobiliersMaterielDeBureauEtAmenagementsDivers??0) + (this.selectedBilanItemLocalAnneeN1.actifC6ImmobiliseAutresImmobilisationsCorporelles??0) + (this.selectedBilanItemLocalAnneeN1.actifC7ImmobiliseImmobilisationsCorporellesEnCours??0)
        },
        actifDImmobiliseImmobilisationsFinancieresAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.actifDImmobiliseImmobilisationsFinancieres = (this.selectedBilanItemLocalAnneeN1.actifD1ImmobilisePretsImmobilises??0) + (this.selectedBilanItemLocalAnneeN1.actifD2ImmobiliseAutresCreancesFinancieres??0) + (this.selectedBilanItemLocalAnneeN1.actifD3ImmobiliseTitresDeParticipation??0) + (this.selectedBilanItemLocalAnneeN1.actifD4ImmobiliseAutresTitresImmobilises??0)
        },
        actifEImmobiliseEcartsDeConversionAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.actifEImmobiliseEcartsDeConversion = (this.selectedBilanItemLocalAnneeN1.actifE1ImmobiliseDiminutionDesCreancesImmobilisees??0) + (this.selectedBilanItemLocalAnneeN1.actifE2ImmobiliseAugmentationDesDettesDeFinancement??0)
        },
        actifTotalIImmobiliseTotalAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.actifTotalIImmobiliseTotal = (this.selectedBilanItemLocalAnneeN1.actifAImmobiliseImmobilisationEnNonValeur??0) + (this.selectedBilanItemLocalAnneeN1.actifBImmobiliseImmobilisationsIncorporelles??0) + (this.selectedBilanItemLocalAnneeN1.actifCImmobiliseImmobilisationsCorporelles??0) + (this.selectedBilanItemLocalAnneeN1.actifDImmobiliseImmobilisationsFinancieres??0) + (this.selectedBilanItemLocalAnneeN1.actifEImmobiliseEcartsDeConversion??0)
        },
        actifFCirculantStocksAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.actifFCirculantStocks = (this.selectedBilanItemLocalAnneeN1.actifF1CirculantMarchandises??0) + (this.selectedBilanItemLocalAnneeN1.actifF2CirculantMatieresEtFournituresConsommables??0) + (this.selectedBilanItemLocalAnneeN1.actifF3CirculantProduitsEnCours??0) + (this.selectedBilanItemLocalAnneeN1.actifF4CirculantProduitsIntermediairesEtProduitsResiduels??0) + (this.selectedBilanItemLocalAnneeN1.actifF5CirculantProduitsFinis??0)
        },
        actifGCirculantCreancesCirculantAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.actifGCirculantCreancesCirculant = (this.selectedBilanItemLocalAnneeN1.actifG1CirculantFournisseursdebiteursAvancesAcomptes??0) + (this.selectedBilanItemLocalAnneeN1.actifG2CirculantClientsEtComptesRattaches??0) + (this.selectedBilanItemLocalAnneeN1.actifG3CirculantPersonnel??0) + (this.selectedBilanItemLocalAnneeN1.actifG4CirculantEtat??0) + (this.selectedBilanItemLocalAnneeN1.actifG5CirculantCompteAssocies??0) + (this.selectedBilanItemLocalAnneeN1.actifG6CirculantAutresDebiteurs??0) + (this.selectedBilanItemLocalAnneeN1.actifG7CirculantCompteRegularisation??0)
        },
        actifTotalIICirculantTotalAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.actifTotalIICirculantTotal = (this.selectedBilanItemLocalAnneeN1.actifFCirculantStocks??0) + (this.selectedBilanItemLocalAnneeN1.actifGCirculantCreancesCirculant??0) + (this.selectedBilanItemLocalAnneeN1.actifHCirculantTitresEtValeursDePlacement??0) + (this.selectedBilanItemLocalAnneeN1.actifICirculantEcartDeConversion??0)
        },
        actifTresorTresorerieTotalAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.actifTresorTresorerieTotal = (this.selectedBilanItemLocalAnneeN1.actifTresor1TresorerieChequesEtValeursAEncaisser??0) + (this.selectedBilanItemLocalAnneeN1.actifTresor2TresorerieBanquesTGEtCP??0) + (this.selectedBilanItemLocalAnneeN1.actifTresor3TresorerieCaissesRegiesDAvancesEtAccreditifs??0)
        },
        actifTotalGeneralAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.actifTotalGeneral = (this.selectedBilanItemLocalAnneeN1.actifTotalIImmobiliseTotal??0) + (this.selectedBilanItemLocalAnneeN1.actifTotalIICirculantTotal??0) + (this.selectedBilanItemLocalAnneeN1.actifTresorTresorerieTotal??0)
        },

        // computed for passif

        passifAFinancementPermanentCapitauxPropres(){
            return this.selectedBilanItemLocal.passifAFinancementPermanentCapitauxPropres = (this.selectedBilanItemLocal.passifA2FinancementPermanentCapitalPersonnel??0) + (this.selectedBilanItemLocal.passifA3FinancementPermanentMoinsActionnairesCapitalsouscritnonappelenonverse??0) + (this.selectedBilanItemLocal.passifA6FinancementPermanentPrimeEmissionFusionApport??0) + (this.selectedBilanItemLocal.passifA7FinancementPermanentEcartsDeReevaluation??0) + (this.selectedBilanItemLocal.passifA8FinancementPermanentReserveLegale??0) + (this.selectedBilanItemLocal.passifA9FinancementPermanentAutresReserves??0) + (this.selectedBilanItemLocal.passifA10FinancementPermanentReportANouveau??0) + (this.selectedBilanItemLocal.passifA11FinancementPermanentResultatNetsEnInstanceDAffectation??0) + (this.selectedBilanItemLocal.passifA12FinancementPermanentResultatNetDeLexercice??0)
        },

        passifBFinancementPermanentCapitauxPropresAssimiles(){
            return this.selectedBilanItemLocal.passifBFinancementPermanentCapitauxPropresAssimiles = (this.selectedBilanItemLocal.passifB1FinancementPermanentSubventionInvestissement??0) + (this.selectedBilanItemLocal.passifB2FinancementPermanentProvisionReglementees??0)
        },

        passifCFinancementPermanentDettesDeFinancement(){
            return this.selectedBilanItemLocal.passifCFinancementPermanentDettesDeFinancement = (this.selectedBilanItemLocal.passifC1FinancementPermanentEmpruntsObligataires??0) + (this.selectedBilanItemLocal.passifC2FinancementPermanentAutresDettesDeFinancement??0)
        },
        passifDFinancementPermanentProvisionsDurablesPourRisquesEtCharges(){
            return this.selectedBilanItemLocal.passifDFinancementPermanentProvisionsDurablesPourRisquesEtCharges = (this.selectedBilanItemLocal.passifD1FinancementPermanentProvisionsPourRisques??0) + (this.selectedBilanItemLocal.passifD2FinancementPermanentProvisionsPourCharges??0)
        },
        passifEFinancementPermanentEcartsDeConversion(){
            return this.selectedBilanItemLocal.passifEFinancementPermanentEcartsDeConversion = (this.selectedBilanItemLocal.passifE1FinancementPermanentAugmentationDesCreancesImmobilisees??0) + (this.selectedBilanItemLocal.passifE2FinancementPermanentDiminutionDesDettesDeFinancement??0)
        },
        passifTotalIFinancementPermanentTotal(){
            return this.selectedBilanItemLocal.passifTotalIFinancementPermanentTotal = (this.selectedBilanItemLocal.passifAFinancementPermanentCapitauxPropres??0) + (this.selectedBilanItemLocal.passifBFinancementPermanentCapitauxPropresAssimiles??0) + (this.selectedBilanItemLocal.passifCFinancementPermanentDettesDeFinancement??0) + (this.selectedBilanItemLocal.passifDFinancementPermanentProvisionsDurablesPourRisquesEtCharges??0) + (this.selectedBilanItemLocal.passifEFinancementPermanentEcartsDeConversion??0)
        },
        passifFCirculantDettesDuCirculant(){
            return this.selectedBilanItemLocal.passifFCirculantDettesDuCirculant = (this.selectedBilanItemLocal.passifF1CirculantFournisseursEtComptesRattaches??0) + (this.selectedBilanItemLocal.passifF2CirculantClientsCrediteursAvancesEtAcomptes??0) + (this.selectedBilanItemLocal.passifF3CirculantPersonnel??0) + (this.selectedBilanItemLocal.passifF4CirculantOrganismesSociaux??0) + (this.selectedBilanItemLocal.passifF5CirculantEtat??0) + (this.selectedBilanItemLocal.passifF6CirculantComptesAssocies??0) + (this.selectedBilanItemLocal.passifF7CirculantAutresCreanciers??0) + (this.selectedBilanItemLocal.passifF8CirculantComptesDeRegularisation??0) 
        },
        passifTotalIICirculantTotal(){
            return this.selectedBilanItemLocal.passifTotalIICirculantTotal = (this.selectedBilanItemLocal.passifFCirculantDettesDuCirculant??0) + (this.selectedBilanItemLocal.passifGCirculantAutresProvisionsPourRisquesEtCharges??0) + (this.selectedBilanItemLocal.passifHCirculantEcartsDeConversion??0)
        },
        passifTresorTresorerieTresoreriePassif(){
            return this.selectedBilanItemLocal.passifTresorTresorerieTresoreriePassif = (this.selectedBilanItemLocal.passifTresor1TresorerieCreditsEscompte??0) + (this.selectedBilanItemLocal.passifTresor2TresorerieCreditDeTresorerie??0) + (this.selectedBilanItemLocal.passifTresor3TresorerieBanques??0)
        },
        TresorTresorerieTotalIII(){
            return this.selectedBilanItemLocal.TresorTresorerieTotalIII = (this.selectedBilanItemLocal.passifTresor1TresorerieCreditsEscompte??0) + (this.selectedBilanItemLocal.passifTresor2TresorerieCreditDeTresorerie??0) + (this.selectedBilanItemLocal.passifTresor3TresorerieBanques??0)
        },
        passifTotalGeneral(){
            return this.selectedBilanItemLocal.passifTotalGeneral = (this.selectedBilanItemLocal.passifTotalIFinancementPermanentTotal??0) + (this.selectedBilanItemLocal.passifTotalIICirculantTotal??0) + (this.selectedBilanItemLocal.passifTresorTresorerieTresoreriePassif??0)
        },

        passifAFinancementPermanentCapitauxPropresAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.passifAFinancementPermanentCapitauxPropres = (this.selectedBilanItemLocalAnneeN1.passifA2FinancementPermanentCapitalPersonnel??0) + (this.selectedBilanItemLocalAnneeN1.passifA3FinancementPermanentMoinsActionnairesCapitalsouscritnonappelenonverse??0) + (this.selectedBilanItemLocalAnneeN1.passifA6FinancementPermanentPrimeEmissionFusionApport??0) + (this.selectedBilanItemLocalAnneeN1.passifA7FinancementPermanentEcartsDeReevaluation??0) + (this.selectedBilanItemLocalAnneeN1.passifA8FinancementPermanentReserveLegale??0) + (this.selectedBilanItemLocalAnneeN1.passifA9FinancementPermanentAutresReserves??0) + (this.selectedBilanItemLocalAnneeN1.passifA10FinancementPermanentReportANouveau??0) + (this.selectedBilanItemLocalAnneeN1.passifA11FinancementPermanentResultatNetsEnInstanceDAffectation??0) + (this.selectedBilanItemLocalAnneeN1.passifA12FinancementPermanentResultatNetDeLexercice??0)
        },

        passifBFinancementPermanentCapitauxPropresAssimilesAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.passifBFinancementPermanentCapitauxPropresAssimiles = (this.selectedBilanItemLocalAnneeN1.passifB1FinancementPermanentSubventionInvestissement??0) + (this.selectedBilanItemLocalAnneeN1.passifB2FinancementPermanentProvisionReglementees??0)
        },

        passifCFinancementPermanentDettesDeFinancementAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.passifCFinancementPermanentDettesDeFinancement = (this.selectedBilanItemLocalAnneeN1.passifC1FinancementPermanentEmpruntsObligataires??0) + (this.selectedBilanItemLocalAnneeN1.passifC2FinancementPermanentAutresDettesDeFinancement??0)
        },
        passifDFinancementPermanentProvisionsDurablesPourRisquesEtChargesAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.passifDFinancementPermanentProvisionsDurablesPourRisquesEtCharges = (this.selectedBilanItemLocalAnneeN1.passifD1FinancementPermanentProvisionsPourRisques??0) + (this.selectedBilanItemLocalAnneeN1.passifD2FinancementPermanentProvisionsPourCharges??0)
        },
        passifEFinancementPermanentEcartsDeConversionAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.passifEFinancementPermanentEcartsDeConversion = (this.selectedBilanItemLocalAnneeN1.passifE1FinancementPermanentAugmentationDesCreancesImmobilisees??0) + (this.selectedBilanItemLocalAnneeN1.passifE2FinancementPermanentDiminutionDesDettesDeFinancement??0)
        },
        passifTotalIFinancementPermanentTotalAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.passifTotalIFinancementPermanentTotal = (this.selectedBilanItemLocalAnneeN1.passifAFinancementPermanentCapitauxPropres??0) + (this.selectedBilanItemLocalAnneeN1.passifBFinancementPermanentCapitauxPropresAssimiles??0) + (this.selectedBilanItemLocalAnneeN1.passifCFinancementPermanentDettesDeFinancement??0) + (this.selectedBilanItemLocalAnneeN1.passifDFinancementPermanentProvisionsDurablesPourRisquesEtCharges??0) + (this.selectedBilanItemLocalAnneeN1.passifEFinancementPermanentEcartsDeConversion??0)
        },
        passifFCirculantDettesDuCirculantAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.passifFCirculantDettesDuCirculant = (this.selectedBilanItemLocalAnneeN1.passifF1CirculantFournisseursEtComptesRattaches??0) + (this.selectedBilanItemLocalAnneeN1.passifF2CirculantClientsCrediteursAvancesEtAcomptes??0) + (this.selectedBilanItemLocalAnneeN1.passifF3CirculantPersonnel??0) + (this.selectedBilanItemLocalAnneeN1.passifF4CirculantOrganismesSociaux??0) + (this.selectedBilanItemLocalAnneeN1.passifF5CirculantEtat??0) + (this.selectedBilanItemLocalAnneeN1.passifF6CirculantComptesAssocies??0) + (this.selectedBilanItemLocalAnneeN1.passifF7CirculantAutresCreanciers??0) + (this.selectedBilanItemLocalAnneeN1.passifF8CirculantComptesDeRegularisation??0) 
        },
        passifTotalIICirculantTotalAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.passifTotalIICirculantTotal = (this.selectedBilanItemLocalAnneeN1.passifFCirculantDettesDuCirculant??0) + (this.selectedBilanItemLocalAnneeN1.passifGCirculantAutresProvisionsPourRisquesEtCharges??0) + (this.selectedBilanItemLocalAnneeN1.passifHCirculantEcartsDeConversion??0)
        },
        passifTresorTresorerieTresoreriePassifAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.passifTresorTresorerieTresoreriePassif = (this.selectedBilanItemLocalAnneeN1.passifTresor1TresorerieCreditsEscompte??0) + (this.selectedBilanItemLocalAnneeN1.passifTresor2TresorerieCreditDeTresorerie??0) + (this.selectedBilanItemLocalAnneeN1.passifTresor3TresorerieBanques??0)
        },
        TresorTresorerieTotalIIIAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.TresorTresorerieTotalIII = (this.selectedBilanItemLocalAnneeN1.passifTresor1TresorerieCreditsEscompte??0) + (this.selectedBilanItemLocalAnneeN1.passifTresor2TresorerieCreditDeTresorerie??0) + (this.selectedBilanItemLocalAnneeN1.passifTresor3TresorerieBanques??0)
        },
        passifTotalGeneralAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.passifTotalGeneral = (this.selectedBilanItemLocalAnneeN1.passifTotalIFinancementPermanentTotal??0) + (this.selectedBilanItemLocalAnneeN1.passifTotalIICirculantTotal??0) + (this.selectedBilanItemLocalAnneeN1.passifTresorTresorerieTresoreriePassif??0)
        },
        /// computed for cpc
        cpcIExploitationProduitsExploitation() {
            return this.selectedBilanItemLocal.cpcIExploitationProduitsExploitation = (this.selectedBilanItemLocal.cpcIExploitationProduitsExploitationExercice??0) - (this.selectedBilanItemLocal.cpcIExploitationProduitsExploitationPrecedent??0);
        },
        cpcI1ExploitationVentesDeMarchandises() {
            return this.selectedBilanItemLocal.cpcI1ExploitationVentesDeMarchandises = (this.selectedBilanItemLocal.cpcI1ExploitationVentesDeMarchandisesExercice??0) - (this.selectedBilanItemLocal.cpcI1ExploitationVentesDeMarchandisesPrecedent??0);
        },
        cpcI2ExploitationVentesBiensEtServicesProduits() {
            return this.selectedBilanItemLocal.cpcI2ExploitationVentesBiensEtServicesProduits = (this.selectedBilanItemLocal.cpcI2ExploitationVentesBiensEtServicesProduitsExercice??0) - (this.selectedBilanItemLocal.cpcI2ExploitationVentesBiensEtServicesProduitsPrecedent??0);
        },
        cpcI3ExploitationChiffresAffaires() {
            return this.selectedBilanItemLocal.cpcI3ExploitationChiffresAffaires = (this.selectedBilanItemLocal.cpcI3ExploitationChiffresAffairesExercice??0) - (this.selectedBilanItemLocal.cpcI3ExploitationChiffresAffairesPrecedent??0);
        },
        cpcI4ExploitationVariationDeStockDeProduits() {
            return this.selectedBilanItemLocal.cpcI4ExploitationVariationDeStockDeProduits = (this.selectedBilanItemLocal.cpcI4ExploitationVariationDeStockDeProduitsExercice??0) - (this.selectedBilanItemLocal.cpcI4ExploitationVariationDeStockDeProduitsPrecedent??0);
        },
        cpcI5ExploitationImmobilisationsProduitesPourElleMeme() {
            return this.selectedBilanItemLocal.cpcI5ExploitationImmobilisationsProduitesPourElleMeme = (this.selectedBilanItemLocal.cpcI5ExploitationImmobilisationsProduitesPourElleMemeExercice??0) - (this.selectedBilanItemLocal.cpcI5ExploitationImmobilisationsProduitesPourElleMemePrecedent??0);
        },
        cpcI6ExploitationSubventionDExploitation() {
            return this.selectedBilanItemLocal.cpcI6ExploitationSubventionDExploitation = (this.selectedBilanItemLocal.cpcI6ExploitationSubventionDExploitationExercice??0) - (this.selectedBilanItemLocal.cpcI6ExploitationSubventionDExploitationPrecedent??0);
        },
        cpcI7ExploitationAutresProduitsExploitation() {
            return this.selectedBilanItemLocal.cpcI7ExploitationAutresProduitsExploitation = (this.selectedBilanItemLocal.cpcI7ExploitationAutresProduitsExploitationExercice??0) - (this.selectedBilanItemLocal.cpcI7ExploitationAutresProduitsExploitationPrecedent??0);
        },
        cpcI8ExploitationReprisesExploitationTransfertDeCharges() {
            return this.selectedBilanItemLocal.cpcI8ExploitationReprisesExploitationTransfertDeCharges = (this.selectedBilanItemLocal.cpcI8ExploitationReprisesExploitationTransfertDeChargesExercice??0) - (this.selectedBilanItemLocal.cpcI8ExploitationReprisesExploitationTransfertDeChargesPrecedent??0);
        },
        cpcIIExploitationChargesExploitation() {
            return this.selectedBilanItemLocal.cpcIIExploitationChargesExploitation = (this.selectedBilanItemLocal.cpcIIExploitationChargesExploitationExercice??0) - (this.selectedBilanItemLocal.cpcIIExploitationChargesExploitationPrecedent??0);
        },
        cpcII1ExploitationAchatsRevendusDeMarchandises() {
            return this.selectedBilanItemLocal.cpcII1ExploitationAchatsRevendusDeMarchandises = (this.selectedBilanItemLocal.cpcII1ExploitationAchatsRevendusDeMarchandisesExercice??0) - (this.selectedBilanItemLocal.cpcII1ExploitationAchatsRevendusDeMarchandisesPrecedent??0);
        },
        cpcII2ExploitationAchatConsommesDeMatieresEtDeFournitures() {
            return this.selectedBilanItemLocal.cpcII2ExploitationAchatConsommesDeMatieresEtDeFournitures = (this.selectedBilanItemLocal.cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresExercice??0) - (this.selectedBilanItemLocal.cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresPrecedent??0);
        },
        cpcII3ExploitationAutresChargesExternes() {
            return this.selectedBilanItemLocal.cpcII3ExploitationAutresChargesExternes = (this.selectedBilanItemLocal.cpcII3ExploitationAutresChargesExternesExercice??0) - (this.selectedBilanItemLocal.cpcII3ExploitationAutresChargesExternesPrecedent??0);
        },
        cpcII4ExploitationImpotsEtTaxes() {
            return this.selectedBilanItemLocal.cpcII4ExploitationImpotsEtTaxes = (this.selectedBilanItemLocal.cpcII4ExploitationImpotsEtTaxesExercice??0) - (this.selectedBilanItemLocal.cpcII4ExploitationImpotsEtTaxesPrecedent??0);
        },
        cpcII5ExploitationChargesPersonnel() {
            return this.selectedBilanItemLocal.cpcII5ExploitationChargesPersonnel = (this.selectedBilanItemLocal.cpcII5ExploitationChargesPersonnelExercice??0) - (this.selectedBilanItemLocal.cpcII5ExploitationChargesPersonnelPrecedent??0);
        },
        cpcII6ExploitationAutresChargesExploitation() {
            return this.selectedBilanItemLocal.cpcII6ExploitationAutresChargesExploitation = (this.selectedBilanItemLocal.cpcII6ExploitationAutresChargesExploitationExercice??0) - (this.selectedBilanItemLocal.cpcII6ExploitationAutresChargesExploitationPrecedent??0);
        },
        cpcII7ExploitationDotationsExploitation() {
            return this.selectedBilanItemLocal.cpcII7ExploitationDotationsExploitation = (this.selectedBilanItemLocal.cpcII7ExploitationDotationsExploitationExercice??0) - (this.selectedBilanItemLocal.cpcII7ExploitationDotationsExploitationPrecedent??0);
        },
        cpcIIIExploitationResultatExploitation() {
            return this.selectedBilanItemLocal.cpcIIIExploitationResultatExploitation = (this.selectedBilanItemLocal.cpcIIIExploitationResultatExploitationExercice??0) - (this.selectedBilanItemLocal.cpcIIIExploitationResultatExploitationPrecedent??0);
        },
        cpcIVFinancierProduitsFinanciers() {
            return this.selectedBilanItemLocal.cpcIVFinancierProduitsFinanciers = (this.selectedBilanItemLocal.cpcIVFinancierProduitsFinanciersExercice??0) - (this.selectedBilanItemLocal.cpcIVFinancierProduitsFinanciersPrecedent??0);
        },
        cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilises() {
            return this.selectedBilanItemLocal.cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilises = (this.selectedBilanItemLocal.cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesExercice??0) - (this.selectedBilanItemLocal.cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesPrecedent??0);
        },
        cpcIV2FinancierGainsDeChange() {
            return this.selectedBilanItemLocal.cpcIV2FinancierGainsDeChange = (this.selectedBilanItemLocal.cpcIV2FinancierGainsDeChangeExercice??0) - (this.selectedBilanItemLocal.cpcIV2FinancierGainsDeChangePrecedent??0);
        },
        cpcIV3FinancierInteretsEtAutresProduitsFinanciers() {
            return this.selectedBilanItemLocal.cpcIV3FinancierInteretsEtAutresProduitsFinanciers = (this.selectedBilanItemLocal.cpcIV3FinancierInteretsEtAutresProduitsFinanciersExercice??0) - (this.selectedBilanItemLocal.cpcIV3FinancierInteretsEtAutresProduitsFinanciersPrecedent??0);
        },
        cpcIV4FinancierReprisesFinancieresTransfertDeCharges() {
            return this.selectedBilanItemLocal.cpcIV4FinancierReprisesFinancieresTransfertDeCharges = (this.selectedBilanItemLocal.cpcIV4FinancierReprisesFinancieresTransfertDeChargesExercice??0) - (this.selectedBilanItemLocal.cpcIV4FinancierReprisesFinancieresTransfertDeChargesPrecedent??0);
        },
        cpcVFinancierChargesFinancieres() {
            return this.selectedBilanItemLocal.cpcVFinancierChargesFinancieres = (this.selectedBilanItemLocal.cpcVFinancierChargesFinancieresExercice??0) - (this.selectedBilanItemLocal.cpcVFinancierChargesFinancieresPrecedent??0);
        },
        cpcV1FinancierChargesInteret() {
            return this.selectedBilanItemLocal.cpcV1FinancierChargesInteret = (this.selectedBilanItemLocal.cpcV1FinancierChargesInteretExercice??0) - (this.selectedBilanItemLocal.cpcV1FinancierChargesInteretPrecedent??0);
        },
        cpcV2FinancierPertesDeChanges() {
            return this.selectedBilanItemLocal.cpcV2FinancierPertesDeChanges = (this.selectedBilanItemLocal.cpcV2FinancierPertesDeChangesExercice??0) - (this.selectedBilanItemLocal.cpcV2FinancierPertesDeChangesPrecedent??0);
        },
        cpcV3FinancierAutresChargesFinancieres() {
            return this.selectedBilanItemLocal.cpcV3FinancierAutresChargesFinancieres = (this.selectedBilanItemLocal.cpcV3FinancierAutresChargesFinancieresExercice??0) - (this.selectedBilanItemLocal.cpcV3FinancierAutresChargesFinancieresPrecedent??0);
        },
        cpcV4FinancierDotationFinancieres() {
            return this.selectedBilanItemLocal.cpcV4FinancierDotationFinancieres = (this.selectedBilanItemLocal.cpcV4FinancierDotationFinancieresExercice??0) - (this.selectedBilanItemLocal.cpcV4FinancierDotationFinancieresPrecedent??0);
        },
        cpcVIFinancierResultatFinancier() {
            return this.selectedBilanItemLocal.cpcVIFinancierResultatFinancier = (this.selectedBilanItemLocal.cpcVIFinancierResultatFinancierExercice??0) - (this.selectedBilanItemLocal.cpcVIFinancierResultatFinancierPrecedent??0);
        },
        cpcVIICourantResultatCourant() {
            return this.selectedBilanItemLocal.cpcVIICourantResultatCourant = (this.selectedBilanItemLocal.cpcVIICourantResultatCourantExercice??0) - (this.selectedBilanItemLocal.cpcVIICourantResultatCourantPrecedent??0);
        },
        cpcVIIINonCourantProduitsNonCourants() {
            return this.selectedBilanItemLocal.cpcVIIINonCourantProduitsNonCourants = (this.selectedBilanItemLocal.cpcVIIINonCourantProduitsNonCourantsExercice??0) - (this.selectedBilanItemLocal.cpcVIIINonCourantProduitsNonCourantsPrecedent??0);
        },
        cpcVIII1NonCourantProduitsDesCessionsDImmobilisations() {
            return this.selectedBilanItemLocal.cpcVIII1NonCourantProduitsDesCessionsDImmobilisations = (this.selectedBilanItemLocal.cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsExercice??0) - (this.selectedBilanItemLocal.cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsPrecedent??0);
        },
        cpcVIII2NonCourantSubventionsDEquilibre() {
            return this.selectedBilanItemLocal.cpcVIII2NonCourantSubventionsDEquilibre = (this.selectedBilanItemLocal.cpcVIII2NonCourantSubventionsDEquilibreExercice??0) - (this.selectedBilanItemLocal.cpcVIII2NonCourantSubventionsDEquilibrePrecedent??0);
        },
        cpcVIII3NonCourantReprisesSurSubventionsDInvestissement() {
            return this.selectedBilanItemLocal.cpcVIII3NonCourantReprisesSurSubventionsDInvestissement = (this.selectedBilanItemLocal.cpcVIII3NonCourantReprisesSurSubventionsDInvestissementExercice??0) - (this.selectedBilanItemLocal.cpcVIII3NonCourantReprisesSurSubventionsDInvestissementPrecedent??0);
        },
        cpcVIII4NonCourantAutresProduitsNonCourants() {
            return this.selectedBilanItemLocal.cpcVIII4NonCourantAutresProduitsNonCourants = (this.selectedBilanItemLocal.cpcVIII4NonCourantAutresProduitsNonCourantsExercice??0) - (this.selectedBilanItemLocal.cpcVIII4NonCourantAutresProduitsNonCourantsPrecedent??0);
        },
        cpcVIII5NonCourantReprisesNonCourantesTransfertsDeCharges() {
            return this.selectedBilanItemLocal.cpcVIII5NonCourantReprisesNonCourantesTransfertsDeCharges = (this.selectedBilanItemLocal.cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesExercice??0) - (this.selectedBilanItemLocal.cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesPrecedent??0);
        },
        cpcIXNonCourantChargesNonCourantes() {
            return this.selectedBilanItemLocal.cpcIXNonCourantChargesNonCourantes = (this.selectedBilanItemLocal.cpcIXNonCourantChargesNonCourantesExercice??0) - (this.selectedBilanItemLocal.cpcIXNonCourantChargesNonCourantesPrecedent??0);
        },
        cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedees() {
            return this.selectedBilanItemLocal.cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedees = (this.selectedBilanItemLocal.cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesExercice??0) - (this.selectedBilanItemLocal.cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesPrecedent??0);
        },
        cpcIX2NonCourantSubventionsAccordees() {
            return this.selectedBilanItemLocal.cpcIX2NonCourantSubventionsAccordees = (this.selectedBilanItemLocal.cpcIX2NonCourantSubventionsAccordeesExercice??0) - (this.selectedBilanItemLocal.cpcIX2NonCourantSubventionsAccordeesPrecedent??0);
        },
        cpcIX3NonCourantAutresChargesNonCourantes() {
            return this.selectedBilanItemLocal.cpcIX3NonCourantAutresChargesNonCourantes = (this.selectedBilanItemLocal.cpcIX3NonCourantAutresChargesNonCourantesExercice??0) - (this.selectedBilanItemLocal.cpcIX3NonCourantAutresChargesNonCourantesPrecedent??0);
        },
        cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvision() {
            return this.selectedBilanItemLocal.cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvision = (this.selectedBilanItemLocal.cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionExercice??0) - (this.selectedBilanItemLocal.cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionPrecedent??0);
        },
        cpcXResultatNonCourant() {
            return this.selectedBilanItemLocal.cpcXResultatNonCourant = (this.selectedBilanItemLocal.cpcXResultatNonCourantExercice??0) - (this.selectedBilanItemLocal.cpcXResultatNonCourantPrecedent??0);
        },
        cpcXIResultatAvantImpots() {
            return this.selectedBilanItemLocal.cpcXIResultatAvantImpots = (this.selectedBilanItemLocal.cpcXIResultatAvantImpotsExercice??0) - (this.selectedBilanItemLocal.cpcXIResultatAvantImpotsPrecedent??0);
        },
        cpcXIIImpotsSurLesResultats() {
            return this.selectedBilanItemLocal.cpcXIIImpotsSurLesResultats = (this.selectedBilanItemLocal.cpcXIIImpotsSurLesResultatsExercice??0) - (this.selectedBilanItemLocal.cpcXIIImpotsSurLesResultatsPrecedent??0);
        },
        cpcXIIIResultatNet() {
            return this.selectedBilanItemLocal.cpcXIIIResultatNet = (this.selectedBilanItemLocal.cpcXIIIResultatNetExercice??0) - (this.selectedBilanItemLocal.cpcXIIIResultatNetPrecedent??0);
        },
        cpcXIVTotalDesProduits() {
            return this.selectedBilanItemLocal.cpcXIVTotalDesProduits = (this.selectedBilanItemLocal.cpcXIVTotalDesProduitsExercice??0) - (this.selectedBilanItemLocal.cpcXIVTotalDesProduitsPrecedent??0);
        },
        cpcXVTotalDesCharges() {
            return this.selectedBilanItemLocal.cpcXVTotalDesCharges = (this.selectedBilanItemLocal.cpcXVTotalDesChargesExercice??0) - (this.selectedBilanItemLocal.cpcXVTotalDesChargesPrecedent??0);
        },
        cpcXVIResultatNET() {
            return this.selectedBilanItemLocal.cpcXVIResultatNET = (this.selectedBilanItemLocal.cpcXVIResultatNETExercice??0) - (this.selectedBilanItemLocal.cpcXVIResultatNETPrecedent??0);
        },
        

        //

        cpcI3ExploitationChiffresAffairesExercice()
        {
            return this.selectedBilanItemLocal.cpcI3ExploitationChiffresAffairesExercice = (this.selectedBilanItemLocal.cpcI1ExploitationVentesDeMarchandisesExercice??0) + (this.selectedBilanItemLocal.cpcI2ExploitationVentesBiensEtServicesProduitsExercice??0)
        },
        cpcIExploitationProduitsExploitationExercice(){
            return this.selectedBilanItemLocal.cpcIExploitationProduitsExploitationExercice =  (this.selectedBilanItemLocal.cpcI3ExploitationChiffresAffairesExercice??0) + (this.selectedBilanItemLocal.cpcI4ExploitationVariationDeStockDeProduitsExercice??0) + (this.selectedBilanItemLocal.cpcI5ExploitationImmobilisationsProduitesPourElleMemeExercice??0) + (this.selectedBilanItemLocal.cpcI6ExploitationSubventionDExploitationExercice??0) + (this.selectedBilanItemLocal.cpcI7ExploitationAutresProduitsExploitationExercice??0) + (this.selectedBilanItemLocal.cpcI8ExploitationReprisesExploitationTransfertDeChargesExercice??0)
        },
        cpcIIExploitationChargesExploitationExercice() {
            return this.selectedBilanItemLocal.cpcIIExploitationChargesExploitationExercice = (this.selectedBilanItemLocal.cpcII1ExploitationAchatsRevendusDeMarchandisesExercice??0) + (this.selectedBilanItemLocal.cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresExercice??0) + (this.selectedBilanItemLocal.cpcII3ExploitationAutresChargesExternesExercice??0) + (this.selectedBilanItemLocal.cpcII4ExploitationImpotsEtTaxesExercice??0) + (this.selectedBilanItemLocal.cpcII5ExploitationChargesPersonnelExercice??0) + (this.selectedBilanItemLocal.cpcII6ExploitationAutresChargesExploitationExercice??0) + (this.selectedBilanItemLocal.cpcII7ExploitationDotationsExploitationExercice??0)
        },
        cpcIIIExploitationResultatExploitationExercice() {
            return this.selectedBilanItemLocal.cpcIIIExploitationResultatExploitationExercice = (this.selectedBilanItemLocal.cpcIExploitationProduitsExploitationExercice??0) - (this.selectedBilanItemLocal.cpcIIExploitationChargesExploitationExercice??0)
        },
        cpcIVFinancierProduitsFinanciersExercice() {
            return this.selectedBilanItemLocal.cpcIVFinancierProduitsFinanciersExercice = (this.selectedBilanItemLocal.cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesExercice??0) +  (this.selectedBilanItemLocal.cpcIV2FinancierGainsDeChangeExercice??0) + (this.selectedBilanItemLocal.cpcIV3FinancierInteretsEtAutresProduitsFinanciersExercice??0) + (this.selectedBilanItemLocal.cpcIV4FinancierReprisesFinancieresTransfertDeChargesExercice??0)
        },
        cpcVFinancierChargesFinancieresExercice() {
            return this.selectedBilanItemLocal.cpcVFinancierChargesFinancieresExercice = (this.selectedBilanItemLocal.cpcV1FinancierChargesInteretExercice??0) + (this.selectedBilanItemLocal.cpcV2FinancierPertesDeChangesExercice??0) + (this.selectedBilanItemLocal.cpcV3FinancierAutresChargesFinancieresExercice??0) + (this.selectedBilanItemLocal.cpcV4FinancierDotationFinancieresExercice??0)
        },
        cpcVIFinancierResultatFinancierExercice() {
            return this.selectedBilanItemLocal.cpcVIFinancierResultatFinancierExercice = (this.selectedBilanItemLocal.cpcIVFinancierProduitsFinanciersExercice??0) - (this.selectedBilanItemLocal.cpcVFinancierChargesFinancieresExercice??0)
        },
        cpcVIICourantResultatCourantExercice() {
            return this.selectedBilanItemLocal.cpcVIICourantResultatCourantExercice = (this.selectedBilanItemLocal.cpcIIIExploitationResultatExploitationExercice??0) + (this.selectedBilanItemLocal.cpcVIFinancierResultatFinancierExercice??0)
        },
        cpcVIIINonCourantProduitsNonCourantsExercice() {
            return this.selectedBilanItemLocal.cpcVIIINonCourantProduitsNonCourantsExercice = (this.selectedBilanItemLocal.cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsExercice??0) + (this.selectedBilanItemLocal.cpcVIII2NonCourantSubventionsDEquilibreExercice??0) + (this.selectedBilanItemLocal.cpcVIII3NonCourantReprisesSurSubventionsDInvestissementExercice??0) + (this.selectedBilanItemLocal.cpcVIII4NonCourantAutresProduitsNonCourantsExercice??0) + (this.selectedBilanItemLocal.cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesExercice??0)
        },
        cpcIXNonCourantChargesNonCourantesExercice() {
            return this.selectedBilanItemLocal.cpcIXNonCourantChargesNonCourantesExercice = (this.selectedBilanItemLocal.cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesExercice??0) + (this.selectedBilanItemLocal.cpcIX2NonCourantSubventionsAccordeesExercice??0) + (this.selectedBilanItemLocal.cpcIX3NonCourantAutresChargesNonCourantesExercice??0) + (this.selectedBilanItemLocal.cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionExercice??0)
        },
        cpcXResultatNonCourantExercice() {
            return this.selectedBilanItemLocal.cpcXResultatNonCourantExercice = (this.selectedBilanItemLocal.cpcVIIINonCourantProduitsNonCourantsExercice??0) - (this.selectedBilanItemLocal.cpcIXNonCourantChargesNonCourantesExercice??0)
        },
        cpcXIResultatAvantImpotsExercice() {
            return this.selectedBilanItemLocal.cpcXIResultatAvantImpotsExercice = (this.selectedBilanItemLocal.cpcVIICourantResultatCourantExercice??0) + (this.selectedBilanItemLocal.cpcXResultatNonCourantExercice??0)
        },
        cpcXIIIResultatNetExercice() {
            return this.selectedBilanItemLocal.cpcXIIIResultatNetExercice = (this.selectedBilanItemLocal.cpcXIResultatAvantImpotsExercice??0) - (this.selectedBilanItemLocal.cpcXIIImpotsSurLesResultatsExercice??0)
        },
        cpcXIVTotalDesProduitsExercice() {
            return this.selectedBilanItemLocal.cpcXIVTotalDesProduitsExercice = (this.selectedBilanItemLocal.cpcIExploitationProduitsExploitationExercice??0) + (this.selectedBilanItemLocal.cpcIVFinancierProduitsFinanciersExercice??0) + (this.selectedBilanItemLocal.cpcVIIINonCourantProduitsNonCourantsExercice??0)
        },
        cpcXVTotalDesChargesExercice() {
            return this.selectedBilanItemLocal.cpcXVTotalDesChargesExercice = (this.selectedBilanItemLocal.cpcIIExploitationChargesExploitationExercice??0) + (this.selectedBilanItemLocal.cpcVFinancierChargesFinancieresExercice??0) + (this.selectedBilanItemLocal.cpcIXNonCourantChargesNonCourantesExercice??0) + (this.selectedBilanItemLocal.cpcXIIImpotsSurLesResultatsExercice??0)
        },
        cpcXVIResultatNETExercice() {
            return this.selectedBilanItemLocal.cpcXVIResultatNETExercice = (this.selectedBilanItemLocal.cpcXIVTotalDesProduitsExercice??0) - (this.selectedBilanItemLocal.cpcXVTotalDesChargesExercice??0)
        },

        cpcI3ExploitationChiffresAffairesPrecedent()
        {
            return this.selectedBilanItemLocal.cpcI3ExploitationChiffresAffairesPrecedent = (this.selectedBilanItemLocal.cpcI1ExploitationVentesDeMarchandisesPrecedent??0) + (this.selectedBilanItemLocal.cpcI2ExploitationVentesBiensEtServicesProduitsPrecedent??0)
        },
        cpcIExploitationProduitsExploitationPrecedent(){
            return this.selectedBilanItemLocal.cpcIExploitationProduitsExploitationPrecedent = (this.selectedBilanItemLocal.cpcI3ExploitationChiffresAffairesPrecedent??0) + (this.selectedBilanItemLocal.cpcI4ExploitationVariationDeStockDeProduitsPrecedent??0) + (this.selectedBilanItemLocal.cpcI5ExploitationImmobilisationsProduitesPourElleMemePrecedent??0) + (this.selectedBilanItemLocal.cpcI6ExploitationSubventionDExploitationPrecedent??0) + (this.selectedBilanItemLocal.cpcI7ExploitationAutresProduitsExploitationPrecedent??0) + (this.selectedBilanItemLocal.cpcI8ExploitationReprisesExploitationTransfertDeChargesPrecedent??0)
        },
        cpcIIExploitationChargesExploitationPrecedent() {
            return this.selectedBilanItemLocal.cpcIIExploitationChargesExploitationPrecedent = (this.selectedBilanItemLocal.cpcII1ExploitationAchatsRevendusDeMarchandisesPrecedent??0) + (this.selectedBilanItemLocal.cpcII2ExploitationAchatConsommesDeMatieresEtDeFournituresPrecedent??0) + (this.selectedBilanItemLocal.cpcII3ExploitationAutresChargesExternesPrecedent??0) + (this.selectedBilanItemLocal.cpcII4ExploitationImpotsEtTaxesPrecedent??0) + (this.selectedBilanItemLocal.cpcII5ExploitationChargesPersonnelPrecedent??0) + (this.selectedBilanItemLocal.cpcII6ExploitationAutresChargesExploitationPrecedent??0) + (this.selectedBilanItemLocal.cpcII7ExploitationDotationsExploitationPrecedent??0)
        },
        cpcIIIExploitationResultatExploitationPrecedent() {
            return this.selectedBilanItemLocal.cpcIIIExploitationResultatExploitationPrecedent = (this.selectedBilanItemLocal.cpcIExploitationProduitsExploitationPrecedent??0) - (this.selectedBilanItemLocal.cpcIIExploitationChargesExploitationPrecedent??0)
        },
        cpcIVFinancierProduitsFinanciersPrecedent() {
            return this.selectedBilanItemLocal.cpcIVFinancierProduitsFinanciersPrecedent = (this.selectedBilanItemLocal.cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilisesPrecedent??0) +  (this.selectedBilanItemLocal.cpcIV2FinancierGainsDeChangePrecedent??0) + (this.selectedBilanItemLocal.cpcIV3FinancierInteretsEtAutresProduitsFinanciersPrecedent??0) + (this.selectedBilanItemLocal.cpcIV4FinancierReprisesFinancieresTransfertDeChargesPrecedent??0)
        },
        cpcVFinancierChargesFinancieresPrecedent() {
            return this.selectedBilanItemLocal.cpcVFinancierChargesFinancieresPrecedent = (this.selectedBilanItemLocal.cpcV1FinancierChargesInteretPrecedent??0) + (this.selectedBilanItemLocal.cpcV2FinancierPertesDeChangesPrecedent??0) + (this.selectedBilanItemLocal.cpcV3FinancierAutresChargesFinancieresPrecedent??0) + (this.selectedBilanItemLocal.cpcV4FinancierDotationFinancieresPrecedent??0)
        },
        cpcVIFinancierResultatFinancierPrecedent() {
            return this.selectedBilanItemLocal.cpcVIFinancierResultatFinancierPrecedent = (this.selectedBilanItemLocal.cpcIVFinancierProduitsFinanciersPrecedent??0) - (this.selectedBilanItemLocal.cpcVFinancierChargesFinancieresPrecedent??0)
        },
        cpcVIICourantResultatCourantPrecedent() {
            return this.selectedBilanItemLocal.cpcVIICourantResultatCourantPrecedent = (this.selectedBilanItemLocal.cpcIIIExploitationResultatExploitationPrecedent??0) + (this.selectedBilanItemLocal.cpcVIFinancierResultatFinancierPrecedent??0)
        },
        cpcVIIINonCourantProduitsNonCourantsPrecedent() {
            return this.selectedBilanItemLocal.cpcVIIINonCourantProduitsNonCourantsPrecedent = (this.selectedBilanItemLocal.cpcVIII1NonCourantProduitsDesCessionsDImmobilisationsPrecedent??0) + (this.selectedBilanItemLocal.cpcVIII2NonCourantSubventionsDEquilibrePrecedent??0) + (this.selectedBilanItemLocal.cpcVIII3NonCourantReprisesSurSubventionsDInvestissementPrecedent??0) + (this.selectedBilanItemLocal.cpcVIII4NonCourantAutresProduitsNonCourantsPrecedent??0) + (this.selectedBilanItemLocal.cpcVIII5NonCourantReprisesNonCourantesTransfertsDeChargesPrecedent??0)
        },
        cpcIXNonCourantChargesNonCourantesPrecedent() {
            return this.selectedBilanItemLocal.cpcIXNonCourantChargesNonCourantesPrecedent = (this.selectedBilanItemLocal.cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedeesPrecedent??0) + (this.selectedBilanItemLocal.cpcIX2NonCourantSubventionsAccordeesPrecedent??0) + (this.selectedBilanItemLocal.cpcIX3NonCourantAutresChargesNonCourantesPrecedent??0) + (this.selectedBilanItemLocal.cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvisionPrecedent??0)
        },
        cpcXResultatNonCourantPrecedent() {
            return this.selectedBilanItemLocal.cpcXResultatNonCourantPrecedent = (this.selectedBilanItemLocal.cpcVIIINonCourantProduitsNonCourantsPrecedent??0) - (this.selectedBilanItemLocal.cpcIXNonCourantChargesNonCourantesPrecedent??0)
        },
        cpcXIResultatAvantImpotsPrecedent() {
            return this.selectedBilanItemLocal.cpcXIResultatAvantImpotsPrecedent = (this.selectedBilanItemLocal.cpcVIICourantResultatCourantPrecedent??0) + (this.selectedBilanItemLocal.cpcXResultatNonCourantPrecedent??0)
        },
        cpcXIIIResultatNetPrecedent() {
            return this.selectedBilanItemLocal.cpcXIIIResultatNetPrecedent = (this.selectedBilanItemLocal.cpcXIResultatAvantImpotsPrecedent??0) - (this.selectedBilanItemLocal.cpcXIIImpotsSurLesResultatsPrecedent??0)
        },
        cpcXIVTotalDesProduitsPrecedent() {
            return this.selectedBilanItemLocal.cpcXIVTotalDesProduitsPrecedent = (this.selectedBilanItemLocal.cpcIExploitationProduitsExploitationPrecedent??0) + (this.selectedBilanItemLocal.cpcIVFinancierProduitsFinanciersPrecedent??0) + (this.selectedBilanItemLocal.cpcVIIINonCourantProduitsNonCourantsPrecedent??0)
        },
        cpcXVTotalDesChargesPrecedent() {
            return this.selectedBilanItemLocal.cpcXVTotalDesChargesPrecedent = (this.selectedBilanItemLocal.cpcIIExploitationChargesExploitationPrecedent??0) + (this.selectedBilanItemLocal.cpcVFinancierChargesFinancieresPrecedent??0) + (this.selectedBilanItemLocal.cpcIXNonCourantChargesNonCourantesPrecedent??0) + (this.selectedBilanItemLocal.cpcXIIImpotsSurLesResultatsPrecedent??0)
        },
        cpcXVIResultatNETPrecedent() {
            return this.selectedBilanItemLocal.cpcXVIResultatNETPrecedent = (this.selectedBilanItemLocal.cpcXIVTotalDesProduitsPrecedent??0) - (this.selectedBilanItemLocal.cpcXVTotalDesChargesPrecedent??0)
        },

        cpcI3ExploitationChiffresAffairesAnneeN1()
        {
            return this.selectedBilanItemLocalAnneeN1.cpcI3ExploitationChiffresAffaires = (this.selectedBilanItemLocalAnneeN1.cpcI1ExploitationVentesDeMarchandises??0) + (this.selectedBilanItemLocalAnneeN1.cpcI2ExploitationVentesBiensEtServicesProduits??0)
        },
        cpcIExploitationProduitsExploitationAnneeN1(){
            return this.selectedBilanItemLocalAnneeN1.cpcIExploitationProduitsExploitation = (this.selectedBilanItemLocalAnneeN1.cpcI3ExploitationChiffresAffaires??0) + (this.selectedBilanItemLocalAnneeN1.cpcI4ExploitationVariationDeStockDeProduits??0) + (this.selectedBilanItemLocalAnneeN1.cpcI5ExploitationImmobilisationsProduitesPourElleMeme??0) + (this.selectedBilanItemLocalAnneeN1.cpcI6ExploitationSubventionDExploitation??0) + (this.selectedBilanItemLocalAnneeN1.cpcI7ExploitationAutresProduitsExploitation??0) + (this.selectedBilanItemLocalAnneeN1.cpcI8ExploitationReprisesExploitationTransfertDeCharges??0)
        },
        cpcIIExploitationChargesExploitationAnneeN1() {
            return this.selectedBilanItemLocalAnneeN1.cpcIIExploitationChargesExploitation = (this.selectedBilanItemLocalAnneeN1.cpcII1ExploitationAchatsRevendusDeMarchandises??0) + (this.selectedBilanItemLocalAnneeN1.cpcII2ExploitationAchatConsommesDeMatieresEtDeFournitures??0) + (this.selectedBilanItemLocalAnneeN1.cpcII3ExploitationAutresChargesExternes??0) + (this.selectedBilanItemLocalAnneeN1.cpcII4ExploitationImpotsEtTaxes??0) + (this.selectedBilanItemLocalAnneeN1.cpcII5ExploitationChargesPersonnel??0) + (this.selectedBilanItemLocalAnneeN1.cpcII6ExploitationAutresChargesExploitation??0) + (this.selectedBilanItemLocalAnneeN1.cpcII7ExploitationDotationsExploitation??0)
        },
        cpcIIIExploitationResultatExploitationAnneeN1() {
            return this.selectedBilanItemLocalAnneeN1.cpcIIIExploitationResultatExploitation = (this.selectedBilanItemLocalAnneeN1.cpcIExploitationProduitsExploitation??0) - (this.selectedBilanItemLocalAnneeN1.cpcIIExploitationChargesExploitation??0)
        },
        cpcIVFinancierProduitsFinanciersAnneeN1() {
            return this.selectedBilanItemLocalAnneeN1.cpcIVFinancierProduitsFinanciers = (this.selectedBilanItemLocalAnneeN1.cpcIV1FinancierProduitsTitresParticipationEtAutresTitresImmobilises??0) +  (this.selectedBilanItemLocalAnneeN1.cpcIV2FinancierGainsDeChange??0) + (this.selectedBilanItemLocalAnneeN1.cpcIV3FinancierInteretsEtAutresProduitsFinanciers??0) + (this.selectedBilanItemLocalAnneeN1.cpcIV4FinancierReprisesFinancieresTransfertDeCharges??0)
        },
        cpcVFinancierChargesFinancieresAnneeN1() {
            return this.selectedBilanItemLocalAnneeN1.cpcVFinancierChargesFinancieres = (this.selectedBilanItemLocalAnneeN1.cpcV1FinancierChargesInteret??0) + (this.selectedBilanItemLocalAnneeN1.cpcV2FinancierPertesDeChanges??0) + (this.selectedBilanItemLocalAnneeN1.cpcV3FinancierAutresChargesFinancieres??0) + (this.selectedBilanItemLocalAnneeN1.cpcV4FinancierDotationFinancieres??0)
        },
        cpcVIFinancierResultatFinancierAnneeN1() {
            return this.selectedBilanItemLocalAnneeN1.cpcVIFinancierResultatFinancier = (this.selectedBilanItemLocalAnneeN1.cpcIVFinancierProduitsFinanciers??0) - (this.selectedBilanItemLocalAnneeN1.cpcVFinancierChargesFinancieres??0)
        },
        cpcVIICourantResultatCourantAnneeN1() {
            return this.selectedBilanItemLocalAnneeN1.cpcVIICourantResultatCourant = (this.selectedBilanItemLocalAnneeN1.cpcIIIExploitationResultatExploitation??0) + (this.selectedBilanItemLocalAnneeN1.cpcVIFinancierResultatFinancier??0)
        },
        cpcVIIINonCourantProduitsNonCourantsAnneeN1() {
            return this.selectedBilanItemLocalAnneeN1.cpcVIIINonCourantProduitsNonCourants = (this.selectedBilanItemLocalAnneeN1.cpcVIII1NonCourantProduitsDesCessionsDImmobilisations??0) + (this.selectedBilanItemLocalAnneeN1.cpcVIII2NonCourantSubventionsDEquilibre??0) + (this.selectedBilanItemLocalAnneeN1.cpcVIII3NonCourantReprisesSurSubventionsDInvestissement??0) + (this.selectedBilanItemLocalAnneeN1.cpcVIII4NonCourantAutresProduitsNonCourants??0) + (this.selectedBilanItemLocalAnneeN1.cpcVIII5NonCourantReprisesNonCourantesTransfertsDeCharges??0)
        },
        cpcIXNonCourantChargesNonCourantesAnneeN1() {
            return this.selectedBilanItemLocalAnneeN1.cpcIXNonCourantChargesNonCourantes = (this.selectedBilanItemLocalAnneeN1.cpcIX1NonCourantValeursNettesAmortissementsDesImmoCedees??0) + (this.selectedBilanItemLocalAnneeN1.cpcIX2NonCourantSubventionsAccordees??0) + (this.selectedBilanItemLocalAnneeN1.cpcIX3NonCourantAutresChargesNonCourantes??0) + (this.selectedBilanItemLocalAnneeN1.cpcIX4NonCourantDotationsNonCourantesAuxAmortissEtProvision??0)
        },
        cpcXResultatNonCourantAnneeN1() {
            return this.selectedBilanItemLocalAnneeN1.cpcXResultatNonCourant = (this.selectedBilanItemLocalAnneeN1.cpcVIIINonCourantProduitsNonCourants??0) - (this.selectedBilanItemLocalAnneeN1.cpcIXNonCourantChargesNonCourantes??0)
        },
        cpcXIResultatAvantImpotsAnneeN1() {
            return this.selectedBilanItemLocalAnneeN1.cpcXIResultatAvantImpots = (this.selectedBilanItemLocalAnneeN1.cpcVIICourantResultatCourant??0) + (this.selectedBilanItemLocalAnneeN1.cpcXResultatNonCourant??0)
        },
        cpcXIIIResultatNetAnneeN1() {
            return this.selectedBilanItemLocalAnneeN1.cpcXIIIResultatNet = (this.selectedBilanItemLocalAnneeN1.cpcXIResultatAvantImpots??0) - (this.selectedBilanItemLocalAnneeN1.cpcXIIImpotsSurLesResultats??0)
        },
        cpcXIVTotalDesProduitsAnneeN1() {
            return this.selectedBilanItemLocalAnneeN1.cpcXIVTotalDesProduits = (this.selectedBilanItemLocalAnneeN1.cpcIExploitationProduitsExploitation??0) + (this.selectedBilanItemLocalAnneeN1.cpcIVFinancierProduitsFinanciers??0) + (this.selectedBilanItemLocalAnneeN1.cpcVIIINonCourantProduitsNonCourants??0)
        },
        cpcXVTotalDesChargesAnneeN1() {
            return this.selectedBilanItemLocalAnneeN1.cpcXVTotalDesCharges = (this.selectedBilanItemLocalAnneeN1.cpcIIExploitationChargesExploitation??0) + (this.selectedBilanItemLocalAnneeN1.cpcVFinancierChargesFinancieres??0) + (this.selectedBilanItemLocalAnneeN1.cpcIXNonCourantChargesNonCourantes??0) + (this.selectedBilanItemLocalAnneeN1.cpcXIIImpotsSurLesResultats??0)
        },
        cpcXVIResultatNETAnneeN1() {
            return this.selectedBilanItemLocalAnneeN1.cpcXVIResultatNET = (this.selectedBilanItemLocalAnneeN1.cpcXIVTotalDesProduits??0) - (this.selectedBilanItemLocalAnneeN1.cpcXVTotalDesCharges??0)
        },

        myValidationSchema() {
             // Using yup to generate a validation schema
            // https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
            // if(!this.isMounted)
            // {
            //     return 0;
            // }
            return yup.object().shape({
                    // ca: yup.number().typeError("Veuillez saisir un montant valide"),
                    // dateBilan: yup.string(),
                    // cashEtEquivalent: yup.number().typeError("Veuillez saisir un montant valide"),
                    // achatsRevendusEtAchatsConsommes: yup.number('Champs non numérique').typeError("Veuillez saisir un montant valide"),
                    // actifNonCirculantAuto: yup.number().typeError("Veuillez saisir un montant valide"),
                    // ca: yup.number().typeError("Veuillez saisir un montant valide"),
                    // cashEtEquivalent: yup.number().typeError("Veuillez saisir un montant valide"),
                    // chargesInteretNet: yup.number().typeError("Veuillez saisir un montant valide"),
                    // clientsEtComptesRattaches: yup.number().typeError("Veuillez saisir un montant valide"),
                    // dettesACourtTerme: yup.number().typeError("Veuillez saisir un montant valide"),
                    // dettesAMoyenEtLongTermeAuto: yup.number().typeError("Veuillez saisir un montant valide"),
                    // dotationExploitation: yup.number().typeError("Veuillez saisir un montant valide"),
                    // fondDeRoulementAuto: yup.number().typeError("Veuillez saisir un montant valide"),
                    // fondsPropres: yup.number().typeError("Veuillez saisir un montant valide"),
                    // fournisseursEtComptesRattaches: yup.number().typeError("Veuillez saisir un montant valide"),
                    // pourcentageEbitdaAuto: yup.number().typeError("Veuillez saisir un montant valide"),
                    // resultatAvantImpots: yup.number().typeError("Veuillez saisir un montant valide"),
                    // resultatExploitation: yup.number().typeError("Veuillez saisir un montant valide"),
                    // resultatNonCourant: yup.number().typeError("Veuillez saisir un montant valide"),
                    // stock: yup.number().typeError("Veuillez saisir un montant valide"),
                    // totalActif: yup.number().typeError("Veuillez saisir un montant valide"),
                    // totalActifCirculant: yup.number().typeError("Veuillez saisir un montant valide"),
            });
        },
    },
    props:['selectedBilanItemProp','showBilanDialogFlag','societe','inforisk'],
    emits:['close','saved'],
    methods:{
        switchToManuel()
        {
            this.inforiskLocal = false;
            this.selectedBilanItemLocal.inforisk = 0
        },
        async loadCompanyICE()
        {
            this.InforiskLoading = true
            var response = await CRUDService.listData('companyFromICE/'+this.societe.id+'/'+this.societe.ice,this.credentials)
            
            if(response.data)
            {
                this.companyInforisk =  response.data;
            }
            else
            {
                alert("identifiants erronés ou bilan introuvable,veuillez vérifier vos identifiants et l'ICE de votre client")
                this.InforiskLoading = false
            }
        },
        async ImporterBilanInforiskAvecEntreprisevalidee()
        {
            debugger;
            var response = await CRUDService.listData('GetBilanFromSociete/'+this.companyInforisk._iIdBil+'/'+this.selectedBilanItemLocal.dateBilan+'/'+this.societe.id,this.credentials)
            var recordObject = response.data;
            this.jsonResult = recordObject;
            this.companyInforisk=null

            if(this.jsonResult == "BilanNotFound")
            {
                alert("Bilan "+ this.selectedBilanItemLocal.dateBilan +" introuvable sur Inforisk")
                this.InforiskLoading = false
                this.selectedBilanItemLocal = {id:0,dateBilan:this.selectedBilanItemLocal.dateBilan};
                return;
            }

            if(this.jsonResult == "ErreurRecherche")
            {
                alert("Erreur dans la recherche inforisk, Vos identifiant sont erronnés veuillez contacter l'administrateur")
                this.InforiskLoading = false
                this.selectedBilanItemLocal = {id:0,dateBilan:this.selectedBilanItemLocal.dateBilan};
                return;
            }

            if(this.jsonResult == "ErreurSaisieIdentifiants")
            {
                alert("Vos identifiant ne sont pas saisies ou sont saisies incorrectement. Veuillez contacter l'administrateur")
                this.InforiskLoading = false
                this.selectedBilanItemLocal = {id:0,dateBilan:this.selectedBilanItemLocal.dateBilan};
                return;
            }

            if(this.jsonResult == "ErreurSocieteVide")
            {
                alert("La societe selectionnée n'a pas d'ICE, veuillez en saisir au moins un")
                this.InforiskLoading = false
                this.selectedBilanItemLocal = {id:0,dateBilan:this.selectedBilanItemLocal.dateBilan};
                return;
            }
            

            if(!this.jsonResult['_stActif'] || !this.jsonResult['_stPassif'] || !this.jsonResult['_stPassif'])
            {
                alert("Document introuvable sur Inforisk");
                this.selectedBilanItemLocal = {id:0,dateBilan:this.selectedBilanItemLocal.dateBilan};
                return;
            }

            for(var prop in this.actifMapping)
            {
                if(this.jsonResult['_stActif'])
                {
                    this.selectedBilanItemLocal[prop] = this.jsonResult['_stActif'][this.actifMapping[prop]]
                }
                // else
                // {
                //     alert("Actif introuvable sur Inforisk");
                // }
            }

            for(var prop in this.passifMapping)
            {
                if(this.jsonResult['_stPassif'])
                {
                    this.selectedBilanItemLocal[prop] = this.jsonResult['_stPassif'][this.passifMapping[prop]]
                }
            //    else
            //    {
            //         alert("Passif introuvable sur Inforisk");
            //    }
                
            }

            for(var prop in this.cpcMapping)
            {
                if(this.jsonResult['_stCPC'])
                {
                    this.selectedBilanItemLocal[prop] = this.jsonResult['_stCPC'][this.cpcMapping[prop]]
                }
                // else
                // {
                //     alert("CPC introuvable sur Inforisk");
                // }
                
            }

            this.selectedBilanItemLocal["inforisk"] = 1
            this.isSaving = true;
            this.InforiskLoading = false;
            
            if(this.selectedBilanItemLocal.inforisk && this.jsonResult['_stCPC'] && this.jsonResult['_stActif'] && this.jsonResult['_stPassif'])
            {
                var yearN1 = parseInt(this.selectedBilanItemLocal.dateBilan)-1;
                this.selectedBilanItemLocal['inforiskJson'] = JSON.stringify(this.jsonResult);
                var response = await this.createBilan(this.selectedBilanItemLocal,this.selectedBilanItemLocal.dateBilan);
                this.selectedBilanItemLocal = response

                var bilanN1 = await this.loadBilan(yearN1.toString());
                if(bilanN1)
                {
                    this.selectedBilanItemLocalAnneeN1 = bilanN1;
                }

            }
        },
        // returns an mapped item from Inforisk Json cache/log
        async loadCachedAndMappedBilanFinancierFromLogBilanInforisk(baseSelectedItem,year)
        {
            var selectedBilan=baseSelectedItem
            this.InforiskLoading = true
            //Loads bilan from cache
            var response = await CRUDService.list('InforiskCached/'+this.societe.id+'/'+year)
            if(!response.data)
            {
                return {id:0,dateBilan:selectedBilan.dateBilan};
                // response = await CRUDService.listData('Inforisk/'+this.societe.id+'/'+year,this.credentials)
                // var recordObject = response.data;
                // this.jsonResult = recordObject;
            }
            else
            {
                this.toastSuccess("Bilan chargé depuis le cache")
                var recordObject = response.data;
                this.jsonResult = JSON.parse(recordObject.Json);
                debugger;
                selectedBilan.inforiskJson = recordObject.Json; // set the cached json in the item
            }

            if(response.data)
            {

                    if(this.jsonResult == "BilanNotFound")
                    {
                        alert("Bilan "+ selectedBilan.dateBilan +" introuvable sur Inforisk")
                        this.InforiskLoading = false
                        return {id:0,dateBilan:selectedBilan.dateBilan};
                    }

                    if(this.jsonResult == "ErreurRecherche")
                    {
                        alert("Erreur dans la recherche inforisk, Vos identifiant sont erronnés veuillez contacter l'administrateur")
                        this.InforiskLoading = false
                        return {id:0,dateBilan:selectedBilan.dateBilan};
                    }

                    if(this.jsonResult == "ErreurSaisieIdentifiants")
                    {
                        alert("Vos identifiant ne sont pas saisies ou sont saisies incorrectement. Veuillez contacter l'administrateur")
                        this.InforiskLoading = false
                        return {id:0,dateBilan:selectedBilan.dateBilan};
                    }

                    if(this.jsonResult == "ErreurSocieteVide")
                    {
                        alert("La societe selectionnée n'a ni RC ni ICE, veuillez en saisir au moins un")
                        this.InforiskLoading = false
                        return {id:0,dateBilan:selectedBilan.dateBilan};
                    }
                    

                    if(!this.jsonResult['_stActif'] || !this.jsonResult['_stPassif'] || !this.jsonResult['_stPassif'])
                    {
                        alert("Document introuvable sur Inforisk");
                        return {id:0,dateBilan:selectedBilan.dateBilan};
                    }

                    for(var prop in this.actifMapping)
                    {
                        if(this.jsonResult['_stActif'])
                        {
                            selectedBilan[prop] = this.jsonResult['_stActif'][this.actifMapping[prop]]
                        }
                        // else
                        // {
                        //     alert("Actif introuvable sur Inforisk");
                        // }
                    }

                    for(var prop in this.passifMapping)
                    {
                        if(this.jsonResult['_stPassif'])
                        {
                            selectedBilan[prop] = this.jsonResult['_stPassif'][this.passifMapping[prop]]
                        }
                    //    else
                    //    {
                    //         alert("Passif introuvable sur Inforisk");
                    //    }
                        
                    }

                    for(var prop in this.cpcMapping)
                    {
                        if(this.jsonResult['_stCPC'])
                        {
                            selectedBilan[prop] = this.jsonResult['_stCPC'][this.cpcMapping[prop]]
                        }
                        // else
                        // {
                        //     alert("CPC introuvable sur Inforisk");
                        // }
                        
                    }

                    selectedBilan["inforisk"] = 1
                    this.isSaving = true;
                    this.InforiskLoading = false;
                    
                    
            }
            else
            {
                this.InforiskLoading = true
                alert("Société introuvable veuillez saisir un ICE correct ou un RC et code tribunal correspondants")
                this.errorMessage = response
                return {id:0}
                        
            }
            
            this.InforiskLoading = false
            return selectedBilan
            
        },
        async loadBilan(annee)
        {
            var response = ""
            try
            {
                var response = await crudService.list("bilanfinancierbyannee/"+annee+"/"+this.societe.id)
            }
            catch(error)
            {
                return error
            }
            
            if(response.data)
            {
                this.blockedDialog = false;
                ;
                this.selectedBilanItemLocalAnneeN1 = response.data;
                this.foundBilanN1 = true
                // this.toastSuccess("Bilan N-1 chargé (" + annee +') depuis '+ (this.selectedBilanItemLocalAnneeN1.inforisk?"inforisk":"manuel"));
                return response.data
            }
            else
            {
                this.foundBilanN1 = false
                return {}
            }
                    
        },
        async createBilan(selectedItem,annee)
        {
            this.isSaving = true;
            this.log("saveGridItem");
            this.log(selectedItem);
            selectedItem.societeId = this.societe.id
            this.selectedBilanItemLocal.dateBilan = annee

            var response = ""
            try
            {
                var response = await CRUDService.create(selectedItem,'bilanfinancierclient')
                if(response.data)
                {
                    this.log(response.data);
                    this.toastSuccess("Le bilan "+ response.data.dateBilan +" a été créé avec succès");
                    this.$emit('saved');
                    this.isSaving = false;
                    return response.data
                }
                else
                {
                    return {id:0};
                }
                
                
            }
            catch(error)
            {
                this.toastError("Echec création de l''enregistrement !");
                this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                this.isSaving = false;
            }
        
        },
        async CreerBilanBoutonAction()
        {
            var yearN1 = parseInt(this.selectedBilanItemLocal.dateBilan)-1;
            

            this.selectedBilanItemLocal = await this.createBilan(this.selectedBilanItemLocal,this.selectedBilanItemLocal.dateBilan);
            
            var bilanN1 = await this.loadBilan(yearN1.toString());
            
            if(!bilanN1)
            {
                this.selectedBilanItemLocalAnneeN1 = bilanN1;
            }

        },
        //Button to import from cache or 
        //to load Company and validates the client and bilan brought from inforisk
        async ImporterBilanInforiskBoutonAction()
        {
            if(!this.credentials.username && !this.credentials.password)
            {
                alert("Veuillez saisir vos identifiants Inforisk")
                return;
            }
            var yearN1 = parseInt(this.selectedBilanItemLocal.dateBilan)-1;
            
            debugger;
            //Loads bilan from cache
            this.selectedBilanItemLocal = await this.loadCachedAndMappedBilanFinancierFromLogBilanInforisk(this.selectedBilanItemLocal,this.selectedBilanItemLocal.dateBilan)
            
            if(this.selectedBilanItemLocal.inforisk)
            {
                var response = await this.createBilan(this.selectedBilanItemLocal,this.selectedBilanItemLocal.dateBilan);
                this.selectedBilanItemLocal = response

                var bilanN1 = await this.loadBilan(yearN1.toString());
                if(bilanN1)
                {
                    this.selectedBilanItemLocalAnneeN1 = bilanN1;
                }

            }
            else
            {
                //load company into window then 
                await this.loadCompanyICE()
            }

        },
        onSubmit(values) {
            this.log("onSubmit");
            this.isSaving = true;
            this.log(values);
            this.saveBilan();
        },
        closeDialog()
        {
            this.$emit('close');
        },
        async saveBilan()
        {
            
            var isUpdating = false;
            this.log("saveGridItem");
            this.log(this.selectedBilanItemLocal);

            // ==================== Save Bilan N ===================
            // cas mode edition;
            if(this.selectedBilanItemLocal.id > 0)
            {
                this.selectedBilanItemLocal.estAnneePrecedente = 0
                try
                {
                    var response = await CRUDService.update(this.selectedBilanItemLocal,'bilanfinancierclient')
                    isUpdating = true
                    this.log(response.data);
                    this.toastSuccess("Le bilan "+ response.data.dateBilan +" a été enregistré avec succès");
                    this.isSaving = false;
                }
                catch(error) {
                    alert("Echec sauvegarde de l''enregistrement !");
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    this.isSaving = false;
                    return;
                }
                
            }
            //cas mode création
            else
            {
                //on crée seulement le bilan si il a des valeurs 
                //sinon on informe le client que le bilan n'a pas été créer
                if(this.bilanNHasValues)
                {
                    this.selectedBilanItemLocal['inforiskJson'] = JSON.stringify(this.jsonResult);
                    this.selectedBilanItemLocal = await this.createBilan(this.selectedBilanItemLocal,this.selectedBilanItemLocal.dateBilan)
                    this.createBilanFlag = false;
                }
                else
                {
                    this.toastWarn("Veuillez saisir au moins une valeur dans le bilan actuel!");
                    this.isSaving = false;
                    //le return empeche la création de l'année N-1 tant que l'année N n'a pas été créée
                    return;
                }
                
            }

            // ==================== Save Bilan N-1 ===================
            // Mode edition du N-1
            if(this.selectedBilanItemLocalAnneeN1.id)
            { 
                // Autorise la MAJ du N1 uniquement s'il a été créé en N1 (le flag EstAnneePrecedente a été créé pour ce besoin), 
                // si le bilan N-1 vient de "inforisk" ou il a été créé dans la partie N (avec brut/amortProv/Precedent), il faut interdire la MAJ 
                if(this.selectedBilanItemLocalAnneeN1.estAnneePrecedente)
                {
                    try{
                        var response = await CRUDService.update(this.selectedBilanItemLocalAnneeN1,'bilanfinancierclient')
                        this.log(response.data)
                        this.toastSuccess("Le bilan "+ response.data.dateBilan +" a été enregistré avec succès");
                        this.isSaving = false;
                    }
                    catch(error)
                    {
                        this.toastError("Echec sauvegarde de l''enregistrement !");
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                        this.toastError(this.errorMessage);
                        this.isSaving = false;
                        return;
                    }
                    
                }
            }
            // mode creation
            else if(this.bilanN1HasValues)
            {
                // Si le bilan N1 n existe pas, il faut le creer uniquement s'il a des valeurs saisies
                this.selectedBilanItemLocalAnneeN1.societeId = this.selectedBilanItemLocal.societeId
                this.selectedBilanItemLocalAnneeN1.dateBilan = (parseInt(this.selectedBilanItemLocal.dateBilan)-1).toString();
                this.selectedBilanItemLocalAnneeN1.estAnneePrecedente = 1
                var ref = this;

                try
                {
                    var response = await CRUDService.create(this.selectedBilanItemLocalAnneeN1,'bilanfinancierclient')
                
                    this.log("create N1 response:" + response.data);
                            
                    this.toastSuccess("Le bilan "+ response.data.dateBilan +" a été créé avec succès");
                    
                    this.isSaving = false;
                            
                }
                catch(error)
                {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    this.toastSuccess("Echec sauvegarde de l''enregistrement !");
                    this.toastSuccess(this.errorMessage);
                    this.isSaving = false;
                }
                // 
                
            }

            // inform the upper component of the end of the save to close the modal or not
            this.$emit('saved',isUpdating);

        },
        confirmDeleteSelected(selectedItem){
            var ref = this;
            if(this.deleting)
            {
                debugger;
                return
            }
            
            this.log("confirmDeleteSelected: " + selectedItem);

            this.$confirm.require({
                message: 'Etes-vous sûr de vouloir supprimer le bilan ?',
                header: 'Confirmation',
                icon: 'pi pi-info-circle',
                acceptLabel:'Oui',
                rejectLabel:'Non',
                acceptClass: 'p-button-danger',
                accept: async() => {
                    await this.deleteBilanItem();
                    ref.deleting = true
                },
                reject: () => {
                    this.$confirm.close();
                    ref.deleting = true
                }
            });
        },
        deleteBilanItem()
        {
            this.log("deleteBilanItem");
            CRUDService.delete(this.selectedBilanItemLocal,'bilanfinancierclient').then(
                (response) => {
                    this.log(response.data);
                    this.toastSuccess("Bilan supprimé avec succès");
                    this.$emit('saved',true);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        onBilanLoaded: function(e)
        {
            var event = e;
            var ref = this;
            var jsonToAdd = {};
            this.log("this.myValidationSchema")
            // var validation = Object.keys(this.myValidationSchema.fields)
            // var validationsToRemove = ["actifNonCirculantAuto","fondDeRoulementAuto","pourcentageEbitdaAuto"]
            // for(var i=0;i<validationsToRemove.length;i++)
            // {
            //     var elemToRemove = validationsToRemove[i];
            //     var ind = validation.indexOf(elemToRemove)
            //     if(ind!=-1)
            //     {
            //     validation.splice(ind,1);
            //     }
            // }

            var validation = null

            CsvToDb.Upload(e.files[0],jsonToAdd,validation).then((response) => { //@todo nouveau parametre non accepté
                $(event.target).val("");
                console.log(response);

                for (const property in jsonToAdd) {
                    var propertyName = property.charAt(0).toLowerCase() + property.slice(1);
                    // if(propertyName == "dateBilan")
                    // {
                    //     var dateBilanString = jsonToAdd[property];
                    //     // var dateBilanMoment = this.parseDateAsMoment(dateBilanString);

                    //     // ;
                    //     // var dateBilan = new Date(dateBilanMoment.toDate());

                    //     // jsonToAdd[property] = dateBilan;

                    //     // if(dateBilanMoment.toString()=='Invalid date')
                    //     // {
                    //     //     jsonToAdd[property] = new Date()
                    //     // }
                        
                        
                    // }
                    if(propertyName != 'dateBilan')
                    {
                        ref.selectedBilanItemLocal[propertyName] = jsonToAdd[property]
                    }
                    
                }

            },
            (error) => {
                console.log(error)
                if(error && error.title == "validation")
                {
                    ref.toastWarn("Validations manquantes: "+error.message);
                }
            })
        },
        downloadItem (url) {
            Axios.get(url, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = "ModeleImportBilan.csv"
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
        }
    }
}
</script>

<style>
    .p-inputnumber-input
    {
        height:25px
    }

    .bilanHead
    {
        background-color: rgb(227, 227, 227);

    }

    .bilanHead .p-inputnumber-input,.bilanTotal .p-inputnumber-input
    {
        font-weight:bold
    }

    .bilanTotal
    {
        background-color: gray;
        color: white;
    }
    .bilanTotalGeneral
    {
        background-color: black;
        color: white;

    }
    .bilanMargeVerticale
    {
        border: solid 1px;
        width:5%;
        font-weight: bold;
        color: white;
        font-size: 0.7rem;
    }

    .bilanActif .YearN1
    {
        border-left: solid 2px;
        border-right: solid 2px;
        border-color:#2195f3;
        
    }

    .bilanPassif .YearN1
    {
        border-left: solid 2px;
        border-right: solid 2px;
        border-color:rgb(243, 23, 0);
        
    }

    .bilanCPC .YearN1
    {
        border-left: solid 2px;
        border-right: solid 2px;
        border-color:rgb(55, 180, 0);
        
    }

    /* First Col */

    .bilanActif .titleCol
    {
        border-right: solid 2px;
        border-color:#2195f3;
    }

    .bilanPassif .titleCol
    {
        border-right: solid 2px;
        border-color:rgb(243, 23, 0);
    }

    .bilanCPC .titleCol
    {
        border-right: solid 2px;
        border-color:rgb(55, 180, 0);
    }

    /* ----------- */

    .bilanActif .bilanMargeVerticale{
        background-color: #2195f3;
        border-color: gray !important;
    }

    .bilanPassif .bilanMargeVerticale{
        background-color: rgb(243, 23, 0);
        border-color: gray !important;
    }

    .bilanCPC .bilanMargeVerticale{
        background-color: rgb(55, 180, 0);
        border-color: gray !important;
    }

    .titleGroupCol
    {
        font-weight:bold; 
    }

    .titleCol{
        padding-left:10px;
    }

    .k-tabstrip-items .k-state-active
    {
        background-color:lightgrey !important;
        border-color:none !important;
        border-top-width:0px !important;
        border-left-width:0px !important;
        border-right-width:0px !important;
    }

    .p-password .pi-eye
    {
        margin-right:20px
    }

    .p-password .pi-eye-slash
    {
        margin-right:20px
    }

    .YearN1 div, .YearN div{
        display:flex;
    }
</style>