<template>
    <div v-if="selectedBilanItem.inforisk == 1 && jsonResult && jsonResult['_st'+bilanType] && areTheyNotEqual()"

    :tooltip="'Valeur depuis inforisk = '+formatDecimal2(inforiskValue)">
        <i style="color:orange" class="pi pi-exclamation-triangle"></i>
    </div>
    <!-- inforisk: {{ inforiskValue }}
   calculated:  {{ calculatedValue }}
   - {{ getDiff() }}   -->

</template>

<script>

export default{
    data()
    {
        return{
            mappingObject:{},
            bilanType:'',
            codeInforisk:'',
            jsonResult:{}
        }
    },
    props:['codeTechnique','selectedBilanItem','refMappingBilanListeChamps'], //BDD
    mounted()
    {
        var ref = this;

        if(this.selectedBilanItem.inforisk != 1)
        {
            //there is no need to go further because bilan is not from inforisk
            return;
        }

        if(this.selectedBilanItem['inforiskJson'])
        {
            this.jsonResult = JSON.parse(this.selectedBilanItem['inforiskJson'])
        }

        this.mappingObject = this.refMappingBilanListeChamps.find(function(d){
            return d.codeTechnique == ref.codeTechnique
        })

        if(this.mappingObject)
        {
            this.bilanType = this.mappingObject.bilanType

            this.codeInforisk = this.mappingObject.codeInforisk
        }
        
    },
    methods:{
        getDiff()
        {
            var inforiskValueLocal = this.inforiskValue;
            var calculatedValueLocal = this.calculatedValue;
            // fix values if they are not numbers 
            if(Math.abs(inforiskValueLocal) == 0 || isNaN(Math.abs(inforiskValueLocal)) )
            {
                inforiskValueLocal = 0;
            }
            if(Math.abs(calculatedValueLocal) == 0 || isNaN(Math.abs(calculatedValueLocal)) )
            {
                calculatedValueLocal = 0;
            }
            return Math.abs(inforiskValueLocal - calculatedValueLocal) ;       
        },
        areTheyNotEqual()
        {
            var inforiskValueLocal = this.inforiskValue;
            var calculatedValueLocal = this.calculatedValue;
            // fix values if they are not numbers 
            if(Math.abs(inforiskValueLocal) == 0 || isNaN(Math.abs(inforiskValueLocal)) )
            {
                inforiskValueLocal = 0;
            }
            if(Math.abs(calculatedValueLocal) == 0 || isNaN(Math.abs(calculatedValueLocal)) )
            {
                calculatedValueLocal = 0;
            }
            if( Math.abs(inforiskValueLocal - calculatedValueLocal) >= 0.01)
            {
                // debugger;
                return true
            }
            return false       
        }
    },
    computed:
    {
        inforiskValue()
        {
            return this.jsonResult['_st'+this.bilanType][this.codeInforisk]
        },
        calculatedValue()
        {
            return this.selectedBilanItem[this.codeTechnique]
        }
    }
}
</script>

<style scoped>

</style>
