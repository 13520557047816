<template>
  <div>
    <InputText type="text" v-model="ice"></InputText>
    <Button @click="loadInforisk">InfoRisk</Button>
    {{jsonResult}}
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import CRUDService from "../1-common-components/crud.service";

export default {
  name: "LoadBilan",
  components: {
    InputText,
    Button,
  },
  emits:   [],
  data() {
    return {
        ice:"",
        jsonResult:{}
    };
  },
  mounted()
  {

  },
  methods:{
    loadInforisk()
    {
        CRUDService.list('Inforisk/'+this.ice).then(
                (response) => {
                    this.log(response.data);
                    ;
                    this.jsonResult = response.data
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
    }
  }
};
</script>


